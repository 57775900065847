import { useState, useMemo, useCallback, memo, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  IconButton, 
  useTheme,
  alpha,
  styled,
  Stack,
  InputBase,
  Card,
  CardContent,
  Paper,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid2 from '@mui/material/Unstable_Grid2';
import TagDialog from '../components/TagDialog';
import { ITag } from '../@types';
import SearchIcon from '@mui/icons-material/Search';
import { selectTagsList, selectTagsLoading } from '../store/tagsSlice';
import { useSelector } from 'react-redux';
import { getTagsListAction, createTagAction, updateTagAction, deleteTagAction } from '../store/tagsSlice';
import { useDispatch } from 'react-redux';
import { Label } from '@mui/icons-material';
import ConfirmationModal from 'components/ConfirmationModal';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import * as Icons from '@mui/icons-material';

const TagCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: theme.spacing(2),
  background: theme.palette.background.paper,
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  position: 'relative',
  overflow: 'visible',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: `0 12px 24px -10px ${alpha(theme.palette.primary.main, 0.15)}`,
    borderColor: alpha(theme.palette.primary.main, 0.2),
    '& .tag-actions': {
      opacity: 1,
      transform: 'translateY(0)',
      pointerEvents: 'auto'
    }
  }
}));

const TagActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 12,
  right: 12,
  display: 'flex',
  gap: theme.spacing(1),
  opacity: 0,
  transform: 'translateY(-10px)',
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  pointerEvents: 'none',
  zIndex: 1,
  '& .MuiIconButton-root': {
    background: theme.palette.background.paper,
    boxShadow: `0 4px 8px ${alpha(theme.palette.common.black, 0.1)}`,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: `0 6px 12px ${alpha(theme.palette.common.black, 0.15)}`
    }
  }
}));

const SearchWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: theme.spacing(3),
  background: theme.palette.background.paper,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  transition: 'all 0.3s ease',
  '&:focus-within': {
    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
    borderColor: theme.palette.primary.main
  }
}));

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  background: theme.palette.background.paper,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: `0 8px 16px ${alpha(theme.palette.primary.main, 0.1)}`,
    borderColor: alpha(theme.palette.primary.main, 0.2),
  }
}));

type IconMap = {
  [key: string]: typeof Label;
};

const iconMap: IconMap = Icons;

const TagCardComponent = memo(({ tag, onEdit, onDelete }: { 
  tag: ITag; 
  onEdit: (tag: ITag) => void;
  onDelete: (tag: ITag) => void;
}) => {
  const IconComponent = (iconMap[tag.icon] || Label) as typeof Label;
  const theme = useTheme();

  return (
    <TagCard>
      <TagActions className="tag-actions">
        <IconButton
          size="small"
          onClick={() => onEdit(tag)}
          sx={{ 
            color: 'primary.main',
            '&:hover': {
              background: alpha(theme.palette.primary.main, 0.1)
            }
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => onDelete(tag)}
          sx={{ 
            color: 'error.main',
            '&:hover': {
              background: alpha(theme.palette.error.main, 0.1)
            }
          }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TagActions>

      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                width: 56,
                height: 56,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: alpha(tag.color, 0.1),
                color: tag.color,
                position: 'relative',
                transition: 'all 0.3s ease',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: -2,
                  left: -2,
                  right: -2,
                  height: 4,
                  background: `linear-gradient(90deg, ${tag.color} 0%, ${alpha(tag.color, 0.6)} 100%)`,
                  borderRadius: '4px 4px 0 0'
                },
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: `0 8px 16px ${alpha(tag.color, 0.2)}`
                }
              }}
            >
              <IconComponent sx={{ fontSize: 28 }} />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {tag.name}
                </Typography>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    bgcolor: tag.color,
                    boxShadow: `0 0 0 2px ${alpha(tag.color, 0.2)}`
                  }}
                />
              </Stack>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5
                }}
              >
                {tag.products.length > 0 ? (
                  <>
                    <Box
                      component="span"
                      sx={{
                        width: 6,
                        height: 6,
                        borderRadius: '50%',
                        bgcolor: 'success.main',
                        display: 'inline-block'
                      }}
                    />
                    {tag.products.length} products
                  </>
                ) : null}
              </Typography>
            </Box>
          </Stack>

          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              minHeight: '40px'
            }}
          >
            {tag.description}
          </Typography>
        </Stack>
      </CardContent>
    </TagCard>
  );
});

const generateRandomColor = () => {
  const colors = [
    '#FF6B6B', // Red
    '#4ECDC4', // Teal
    '#45B7D1', // Blue
    '#96CEB4', // Green
    '#FFEEAD', // Yellow
    '#D4A5A5', // Pink
    '#9B59B6', // Purple
    '#3498DB', // Blue
    '#E67E22', // Orange
    '#1ABC9C', // Turquoise
    '#34495E', // Navy
    '#27AE60', // Emerald
    '#E74C3C', // Red
    '#8E44AD', // Purple
    '#2980B9', // Blue
    '#F1C40F'  // Yellow
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default function TagsPage() {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

	const tags = useSelector(selectTagsList);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tagToDelete, setTagToDelete] = useState<ITag | null>(null);

  const loading = useSelector(selectTagsLoading);

  const filteredTags = useMemo(() => 
    tags.filter(tag => 
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.description.toLowerCase().includes(searchQuery.toLowerCase())
    ),
    [tags, searchQuery]
  );

  const totalProducts = useMemo(() => 
    tags.reduce((sum, tag) => sum + (tag.products.length || 0), 0),
    [tags]
  );

  useEffect(() => {
		dispatch(getTagsListAction());
	}, [dispatch]);

  const handleSaveTag = useCallback((tag: ITag) => {
    const tagToSave = {
      name: tag.name,
      description: tag.description,
      color: selectedTag ? tag.color : generateRandomColor(),
      icon: tag.icon || 'LocalOffer',
    };

    if (selectedTag) {
      dispatch(updateTagAction({
        ...tagToSave,
        id: selectedTag.id,
        products: selectedTag.products
      }));
    } else {
      dispatch(createTagAction(tagToSave));
    }
    
    setOpenDialog(false);
    setSelectedTag(null);
    setShowColorPicker(false);
  }, [dispatch, selectedTag]);

  const handleEdit = useCallback((tag: ITag) => {
    setSelectedTag(tag);
    setOpenDialog(true);
  }, []);

  const handleDelete = useCallback((id: number) => {
    dispatch(deleteTagAction(id));
    setTagToDelete(null);
  }, [dispatch]);

  const handleDeleteClick = useCallback((tag: ITag) => {
    setTagToDelete(tag);
  }, []);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <Typography variant="h4">
            Tags
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Manage your product tags
          </Typography>
        </Stack>

        <Grid2 container spacing={2}>
          <Grid2 xs={12} sm={6} md={3}>
            <StatsCard>
              <Stack spacing={1}>
                <Typography variant="h3">
                  {tags.length}
                </Typography>
                <Typography color="text.secondary">
                  Total Tags
                </Typography>
              </Stack>
            </StatsCard>
          </Grid2>
          <Grid2 xs={12} sm={6} md={3}>
            <StatsCard>
              <Stack spacing={1}>
                <Typography variant="h3">
                  {totalProducts}
                </Typography>
                <Typography color="text.secondary">
                  Tagged Products
                </Typography>
              </Stack>
            </StatsCard>
          </Grid2>
        </Grid2>

        <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <SearchWrapper>
            <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <InputBase
              placeholder="Search tags..."
              value={searchQuery}
              onChange={handleSearch}
              fullWidth
            />
          </SearchWrapper>

          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setSelectedTag(null);
              setOpenDialog(true);
            }}
          >
            Create Tag
          </Button>
        </Stack>

        <Grid2 container spacing={2}>
          {loading ? (
            <Box sx={{ width: '100%', textAlign: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : filteredTags.length === 0 ? (
            <Box sx={{ 
              width: '100%', 
              textAlign: 'center', 
              py: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}>
              <SentimentDissatisfiedIcon sx={{ fontSize: 48, color: 'text.secondary' }} />
              <Typography variant="h6" color="text.secondary">
                {searchQuery ? 'No tags found matching your search' : 'No tags created yet'}
              </Typography>
              {!searchQuery && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setSelectedTag(null);
                    setOpenDialog(true);
                  }}
                >
                  Create Your First Tag
                </Button>
              )}
            </Box>
          ) : (
            filteredTags.map((tag) => (
              <Grid2 xs={12} sm={6} md={4} key={tag.id}>
                <TagCardComponent
                  tag={tag}
                  onEdit={handleEdit}
                  onDelete={handleDeleteClick}
                />
              </Grid2>
            ))
          )}
        </Grid2>
      </Stack>

      <TagDialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={handleSaveTag}
        tag={selectedTag}
        showColorPicker={showColorPicker}
        onColorPickerChange={setShowColorPicker}
      />

      <ConfirmationModal
        open={!!tagToDelete}
        title="Delete Tag"
        message={`Are you sure you want to delete "${tagToDelete?.name}"?`}
        onConfirm={() => tagToDelete && handleDelete(tagToDelete.id)}
        onClose={() => setTagToDelete(null)}
      />
    </Box>
  );
} 