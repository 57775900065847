import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Stack,
	Typography,
	Slider,
	Box,
	Alert,
	IconButton,
	useTheme,
	alpha,
	Chip,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Autocomplete,
	Paper,
	Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { addNotificationAction } from 'modules/notification/store/notificationSlice';
import { CheckInterval, Condition, NotificationType } from '../@types';
import { selectAuth } from 'modules/auth/store/authSlice';
import TelegramIcon from '@mui/icons-material/Telegram';
import SmsIcon from '@mui/icons-material/Sms';
import { keyframes } from '@emotion/react';
import { NotificationIllustration } from 'components/illustrations/NotificationIllustration';
import { PriceHistoryIllustration } from 'components/illustrations/PriceHistoryIllustration';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { INotification } from 'modules/notification/@types';

interface AddNewNotificationProps {
	onClose: () => void;
	onSuccessAdd?: () => void;
	productId: string;
	currentPrice?: number;
	editingNotification?: INotification | null;
}

const notificationTypes = [
	{ 
		type: NotificationType.EMAIL, 
		icon: EmailIcon, 
		label: 'Email', 
		disabled: false 
	},
	{ 
		type: NotificationType.WHATSAPP, 
		icon: WhatsAppIcon, 
		label: 'WhatsApp', 
		disabled: true,
		comingSoon: true 
	},
	{ 
		type: NotificationType.TELEGRAM, 
		icon: TelegramIcon, 
		label: 'Telegram', 
		disabled: true,
		comingSoon: true 
	},
	{ 
		type: NotificationType.PUSH, 
		icon: NotificationsIcon, 
		label: 'Push', 
		disabled: true,
		comingSoon: true 
	},
	{ 
		type: NotificationType.SMS, 
		icon: SmsIcon, 
		label: 'SMS', 
		disabled: true,
		comingSoon: true 
	}
];

const isValueBasedCondition = (condition: Condition) => {
	return [Condition.BELOW_THRESHOLD, Condition.ABOVE_THRESHOLD].includes(condition);
};

const float = keyframes`
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

interface Step {
	label: string;
	description: string;
	icon: React.ReactNode;
}

const steps: Step[] = [
	{
		label: 'Choose Notification Type',
		description: 'Select how you want to be notified',
		icon: <NotificationsIcon />
	},
	{
		label: 'Set Alert Conditions',
		description: 'Define when you want to be notified',
		icon: <TrendingDownIcon />
	},
	{
		label: 'Add Recipients',
		description: 'Who should receive these notifications',
		icon: <EmailIcon />
	}
];

const RecipientsSection: React.FC<{
	emails: string[];
	onEmailsChange: (emails: string[]) => void;
}> = ({ emails, onEmailsChange }) => {
	const [inputEmail, setInputEmail] = useState('');
	
	return (
		<Stack spacing={2}>
			<Typography variant="subtitle1" color="text.secondary">
				Add email addresses to receive notifications
			</Typography>
			<Autocomplete
				multiple
				freeSolo
				value={emails}
				onChange={(_, newValue) => onEmailsChange(newValue)}
				options={[]}
				inputValue={inputEmail}
				onInputChange={(_, value) => setInputEmail(value)}
				renderTags={(value, getTagProps) =>
					value.map((email, index) => (
						<Chip
							label={email}
							icon={<EmailIcon />}
							{...getTagProps({ index })}
							sx={{ borderRadius: 2 }}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						placeholder="Add email address"
						error={inputEmail.length > 0 && !isValidEmail(inputEmail)}
						helperText={inputEmail.length > 0 && !isValidEmail(inputEmail) ? "Please enter a valid email" : ""}
					/>
				)}
			/>
		</Stack>
	);
};

const PriceSettingsSection: React.FC<{
	condition: Condition;
	targetPrice: number;
	currentPrice: number;
	onPriceChange: (price: number) => void;
}> = ({ condition, targetPrice, currentPrice, onPriceChange }) => {
	const percentageOff = ((currentPrice - targetPrice) / currentPrice) * 100;
	
	return (
		<Box>
			<Typography gutterBottom>
				{condition === Condition.BELOW_THRESHOLD 
					? 'Notify me when price drops below'
					: 'Notify me when price goes above'}
			</Typography>
			<Stack direction="row" spacing={2} alignItems="center">
				<TextField
					value={Number(targetPrice).toFixed(2)}
					onChange={(e) => onPriceChange(Number(parseFloat(e.target.value).toFixed(2)))}
					type="number"
					size="small"
					InputProps={{
						startAdornment: '$',
						sx: { borderRadius: 2 }
					}}
				/>
				{condition === Condition.BELOW_THRESHOLD && (
					<Chip 
						icon={<TrendingDownIcon />}
						label={`${percentageOff.toFixed(1)}% off`}
						color="success"
						variant="outlined"
						sx={{ borderRadius: 2 }}
					/>
				)}
			</Stack>
			<Slider
				value={targetPrice}
				onChange={(_, value) => onPriceChange(Number(Number(value).toFixed(2)))}
				min={currentPrice * 0.5}
				max={currentPrice * 1.5}
				step={0.01}
				sx={{ mt: 2 }}
			/>
		</Box>
	);
};

const isValidEmail = (email: string): boolean => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const AddNewNotification: React.FC<AddNewNotificationProps> = ({
	onClose,
	onSuccessAdd,
	productId,
	currentPrice = 0,
	editingNotification
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { user } = useSelector(selectAuth);
	
	const [formData, setFormData] = useState({
		targetPrice: editingNotification?.value || currentPrice * 0.9,
		emails: editingNotification?.recipientEmails || (user ? [user.email] : []),
		type: editingNotification?.type || NotificationType.EMAIL,
		condition: editingNotification?.condition || Condition.PRICE_DROP,
		checkInterval: editingNotification?.checkInterval || CheckInterval.EVERY_5_MINUTES
	});
	
	const [showSuccess, setShowSuccess] = useState(false);
	const [inputEmail, setInputEmail] = useState('');
	const [activeStep, setActiveStep] = useState(0);

	const handleSubmit = () => {
		dispatch(addNotificationAction({
			productId: Number(productId),
			value: Number(Number(formData.targetPrice).toFixed(2)),
			type: formData.type,
			condition: formData.condition,
			checkInterval: formData.checkInterval,
			recipientEmails: formData.emails
		}));
		setShowSuccess(true);
		setTimeout(() => {
			onSuccessAdd?.();
			onClose();
		}, 2000);
	};

	const percentageOff = ((currentPrice - formData.targetPrice) / currentPrice) * 100;

	const handleNext = () => {
		setActiveStep((prevStep) => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const renderStepContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<motion.div
						initial={{ opacity: 0, x: 20 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: -20 }}
					>
						<Stack spacing={2}>
							<Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
								Choose how you'd like to receive notifications
							</Typography>
							<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))', gap: 1 }}>
								{notificationTypes.map(({ type, icon: Icon, label, disabled, comingSoon }) => (
									<Paper
										key={type}
										onClick={() => !disabled && setFormData({ ...formData, type })}
										elevation={0}
										sx={{
											p: 1.2,
											cursor: disabled ? 'default' : 'pointer',
											border: '1px solid',
											borderColor: formData.type === type ? 'primary.main' : 'divider',
											borderRadius: 1.5,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											gap: 0.5,
											opacity: disabled ? 0.5 : 1,
											bgcolor: formData.type === type 
												? alpha(theme.palette.primary.main, 0.04)
												: 'background.paper',
											transition: 'all 0.2s ease',
											position: 'relative',
											overflow: 'hidden',
											...(!disabled && {
												'&:hover': {
													borderColor: 'primary.main',
													bgcolor: alpha(theme.palette.primary.main, 0.04),
													transform: 'translateY(-1px)'
												}
											}),
											...((formData.type === type) && {
												'&::before': {
													content: '""',
													position: 'absolute',
													top: 0,
													left: 0,
													width: '2px',
													height: '100%',
													bgcolor: 'primary.main'
												}
											})
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: 28,
												height: 28,
												borderRadius: 0.8,
												bgcolor: formData.type === type 
													? alpha(theme.palette.primary.main, 0.12)
													: alpha(theme.palette.action.hover, 0.08),
												color: formData.type === type ? 'primary.main' : 'text.secondary'
											}}
										>
											<Icon sx={{ fontSize: 16 }} />
										</Box>
										<Typography 
											variant="caption" 
											sx={{ 
												fontWeight: 500,
												color: formData.type === type ? 'primary.main' : 'text.primary'
											}}
										>
											{label}
										</Typography>
										{comingSoon && (
											<Box
												sx={{
													position: 'absolute',
													top: 4,
													right: 4,
													px: 0.5,
													py: 0.25,
													borderRadius: 0.5,
													bgcolor: alpha(theme.palette.primary.main, 0.08),
													fontSize: '0.6rem',
													lineHeight: 1,
													fontWeight: 600,
													color: 'primary.main',
													letterSpacing: '0.02em'
												}}
											>
												SOON
											</Box>
										)}
									</Paper>
								))}
							</Box>
						</Stack>
					</motion.div>
				);

			case 1:
				return (
					<motion.div
						initial={{ opacity: 0, x: 20 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: -20 }}
					>
						<Stack spacing={3}>
							<Box>
								<FormControl fullWidth>
									<InputLabel>Alert Condition</InputLabel>
									<Select
										value={formData.condition}
										label="Alert Condition"
										onChange={(e) => {
											const newCondition = e.target.value as Condition;
											setFormData({
												...formData,
												condition: newCondition,
												targetPrice: isValueBasedCondition(newCondition) ? formData.targetPrice : 0
											});
										}}
									>
										{Object.entries(Condition).map(([key, value]) => (
											<MenuItem key={value} value={value}>
												{key.split('_').map(word => 
													word.charAt(0) + word.slice(1).toLowerCase()
												).join(' ')}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>

							{isValueBasedCondition(formData.condition) && (
								<PriceSettingsSection 
									condition={formData.condition}
									targetPrice={formData.targetPrice}
									currentPrice={currentPrice}
									onPriceChange={(price) => setFormData({ ...formData, targetPrice: price })}
								/>
							)}

							<FormControl fullWidth>
								<InputLabel>Check Frequency</InputLabel>
								<Select
									value={formData.checkInterval}
									label="Check Frequency"
									onChange={(e) => setFormData({ 
										...formData, 
										checkInterval: e.target.value as CheckInterval 
									})}
								>
									{Object.entries(CheckInterval).map(([key, value]) => (
										<MenuItem key={value} value={value}>
											{key.split('_').map(word => 
												word.charAt(0) + word.slice(1).toLowerCase()
											).join(' ')}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
					</motion.div>
				);

			case 2:
				return (
					<motion.div
						initial={{ opacity: 0, x: 20 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: -20 }}
					>
						<RecipientsSection
							emails={formData.emails}
							onEmailsChange={(emails) => setFormData({ ...formData, emails })}
						/>
					</motion.div>
				);

			default:
				return null;
		}
	};

	const isStepValid = (step: number): boolean => {
		switch (step) {
			case 0:
				return formData.type === NotificationType.EMAIL; // Only email is supported for now
			case 1:
				return formData.condition !== undefined && 
					(!isValueBasedCondition(formData.condition) || formData.targetPrice > 0);
			case 2:
				return formData.emails.length > 0;
			default:
				return false;
		}
	};

	return (
		<Dialog 
			open={true} 
			onClose={onClose}
			PaperProps={{
				sx: {
					borderRadius: 4,
					width: '100%',
					maxWidth: 560,
					overflow: 'hidden',
					background: theme.palette.background.paper,
					boxShadow: theme.shadows[24]
				}
			}}
		>
			<DialogTitle sx={{ 
				display: 'flex', 
				alignItems: 'center', 
				justifyContent: 'space-between',
				background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
				color: 'white',
				pb: 3,
				position: 'relative',
				overflow: 'hidden',
				'&::before': {
					content: '""',
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					background: `radial-gradient(circle at top right, ${alpha(theme.palette.common.white, 0.1)}, transparent)`,
					zIndex: 0
				}
			}}>
				<Stack direction="row" spacing={2} alignItems="center" sx={{ position: 'relative', zIndex: 1 }}>
					<Box
						sx={{
							animation: `${float} 3s ease-in-out infinite`,
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<NotificationIllustration sx={{ fontSize: 40 }} />
					</Box>
					<Stack spacing={0.5}>
						<Typography variant="h6">
							{editingNotification ? 'Edit Price Alert' : 'Set Price Alert'}
						</Typography>
						<Typography variant="body2" sx={{ opacity: 0.8 }}>
							{editingNotification 
								? 'Update your price alert settings'
								: 'Get notified when the price matches your criteria'}
						</Typography>
					</Stack>
				</Stack>
				<IconButton 
					onClick={onClose} 
					sx={{ 
						color: 'white',
						position: 'relative',
						zIndex: 1,
						'&:hover': {
							background: alpha(theme.palette.common.white, 0.1)
						}
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={{ mt: 2 }}>
				<Stepper 
					activeStep={activeStep} 
					orientation="vertical"
					sx={{ mb: 3 }}
				>
					{steps.map((step, index) => (
						<Step key={step.label}>
							<StepLabel
								StepIconComponent={() => (
									<Avatar
										sx={{
											width: 32,
											height: 32,
											bgcolor: activeStep >= index ? 'primary.main' : 'grey.300',
										}}
									>
										{step.icon}
									</Avatar>
								)}
							>
								<Typography variant="subtitle1">{step.label}</Typography>
								<Typography variant="body2" color="text.secondary">
									{step.description}
								</Typography>
							</StepLabel>
							<StepContent>
								{renderStepContent(index)}
							</StepContent>
						</Step>
					))}
				</Stepper>
			</DialogContent>

			<DialogActions sx={{ p: 3, pt: 0 }}>
				<Button
					onClick={handleBack}
					disabled={activeStep === 0}
					sx={{ borderRadius: 2 }}
				>
					Back
				</Button>
				<Box sx={{ flex: '1 1 auto' }} />
				<Button
					variant="contained"
					onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
					disabled={!isStepValid(activeStep)}
					sx={{ borderRadius: 2 }}
				>
					{activeStep === steps.length - 1 
						? (editingNotification ? 'Update Alert' : 'Set Alert') 
						: 'Continue'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddNewNotification;
