import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { currentProduct, getProductByIdAction, removeProductByIdAction } from "../store/productSlice";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import AddNewNotification from "../components/AddNewNotification";
import { Box, Typography, Chip, Container, useTheme, Modal, Fade, Stack, Divider, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import ProductPriceChart from '../../../components/ProductPriceChart';
import { INotification } from "modules/notification/@types";
import { alpha } from '@mui/material/styles';
import NotificationsIcon from "@mui/icons-material/Notifications";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Paper from "@mui/material/Paper";
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EmailIcon from '@mui/icons-material/Email';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';

import * as Icons from '@mui/icons-material';
import { Label } from "@mui/icons-material";
import TagSelectionDialog from '../components/TagSelectionDialog';
import ConfirmationModal from '../../../components/ConfirmationModal';
import Routes from '../../../configs/routes';
import { selectNotifications, getNotificationListAction } from "modules/notification/store/notificationSlice";
import { removeNotification } from "services/notificationService";
import { Condition } from "../@types";


const ProductHeader = styled(Box)(({ theme }) => ({
	position: 'relative',
	padding: theme.spacing(4),
	borderRadius: theme.shape.borderRadius * 2,
	background: theme.palette.mode === 'dark'
		? 'rgba(255, 255, 255, 0.03)'
		: '#ffffff',
	border: '1px solid',
	borderColor: theme.palette.mode === 'dark'
		? 'rgba(255, 255, 255, 0.05)'
		: 'rgba(0, 0, 0, 0.05)',
	boxShadow: theme.shadows[1],
	marginBottom: theme.spacing(3),
}));

const PriceSection = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'flex-start',
	gap: theme.spacing(2),
	padding: theme.spacing(2),
	borderRadius: theme.spacing(2),
	backgroundColor: theme.palette.mode === 'dark' 
		? alpha(theme.palette.common.white, 0.03)
		: alpha(theme.palette.common.black, 0.02),
}));

const DiscountBadge = styled(Box)<{ isPriceDown?: boolean }>(({ theme, isPriceDown }) => ({
	padding: theme.spacing(0.5, 1.5),
	borderRadius: theme.spacing(1),
	backgroundColor: isPriceDown 
		? alpha(theme.palette.success.main, 0.1)
		: alpha(theme.palette.error.main, 0.1),
	color: isPriceDown 
		? theme.palette.success.main 
		: theme.palette.error.main,
	fontWeight: 600,
	fontSize: '0.875rem',
}));

const EmptyStateBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(4),
	'& svg': {
		width: 120,
		height: 120,
		marginBottom: theme.spacing(2),
		color: theme.palette.mode === 'dark' 
			? alpha(theme.palette.primary.main, 0.5)
			: theme.palette.primary.main,
	}
}));

const TagsContainer = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: theme.spacing(2),
	border: `1px solid ${theme.palette.divider}`,
	'& .MuiButton-root': {
		minWidth: 'auto',
		padding: theme.spacing(0.5, 1.5)
	}
}));

const TagChip = styled(Chip)(({ theme }) => ({
	borderRadius: theme.spacing(1),
	height: 28,
	'& .MuiChip-label': {
		fontWeight: 500,
		fontSize: '0.8125rem',
		paddingLeft: 6
	},
	'& .MuiChip-icon': {
		fontSize: 16,
		marginLeft: 6,
		marginRight: -4
	},
	'& .MuiChip-deleteIcon': {
		fontSize: 16,
		marginRight: 4,
		color: 'inherit',
		opacity: 0.7,
		'&:hover': {
			opacity: 1
		}
	}
}));

const ProductLayout = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3),
	minHeight: '100vh',
	background: theme.palette.background.default
}));

const ImageSection = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	height: '500px',
	borderRadius: theme.spacing(2),
	backgroundColor: theme.palette.mode === 'dark' 
		? alpha(theme.palette.common.black, 0.02)
		: alpha(theme.palette.common.white, 0.9),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden'
}));

const ProductImage = styled('img')({
	maxWidth: '100%',
	maxHeight: '100%',
	objectFit: 'contain',
	transition: 'transform 0.3s ease',
	'&:hover': {
		transform: 'scale(1.05)',
		cursor: 'zoom-in'
	}
});

const ColorOption = styled(Box)<{ color: string }>(({ theme, color }) => ({
	width: 32,
	height: 32,
	borderRadius: '50%',
	backgroundColor: color,
	cursor: 'pointer',
	border: `2px solid ${theme.palette.background.paper}`,
	boxShadow: theme.shadows[2],
	transition: 'transform 0.2s ease',
	'&:hover': {
		transform: 'scale(1.1)'
	}
}));

const SizeOption = styled(Button)(({ theme }) => ({
	minWidth: 48,
	height: 48,
	borderRadius: theme.spacing(1),
	border: `1px solid ${theme.palette.divider}`,
	'&.selected': {
		borderColor: theme.palette.primary.main,
		backgroundColor: alpha(theme.palette.primary.main, 0.1)
	}
}));

const DomainInfo = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(1),
	padding: theme.spacing(1, 2),
	borderRadius: theme.spacing(1),
	backgroundColor: theme.palette.mode === 'dark' 
		? alpha(theme.palette.common.white, 0.05)
		: alpha(theme.palette.common.black, 0.03),
	width: 'fit-content'
}));

const DescriptionBox = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: theme.spacing(2),
	backgroundColor: theme.palette.mode === 'dark' 
		? alpha(theme.palette.common.white, 0.03)
		: alpha(theme.palette.common.black, 0.02),
	border: `1px solid ${theme.palette.divider}`
}));

const iconMap = Icons;

// Add this styled component
const CompactChartBox = styled(Box)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: theme.spacing(2),
	border: `1px solid ${theme.palette.divider}`,
	marginTop: theme.spacing(2)
}));

// Add this helper function to format check interval
const formatCheckInterval = (interval: string) => {
	const formatted = interval.split('_')
		.map(word => word.toLowerCase())
		.join(' ');
	return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

const getNotificationIcon = (condition: Condition) => {
	switch (condition) {
		case Condition.BELOW_THRESHOLD:
			return <TrendingDownIcon color="success" />;
		case Condition.ABOVE_THRESHOLD:
			return <TrendingUpIcon color="error" />;
		case Condition.PRICE_CHANGE:
			return <CompareArrowsIcon color="primary" />;
		default:
			return <NotificationsIcon />;
	}
};

export default function ProductView() {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const { id } = useParams<{ id: string }>();
	const product = useSelector(currentProduct);
	const [activeTab, setActiveTab] = React.useState(0);
	const theme = useTheme();
	const [openZoom, setOpenZoom] = useState(false);
	const [selectedSection, setSelectedSection] = useState('history');
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
	const { notifications } = useSelector(selectNotifications);
	const [editingNotification, setEditingNotification] = useState<INotification | null>(null);
	const [deleteNotificationId, setDeleteNotificationId] = useState<string | null>(null);

	const handleEdit = (row: INotification) => {
		console.log("Edit:", row);
	};

	const handleDelete = () => {
		if (window.confirm('Are you sure you want to delete this product?')) {
			dispatch(removeProductByIdAction(Number(id)));
			navigate('/products');
		}
	};

	const handleDeleteTag = (tagId: string) => {
		// Implement the logic to delete a tag
		// dispatch(removeTagFromProductAction(tagId, product.id));
	};

	const handleDeleteProduct = () => {
		dispatch(removeProductByIdAction(Number(id)));
	};

	const handleEditNotification = (notification: INotification) => {
		setEditingNotification(notification);
		setIsNotificationModalOpen(true);
	};

	const handleDeleteNotification = async () => {
		if (deleteNotificationId) {
			await removeNotification(deleteNotificationId);
			dispatch(getNotificationListAction());
			setDeleteNotificationId(null);
		}
	};

	useEffect(() => {
		if (id) {
			dispatch(getProductByIdAction(id));
		}
		dispatch(getNotificationListAction());
	}, [dispatch, id]);

	if (!product) {
		return null;
	}

	const priceChange = product.price - product.prevPrice;
	const priceChangePercentage = (priceChange / product.prevPrice) * 100;
	const isPriceDown = priceChange < 0;

	const formattedPriceHistory = product.priceHistory.map(item => ({
		...item,
		date: typeof item.date === 'string' 
			? item.date 
			: new Date(item.date).toISOString().split('T')[0]
	}));

	const NotificationsSection = ({ notifications }: { notifications: INotification[] }) => {
		return (
			<Box sx={{ mt: 4 }}>
				<Stack 
					direction="row" 
					alignItems="center" 
					justifyContent="space-between" 
					mb={3}
				>
					<Stack direction="row" alignItems="center" spacing={2}>
						<NotificationsActiveIcon color="primary" />
						<Typography variant="h6">Price Alerts</Typography>
						{notifications.length > 0 && (
							<Chip
								size="small"
								label={notifications.length}
								color="primary"
								sx={{ 
									ml: 1,
									height: 20,
									'& .MuiChip-label': { px: 1, fontSize: '0.75rem' }
								}}
							/>
						)}
					</Stack>
					
					<Button
						variant="contained"
						startIcon={<NotificationsActiveIcon />}
						onClick={() => setIsNotificationModalOpen(true)}
						sx={{
							height: 44,
							borderRadius: 2,
							bgcolor: 'primary.main',
							textTransform: 'none',
							px: 3,
							'&:hover': {
								bgcolor: 'primary.dark',
								transform: 'translateY(-1px)',
								boxShadow: theme.shadows[8]
							},
							transition: 'all 0.2s ease'
						}}
					>
						Set Price Alert
					</Button>
				</Stack>
				
				{notifications.length > 0 ? (
					<Stack spacing={2}>
						{notifications.map((notification) => (
							<Paper
								key={notification.id}
								elevation={0}
								sx={{
									p: 3,
									borderRadius: 3,
									border: '1px solid',
									borderColor: 'divider',
									transition: 'transform 0.2s ease, box-shadow 0.2s ease',
									'&:hover': {
										transform: 'translateY(-2px)',
										boxShadow: theme.shadows[4]
									}
								}}
							>
								<Stack spacing={2}>
									<Stack direction="row" alignItems="center" spacing={2}>
										{getNotificationIcon(notification.condition)}
										<Box flex={1}>
											<Typography variant="subtitle1" fontWeight={600}>
												{notification.condition === 'BELOW_THRESHOLD' 
													? `Alert when price drops below $${Number(notification.value).toFixed(2)}`
													: notification.condition === 'ABOVE_THRESHOLD'
													? `Alert when price goes above $${Number(notification.value).toFixed(2)}`
													: 'Alert on any price change'}
											</Typography>
											<Stack direction="row" spacing={1} alignItems="center" mt={0.5}>
												<Chip 
													size="small"
													label={notification.type.toLowerCase()}
													icon={<EmailIcon />}
													sx={{ 
														borderRadius: 1,
														bgcolor: alpha(theme.palette.primary.main, 0.1),
														color: 'primary.main',
														'& .MuiChip-icon': { color: 'inherit' }
													}}
												/>
												<Chip
													size="small"
													label={formatCheckInterval(notification.checkInterval)}
													icon={<AccessTimeIcon />}
													sx={{
														borderRadius: 1,
														bgcolor: alpha(theme.palette.info.main, 0.1),
														color: 'info.main',
														'& .MuiChip-icon': { color: 'inherit' }
													}}
												/>
												<Typography variant="body2" color="text.secondary">
													{notification.recipientEmails.join(', ')}
												</Typography>
											</Stack>
										</Box>
										<Stack direction="row" alignItems="center" spacing={1}>
											<IconButton
												onClick={() => handleEditNotification(notification)}
												sx={{
													color: 'primary.main',
													opacity: 0.7,
													'&:hover': {
														opacity: 1,
														bgcolor: alpha(theme.palette.primary.main, 0.1)
													}
												}}
											>
												<EditIcon />
											</IconButton>
											<IconButton
												onClick={() => setDeleteNotificationId(notification.id)}
												sx={{
													color: 'error.main',
													opacity: 0.7,
													'&:hover': {
														opacity: 1,
														bgcolor: alpha(theme.palette.error.main, 0.1)
													}
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
									</Stack>
								</Stack>
							</Paper>
						))}
					</Stack>
				) : (
					<Paper
						elevation={0}
						sx={{
							p: 4,
							textAlign: 'center',
							borderRadius: 3,
							border: '1px dashed',
							borderColor: 'divider',
							bgcolor: 'background.default'
						}}
					>
						<NotificationsOffIcon 
							sx={{ 
								fontSize: 48, 
								color: 'text.disabled',
								mb: 2 
							}} 
						/>
						<Typography variant="h6" color="text.secondary" gutterBottom>
							No Active Alerts
						</Typography>
						<Typography variant="body2" color="text.secondary">
							Set up price alerts to get notified when prices change
						</Typography>
					</Paper>
				)}
			</Box>
		);
	};

	return (
		<ProductLayout>
			<Container maxWidth="xl">
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.5 }}
				>
					{/* Navigation */}
					<Stack direction="row" alignItems="center" spacing={2} mb={4}>
						<IconButton onClick={() => navigate(-1)}>
							<ArrowBackIcon />
						</IconButton>
						<Typography variant="h6">Back to Products</Typography>
					</Stack>

					<Grid container spacing={4}>
						{/* Left Column - Image */}
						<Grid xs={12} md={6}>
							<ImageSection>
								<ProductImage src={product.imgUrl} alt={product.title} />
								<IconButton
									sx={{
										position: 'absolute',
										top: 16,
										right: 16,
										bgcolor: 'background.paper',
										boxShadow: 1
									}}
									onClick={() => setOpenZoom(true)}
								>
									<ZoomOutMapIcon />
								</IconButton>
							</ImageSection>

							{/* Thumbnail Images */}
							<Stack direction="row" spacing={2} mt={2} justifyContent="center">
								{[product.imgUrl, product.imgUrl, product.imgUrl].map((img, i) => (
									<Box
										key={i}
										component="img"
										src={img}
										sx={{
											width: 80,
											height: 80,
											borderRadius: 2,
											cursor: 'pointer',
											border: '2px solid',
											borderColor: i === 0 ? 'primary.main' : 'divider'
										}}
									/>
								))}
							</Stack>
						</Grid>

						{/* Right Column - Product Info */}
						<Grid xs={12} md={6}>
							<Stack spacing={3}>
								{/* Domain Info */}
								<DomainInfo>
									<Box
										component="img"
										src={product.favicon}
										alt="site icon"
										sx={{
											width: 20,
											height: 20,
											borderRadius: 0.5,
											objectFit: 'contain'
										}}
									/>
									<Typography variant="body2" color="text.secondary">
										{new URL(product.url).hostname}
									</Typography>
								</DomainInfo>

								{/* Title & Price */}
								<Box>
									<Typography variant="h3" fontWeight="700" gutterBottom>
										{product.title}
									</Typography>
								</Box>

								{/* Price */}
								<PriceSection>
									<Stack spacing={0.5}>
										<Stack direction="row" spacing={2} alignItems="center">
											<Typography variant="h3" color="primary.main" fontWeight="700">
												{product.currency}{product.price.toLocaleString('en-US', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2
												})}
											</Typography>
											{priceChange !== 0 && (
												<Typography
													variant="h5"
													color="text.secondary"
													sx={{ 
														textDecoration: 'line-through',
														fontWeight: 400 
													}}
												>
													{product.currency}{product.prevPrice.toLocaleString('en-US', {
														minimumFractionDigits: 2,
														maximumFractionDigits: 2
													})}
												</Typography>
											)}
										</Stack>
										{priceChange !== 0 && (
											<Stack direction="row" spacing={1} alignItems="center">
												<DiscountBadge isPriceDown={isPriceDown}>
													{isPriceDown ? (
														<>
															<TrendingDownIcon sx={{ fontSize: 16, mr: 0.5 }} />
															{Math.abs(priceChangePercentage).toFixed(1)}% off
														</>
													) : (
														<>
															<TrendingUpIcon sx={{ fontSize: 16, mr: 0.5 }} />
															{priceChangePercentage.toFixed(1)}% increase
														</>
													)}
												</DiscountBadge>
											</Stack>
										)}
									</Stack>
								</PriceSection>

								{/* Compact Price History */}
								<CompactChartBox>
										<Box sx={{ height: 120 }}>
											<ProductPriceChart
												priceHistory={formattedPriceHistory}
												currency={product.currency}
												compact
											/>
										</Box>
								</CompactChartBox>

								<Divider />

								{/* Tags Section */}
								<TagsContainer>
									<Stack direction="row" alignItems="center" spacing={2}>
										<Stack direction="row" spacing={1} flexWrap="wrap" sx={{ flex: 1 }}>
											{product.tags && product.tags.length > 0 ? (
												product.tags.map((tag) => (
													<TagChip
														key={tag.id}
														label={tag.name}
														icon={React.createElement(iconMap[tag.icon as keyof typeof Icons] || Label, {
															sx: { color: '#fff' }
														})}
														onDelete={() => handleDeleteTag(tag.id.toString())}
														sx={{
															bgcolor: alpha(tag.color, 0.1),
															color: tag.color,
															'&:hover': {
																bgcolor: alpha(tag.color, 0.15),
															}
														}}
													/>
												))
											) : (
												<Typography variant="body2" color="text.secondary">
													No tags
												</Typography>
											)}
										</Stack>
										<Button
											size="small"
											startIcon={<LocalOfferIcon sx={{ fontSize: 16 }} />}
											onClick={() => setOpenDialog(true)}
											sx={{ 
												color: 'text.secondary',
												'&:hover': { color: 'primary.main' }
											}}
										>
											Add
										</Button>
									</Stack>
								</TagsContainer>

								<Stack direction="row" spacing={2} sx={{ mt: 3 }}>
									<Button
										variant="contained"
										size="large"
										startIcon={<OpenInNewIcon />}
										fullWidth
										component="a"
										href={product.url}
										target="_blank"
										sx={{
											height: 48,
											borderRadius: 2,
											bgcolor: 'primary.main',
											textTransform: 'none'
										}}
									>
										See item on webpage
									</Button>
									<IconButton
										onClick={() => setOpenDeleteConfirm(true)}
										sx={{
											border: 1,
											borderColor: 'divider',
											borderRadius: 2,
											width: 48,
											height: 48,
											'&:hover': {
												bgcolor: 'error.light',
												borderColor: 'error.main',
												color: 'error.main',
											}
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Stack>

								<Divider />
							</Stack>
						</Grid>
					</Grid>

					<Grid container spacing={3}>
						<Grid item xs={12}>
							<NotificationsSection notifications={product.notifications} />
						</Grid>
					</Grid>
				</motion.div>

				{isNotificationModalOpen && (
					<AddNewNotification
						onClose={() => {
							setIsNotificationModalOpen(false);
							setEditingNotification(null);
						}}
						productId={id as string}
						currentPrice={product?.price}
						editingNotification={editingNotification}
						onSuccessAdd={() => {
							dispatch(getProductByIdAction(id as string));
							dispatch(getNotificationListAction());
							setEditingNotification(null);
						}}
					/>
				)}

				<Modal
					open={openZoom}
					onClose={() => setOpenZoom(false)}
					closeAfterTransition
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						p: 2
					}}
				>
					<Fade in={openZoom}>
						<Box
							sx={{
								position: 'relative',
								maxWidth: '90vw',
								maxHeight: '90vh',
								borderRadius: 2,
								overflow: 'hidden',
								bgcolor: 'background.paper',
								boxShadow: 24,
							}}
						>
							<IconButton
								onClick={() => setOpenZoom(false)}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									bgcolor: 'background.paper',
									boxShadow: 1,
									'&:hover': { bgcolor: 'background.paper' }
								}}
							>
								<CloseIcon />
							</IconButton>
							<Box
								component="img"
								src={product.imgUrl}
								alt={product.title}
								sx={{
									width: '100%',
									height: '100%',
									objectFit: 'contain',
									p: 4
								}}
							/>
						</Box>
					</Fade>
				</Modal>

				<TagSelectionDialog
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					productId={id as string}
					existingTags={product.tags}
					onSuccess={() => {
						dispatch(getProductByIdAction(id as string));
					}}
				/>

				<ConfirmationModal
					open={openDeleteConfirm}
					title="Delete Product"
					message="Are you sure you want to delete this product? This action cannot be undone."
					onConfirm={handleDeleteProduct}
					onClose={() => setOpenDeleteConfirm(false)}
				/>

				<ConfirmationModal
					open={!!deleteNotificationId}
					title="Delete Alert"
					message="Are you sure you want to delete this price alert? This action cannot be undone."
					onConfirm={handleDeleteNotification}
					onClose={() => setDeleteNotificationId(null)}
				/>
			</Container>
		</ProductLayout>
	);
}
