import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import * as tagService from "../../../services/tagService";
import { ITag } from "../@types";

import {
	getTagsListAction,
	getTagsListFailed,
	getTagsListSuccess,
	createTagAction,
	updateTagAction,
	deleteTagAction,
} from "./tagsSlice";

function* getTagsSage(): SagaIterator {
	try {
		const { data } = yield call(tagService.getAllTags);
		yield put(getTagsListSuccess(data));
	} catch (error: any) {
		yield put(getTagsListFailed(error.message));
	}
}

function* createTagSaga(action: PayloadAction<Omit<ITag, 'id'>>): SagaIterator {
	try {
		const { data } = yield call(tagService.createTag as any, action.payload);
		yield put(getTagsListAction());
	} catch (error: any) {
		yield put(getTagsListFailed(error.message));
	}
}

function* updateTagSaga(action: PayloadAction<ITag>): SagaIterator {
	try {
		yield call(tagService.updateTag as any, action.payload);
		yield put(getTagsListAction());
	} catch (error: any) {
		yield put(getTagsListFailed(error.message));
	}
}

function* deleteTagSaga(action: PayloadAction<number>): SagaIterator {
	try {
		const { data } = yield call(tagService.deleteTag as any, action.payload);
		yield put(getTagsListAction());
	} catch (error: any) {
		yield put(getTagsListFailed(error.message));
	}
}

function* tagsSaga() {
	yield takeLatest(getTagsListAction.type, getTagsSage);
	yield takeLatest(createTagAction.type, createTagSaga);
	yield takeLatest(updateTagAction.type, updateTagSaga);
	yield takeLatest(deleteTagAction.type, deleteTagSaga);
}

export default tagsSaga;
