import { PropsWithChildren, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import getCustomTheme from "../theme/customTheme";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../store/store";
import { clearNavigation } from "../store/navigationSlice";
import * as React from "react";
import { selectAuth } from "../modules/auth";
import { getToken } from "../services/localStorageService";
import { getMe, initSuccess } from "../modules/auth/store/authSlice";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function MainLayout() {
	const customTheme = createTheme(getCustomTheme("light"));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const navigationPath = useSelector(
		(state: RootState) => state.navigation.path,
	);

	useEffect(() => {
		if (getToken()) {
			dispatch(getMe());
		} else {
			dispatch(initSuccess());
		}
	}, [dispatch]);

	useEffect(() => {
		if (navigationPath) {
			navigate(navigationPath);
			dispatch(clearNavigation());
		}
	}, [navigationPath, navigate, dispatch]);

	return (
		<>
			<ToastContainer />
			<ThemeProvider theme={customTheme}>
				<Outlet />
			</ThemeProvider>
		</>
	);
}

export default MainLayout;
