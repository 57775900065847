import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { IProduct } from "../@types";

interface ProductState {
	loading: boolean;
	error: string | null;
	products: IProduct[];
	currentProduct: IProduct | null;
}

const initialState: ProductState = {
	loading: false,
	error: null,
	products: [],
	currentProduct: null,
};

export const getProductsAction = createAction('products/getProducts');

const productSlice = createSlice({
	name: "products",
	initialState,
	reducers: {
		getProductListSuccessAction: (state, action: PayloadAction<IProduct[]>) => {
			state.loading = false;
			state.products = action.payload;
		},
		removeProductByIdAction: (state, action: PayloadAction<number>) => {
			state.products = state.products.filter((p) => p.id !== action.payload);
		},
		getProductListFailedAction: (state) => {
			state.loading = false;
		},
		getProductListAction: (state) => {
			state.loading = true;
		},
		getProductByIdAction: (state, action: PayloadAction<string>) => {
			state.loading = true;
			state.currentProduct = null;
		},
		getProductBySuccessAction: (state, action: PayloadAction<IProduct>) => {
			state.loading = false;
			state.currentProduct = action.payload;
		},
		removeProductBySuccessAction: (state, action: PayloadAction<number>) => {
			state.products = state.products.filter((p) => p.id !== action.payload);
		},
		addProductAction: (state, action: PayloadAction<IProduct>) => {
			state.products.unshift(action.payload);
		},
		getProductsSuccessAction: (state, action: PayloadAction<IProduct[]>) => {
			state.products = action.payload;
		},
		getProductsFailureAction: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		removeProductSuccessAction: (state) => {
			state.loading = false;
			state.error = null;
		},
		removeProductFailureAction: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		}
	},
});

export const {
	getProductListSuccessAction,
	getProductListAction,
	getProductListFailedAction,
	getProductByIdAction,
	getProductBySuccessAction,
	removeProductBySuccessAction,
	addProductAction,
	getProductsSuccessAction,
	getProductsFailureAction,
	removeProductSuccessAction,
	removeProductFailureAction,
	removeProductByIdAction,
} = productSlice.actions;

export default productSlice.reducer;

// Selectors
export const selectProducts = (state: RootState) => state.products;
export const currentProduct = (state: RootState) =>
	state.products.currentProduct;
