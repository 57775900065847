import React from 'react';
import { Box, Container, Grid, Typography, Link, Stack } from '@mui/material';

const footerSections = [
  {
    title: 'Product',
    links: ['Features', 'Pricing', 'Compare', 'Updates']
  },
  {
    title: 'Company',
    links: ['About', 'Blog', 'Careers', 'Press']
  },
  {
    title: 'Support',
    links: ['Help Center', 'Documentation', 'Contact Us', 'Status']
  },
  {
    title: 'Legal',
    links: ['Privacy', 'Terms', 'Security', 'Cookies']
  }
];

export default function Footer() {
  return (
    <Box 
      component="footer" 
      sx={{ 
        bgcolor: 'grey.900',
        color: 'white',
        py: 8,
        mt: 'auto'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Comparist
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>
              Making product comparison smarter and easier.
            </Typography>
          </Grid>
          {footerSections.map((section) => (
            <Grid item xs={6} sm={3} md={2} key={section.title}>
              <Typography variant="subtitle1" gutterBottom>
                {section.title}
              </Typography>
              <Stack spacing={1}>
                {section.links.map((link) => (
                  <Link
                    key={link}
                    href="#"
                    color="inherit"
                    sx={{ 
                      opacity: 0.7,
                      '&:hover': { opacity: 1 }
                    }}
                  >
                    {link}
                  </Link>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Typography 
          variant="body2" 
          sx={{ 
            mt: 8,
            opacity: 0.7,
            textAlign: 'center'
          }}
        >
          © {new Date().getFullYear()} Comparist. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
} 