import React from 'react';
import PublicLayout from '../../layouts/PublicLayout';
import HeroSection from './sections/HeroSection';
import FeaturesSection from './sections/FeaturesSection';
import HowItWorksSection from './sections/HowItWorksSection';
import PricingSection from './sections/PricingSection';
import CTASection from './sections/CTASection';
import Footer from './sections/Footer';
import PriceComparisonChart from '../../components/PriceComparisonChart';
import { Box, Typography } from '@mui/material';

export default function Home() {
  return (
    <PublicLayout>
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <PricingSection />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          p: 4,
          bgcolor: 'grey.50'
        }}
      >
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          Price Comparison
        </Typography>
        <Box sx={{ height: 400 }}>
          <PriceComparisonChart />
        </Box>
      </Box>
      <CTASection />
      <Footer />
    </PublicLayout>
  );
} 