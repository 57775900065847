import React from 'react';
import { Box, Container, Typography, Button, Stack, Grid } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import DashboardPreview from '../../../components/DashboardPreview';
import ExtensionPreview from '../../../components/ExtensionPreview';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledTypography = styled(Typography)(({ theme }) => ({
  animation: `${fadeIn} 0.6s ease-out forwards`,
  opacity: 0,
}));

const StyledHeroSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  padding: theme.spacing(20, 0, 15),
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${require('../../../assets/images/hero/hero-pattern.svg')})`,
    backgroundSize: 'cover',
    opacity: 0.1,
  }
}));

const HeroImage = styled('img')({
  width: '100%',
  height: 'auto',
  maxWidth: 600,
  filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2))',
  transform: 'perspective(1000px) rotateY(-10deg)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'perspective(1000px) rotateY(-5deg)',
  }
});

export default function HeroSection() {
  return (
    <StyledHeroSection>
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6}>
            <Stack spacing={4} maxWidth="600px">
              <StyledTypography 
                variant="h1" 
                sx={{ 
                  fontSize: { xs: '2.5rem', md: '4rem' },
                  fontWeight: 800,
                  lineHeight: 1.2,
                  textShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  animationDelay: '0.2s'
                }}
              >
                Smart Product Comparison Platform
              </StyledTypography>
              <Typography variant="h5" sx={{ opacity: 0.9, lineHeight: 1.6 }}>
                Save time and money by comparing products across multiple platforms. 
                Get real-time price alerts and make informed decisions.
              </Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button 
                  variant="contained" 
                  size="large"
                  sx={{ 
                    bgcolor: 'white',
                    color: 'primary.main',
                    px: 4,
                    py: 1.5,
                    '&:hover': {
                      bgcolor: 'grey.100'
                    }
                  }}
                >
                  Start Comparing
                </Button>
                <Button 
                  variant="outlined" 
                  size="large"
                  sx={{ 
                    borderColor: 'white',
                    color: 'white',
                    px: 4,
                    '&:hover': {
                      borderColor: 'grey.100',
                      bgcolor: 'rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  Watch Demo
                </Button>
              </Stack>
              <Stack direction="row" spacing={4} sx={{ pt: 2 }}>
                <Box>
                  <Typography variant="h4" fontWeight="bold">50K+</Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>Active Users</Typography>
                </Box>
                <Box>
                  <Typography variant="h4" fontWeight="bold">1M+</Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>Products Compared</Typography>
                </Box>
                <Box>
                  <Typography variant="h4" fontWeight="bold">30%</Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7 }}>Average Savings</Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <DashboardPreview />
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                right: -40,
                transform: 'translateY(-50%)',
                zIndex: 2,
                display: { xs: 'none', md: 'block' }
              }}
            >
              <ExtensionPreview />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </StyledHeroSection>
  );
} 