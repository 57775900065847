import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import * as productService from "../../../services/productService";
import { navigate } from "../../../store/navigationSlice";
import Routes from "../../../configs/routes";

import {
	getProductListAction,
	removeProductByIdAction,
	getProductByIdAction,
	getProductBySuccessAction,
	removeProductBySuccessAction,
	getProductsAction,
	getProductListSuccessAction,
	getProductListFailedAction,
	removeProductSuccessAction,
	removeProductFailureAction,
} from "./productSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

function* getProductListSage({
	payload,
}: PayloadAction<string[]>): SagaIterator {
	try {
		const { data } = yield call(productService.getProductList, payload);
		yield put(getProductListSuccessAction(data));
	} catch (error: any) {
		yield put(getProductListFailedAction());
	}
}

function* getProductByIdSaga({ payload }: PayloadAction<number>): SagaIterator {
	try {
		const { data } = yield call(productService.getProductById, payload);
		yield put(getProductBySuccessAction(data));
	} catch (error: any) {
		yield put(getProductListFailedAction());
	}
}

function* handleRemoveProduct(action: ReturnType<typeof removeProductByIdAction>) {
	try {
		yield call(productService.removeProductById, action.payload);
		yield put(removeProductSuccessAction());
		yield put(navigate(Routes.products));
		yield call(toast.success, "Product deleted successfully");
	} catch (error: any) {
		yield put(removeProductFailureAction(error.message));
		yield call(toast.error, error.message);
	}
}

function* fetchProducts(): SagaIterator {
	try {
		const { data } = yield call(productService.getProductList, []);
		yield put(getProductListSuccessAction(data));
	} catch (error: any) {
		yield put(getProductListFailedAction());
	}
}

function* productSaga() {
	yield takeLatest(getProductListAction.type, getProductListSage);
	yield takeLatest(getProductByIdAction.type, getProductByIdSaga);
	yield takeLatest(removeProductByIdAction.type, handleRemoveProduct);
	yield takeLatest(getProductsAction.type, fetchProducts);
}

export default productSaga;
