import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import * as notificationService from "../../../services/notificationService";

import {
	getNotificationListAction,
	getNotificationListSuccess,
	getNotificationListFailed,
	addNotificationAction,
	addNotificationFailed,
} from "./notificationSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../@types";

function* getNotificationsSage(): SagaIterator {
	try {
		const { data } = yield call(notificationService.getAllNotifications);
		console.log("getNotificationsSage", data);
		yield put(getNotificationListSuccess(data));
	} catch (error: any) {
		yield put(getNotificationListFailed());
	}
}

function* addNotificationSage(action: PayloadAction<INotification>): SagaIterator {
	try {
		const { data } = yield call(notificationService.addNotification, action.payload);
		yield put(addNotificationAction(data));
	} catch (error: any) {
		yield put(addNotificationFailed());
	}
}

function* notificationSaga() {
	yield takeLatest(getNotificationListAction.type, getNotificationsSage);
	yield takeLatest(addNotificationAction.type, addNotificationSage);
}

export default notificationSaga;
