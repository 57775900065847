import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import {
	loginRequest,
	loginSuccess,
	loginFailure,
	getMe as getMeAction,
	signupRequest,
	signupFailure,
	signupSuccess,
} from "./authSlice";
import { login, getMe, signup } from "../../../services/authService";
import { setToken } from "../../../services/localStorageService";
import { navigate } from "../../../store/navigationSlice";
import Routes from "../../../configs/routes";
import { generatePath } from "react-router-dom";

function* loginSaga(action: ReturnType<typeof loginRequest>): SagaIterator {
	try {
		const response = yield call(login, action.payload);
		setToken(response.data.access_token);
		const { data } = yield call(getMe);
		yield put(loginSuccess(data));
		yield put(navigate(Routes.dashboard));
	} catch (error: any) {
		console.log(error.response.status);
		if (error.response.status === 400) {
			yield put(navigate(generatePath(Routes.confirmEmail, { email: action.payload.email })));
		} else {
			yield put(loginFailure("Wrong Credentials"));
		}
	}
}

function* signUpSaga(action: ReturnType<typeof signupRequest>): SagaIterator {
	try {
		yield call(signup, action.payload);
		yield put(signupSuccess(action.payload));
		yield put(navigate(Routes.confirmEmail));
	} catch (error: any) {
		if (error.status === 409) {
			yield put(signupFailure("Email already exists!!"));
		} else {
			yield put(signupFailure("Wrong Credentials"));
		}
	}
}

function* getMeSaga(): SagaIterator {
	try {
		const { data } = yield call(getMe);
		yield put(loginSuccess(data));
	} catch (error: any) {
		yield put(loginFailure(error.message));
	}
}

function* authSaga() {
	yield takeLatest(loginRequest.type, loginSaga);
	yield takeLatest(signupRequest.type, signUpSaga);
	yield takeLatest(getMeAction.type, getMeSaga);
}

export default authSaga;
