import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { INotification } from "../@types";

interface ProductState {
	loading: boolean;
	error: string | null;
	notifications: INotification[];
}

const initialState: ProductState = {
	loading: false,
	error: null,
	notifications: [],
};

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		getNotificationListSuccess: (
			state,
			action: PayloadAction<INotification[]>,
		) => {
			state.loading = false;
			state.notifications = action.payload;
		},
		getNotificationListFailed: (state) => {
			state.loading = false;
		},
		getNotificationListAction: (state) => {
			state.loading = true;
		},
		addNotificationAction: (state, action: PayloadAction<Partial<INotification>>) => {
			state.loading = true
			state.notifications.push(action.payload as INotification);
		},
		addNotificationFailed: (state) => {
			state.loading = false;
		},
	},
});

export const {
	getNotificationListAction,
	getNotificationListFailed,
	getNotificationListSuccess,
	addNotificationAction,
	addNotificationFailed,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

// Selectors
export const selectNotifications = (state: RootState) => state.notifications;
