"use client"

import { useState } from "react"
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
  Radio,
  FormControlLabel,
  RadioGroup,
  Tab,
  Tabs,
  Skeleton
} from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import PaymentIcon from '@mui/icons-material/Payment'
import AppleIcon from '@mui/icons-material/Apple'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`payment-tabpanel-${index}`}
      aria-labelledby={`payment-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `payment-tab-${index}`,
    "aria-controls": `payment-tabpanel-${index}`,
  }
}

type PaymentCard = {
  id: string
  cardBrand: string
  last4: string
  expiryMonth: string
  expiryYear: string
  isDefault: boolean
  loading?: boolean
}

export default function PaymentMethods() {
  const [savedCards, setSavedCards] = useState<PaymentCard[]>([
    {
      id: "card_1",
      cardBrand: "Visa",
      last4: "4242",
      expiryMonth: "12",
      expiryYear: "2026",
      isDefault: true
    },
    {
      id: "card_2",
      cardBrand: "Mastercard",
      last4: "8394",
      expiryMonth: "06",
      expiryYear: "2025",
      isDefault: false
    }
  ])
  
  const [isLoading, setIsLoading] = useState(false)
  const [cardsLoading, setCardsLoading] = useState(true)
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [cardToDelete, setCardToDelete] = useState<string | null>(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error" | "info" | "warning">("success")
  const [tabValue, setTabValue] = useState(0)
  const [showCvv, setShowCvv] = useState(false)

  // Simulate cards loading when component mounts
  useState(() => {
    setTimeout(() => {
      setCardsLoading(false)
    }, 1000)
  })

  const handleSetDefault = (cardId: string) => {
    setIsLoading(true)
    // Simulate API call
    setTimeout(() => {
      setSavedCards(savedCards.map(card => ({
        ...card,
        isDefault: card.id === cardId
      })))
      setIsLoading(false)
      showNotification("Default payment method updated")
    }, 800)
  }

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleDeleteCard = (cardId: string) => {
    setCardToDelete(cardId)
  }

  const confirmDeleteCard = () => {
    if (!cardToDelete) return
    
    setIsLoading(true)
    // Simulate API call
    setTimeout(() => {
      setSavedCards(savedCards.filter(card => card.id !== cardToDelete))
      setCardToDelete(null)
      setIsLoading(false)
      showNotification("Payment method removed")
    }, 800)
  }

  const handleAddCard = (event: React.FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    
    // Simulate API call
    setTimeout(() => {
      const newCard: PaymentCard = {
        id: `card_${Math.random().toString(36).substring(7)}`,
        cardBrand: "Visa",
        last4: "1234",
        expiryMonth: "08",
        expiryYear: "2027",
        isDefault: savedCards.length === 0,
        loading: true
      }
      
      setSavedCards([...savedCards, newCard])

      // Simulate loading of the new card
      setTimeout(() => {
        setSavedCards(cards => 
          cards.map(card => 
            card.id === newCard.id ? { ...card, loading: false } : card
          )
        )
        setShowAddDialog(false)
        setIsLoading(false)
        showNotification("New payment method added")
      }, 500)
    }, 1000)
  }

  // Notification function
  const showNotification = (message: string, severity: "success" | "error" | "info" | "warning" = "success") => {
    setAlertMessage(message)
    setAlertSeverity(severity)
    setShowAlert(true)
  }

  // Handle closing the notification
  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  // Render a skeleton placeholder for a loading payment method
  const renderCardSkeleton = () => (
    <Box>
      <ListItem 
        disablePadding 
        sx={{ 
          py: 2,
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1, sm: 0 }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}>
          <Skeleton variant="text" width={150} height={24} sx={{ mr: 3 }} />
          <Skeleton variant="text" width={120} height={24} sx={{ mr: 2 }} />
        </Box>
        <Box 
          sx={{ 
            ml: { xs: 0, sm: 'auto' }, 
            mt: { xs: 1, sm: 0 },
            width: { xs: '100%', sm: 'auto' }
          }}
        >
          <Skeleton variant="rectangular" width={100} height={30} />
        </Box>
      </ListItem>
    </Box>
  )

  return (
    <Box>
      {/* Payment Methods Header */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
          Payment Methods
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          Manage your saved payment methods
        </Typography>
      </Box>
      
      {/* Saved Cards List */}
      {cardsLoading ? (
        <Box sx={{ mb: 4 }}>
          {[1, 2].map((_, index) => (
            <Box key={index}>
              {index > 0 && <Divider />}
              {renderCardSkeleton()}
            </Box>
          ))}
        </Box>
      ) : savedCards.length > 0 ? (
        <List disablePadding sx={{ mb: 4 }}>
          {savedCards.map((card, index) => (
            <Box key={card.id}>
              {index > 0 && <Divider />}
              {card.loading ? (
                renderCardSkeleton()
              ) : (
                <ListItem 
                  disablePadding 
                  sx={{ 
                    py: 2,
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 1, sm: 0 }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', width: { xs: '100%', sm: 'auto' } }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        display: 'inline-block',
                        fontFamily: 'monospace',
                        fontWeight: 500,
                        mr: 3
                      }}
                    >
                      {card.cardBrand} •••• {card.last4}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                      Expires {card.expiryMonth}/{card.expiryYear}
                    </Typography>
                    {card.isDefault && (
                      <Typography 
                        variant="caption" 
                        sx={{ 
                          fontWeight: 500, 
                          color: 'primary.main',
                          borderLeft: '1px solid #ddd',
                          pl: 1,
                          ml: 1
                        }}
                      >
                        Default
                      </Typography>
                    )}
                  </Box>
                  <Box 
                    sx={{ 
                      ml: { xs: 0, sm: 'auto' }, 
                      mt: { xs: 1, sm: 0 },
                      width: { xs: '100%', sm: 'auto' },
                      display: 'flex',
                      justifyContent: { xs: 'flex-start', sm: 'flex-end' }
                    }}
                  >
                    {!card.isDefault && (
                      <Button 
                        size="small" 
                        onClick={() => handleSetDefault(card.id)}
                        disabled={isLoading}
                        sx={{ 
                          textTransform: 'none',
                          mr: 1,
                          fontWeight: 400,
                          borderRadius: 0
                        }}
                      >
                        Set as default
                      </Button>
                    )}
                    <IconButton 
                      size="small" 
                      edge="end"
                      onClick={() => handleDeleteCard(card.id)}
                      sx={{ p: 0.75 }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ListItem>
              )}
            </Box>
          ))}
        </List>
      ) : (
        <Box sx={{ mb: 4, py: 3, borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }}>
          <Typography variant="body1" color="text.secondary" align="center">
            No payment methods found
          </Typography>
        </Box>
      )}

      {/* Add Button */}
      <Button 
        startIcon={<AddIcon />} 
        onClick={() => setShowAddDialog(true)}
        sx={{ 
          textTransform: 'none',
          fontWeight: 400,
          borderRadius: 0
        }}
      >
        Add Payment Method
      </Button>

      {/* Add Payment Method Dialog */}
      <Dialog 
        open={showAddDialog} 
        onClose={() => !isLoading && setShowAddDialog(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 0
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Add Payment Method
        </DialogTitle>
        <DialogContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 400,
                  minHeight: 48,
                  opacity: 0.7,
                  '&.Mui-selected': {
                    fontWeight: 500,
                    opacity: 1
                  }
                }
              }}
            >
              <Tab 
                label="Credit Card" 
                icon={<CreditCardIcon fontSize="small" />}
                iconPosition="start"
                disableRipple
                {...a11yProps(0)}
              />
              <Tab 
                label="PayPal" 
                icon={<PaymentIcon fontSize="small" />}
                iconPosition="start"
                disableRipple
                {...a11yProps(1)}
              />
              <Tab 
                label="Apple Pay" 
                icon={<AppleIcon fontSize="small" />}
                iconPosition="start"
                disableRipple
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>

          <TabPanel value={tabValue} index={0}>
            <form onSubmit={handleAddCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Card Number"
                    fullWidth
                    required
                    placeholder="1234 5678 9012 3456"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CreditCardIcon color="action" fontSize="small" />
                        </InputAdornment>
                      ),
                      sx: { borderRadius: 0 }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cardholder Name"
                    fullWidth
                    required
                    placeholder="John Smith"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      sx: { borderRadius: 0 }
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="Expiry Date"
                    fullWidth
                    required
                    placeholder="MM/YY"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      sx: { borderRadius: 0 }
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label="CVV"
                    fullWidth
                    required
                    placeholder="123"
                    type={showCvv ? "text" : "password"}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setShowCvv(!showCvv)}
                            edge="end"
                          >
                            {showCvv ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { borderRadius: 0 }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel 
                    control={<Radio size="small" checked />} 
                    label={
                      <Typography variant="body2">
                        Set as default payment method
                      </Typography>
                    } 
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button 
                  variant="outlined" 
                  onClick={() => setShowAddDialog(false)}
                  disabled={isLoading}
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 400,
                    borderRadius: 0
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  type="submit" 
                  variant="contained" 
                  disabled={isLoading}
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 400,
                    borderRadius: 0,
                    boxShadow: 'none',
                    minWidth: 100,
                    '&:hover': {
                      boxShadow: 'none'
                    }
                  }}
                >
                  {isLoading ? <CircularProgress size={20} /> : "Save"}
                </Button>
              </Box>
            </form>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ textAlign: 'center', py: 3 }}>
              <Typography variant="body1" paragraph>
                Connect your PayPal account for faster checkout
              </Typography>
              <Button 
                variant="contained" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 400,
                  borderRadius: 0,
                  bgcolor: '#0070BA',
                  '&:hover': {
                    bgcolor: '#005EA6'
                  },
                  boxShadow: 'none'
                }}
              >
                Connect PayPal
              </Button>
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Box sx={{ textAlign: 'center', py: 3 }}>
              <Typography variant="body1" paragraph>
                Set up Apple Pay for secure payments
              </Typography>
              <Button 
                variant="contained" 
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 400,
                  borderRadius: 0,
                  bgcolor: '#000',
                  '&:hover': {
                    bgcolor: '#333'
                  },
                  boxShadow: 'none'
                }}
              >
                Set Up Apple Pay
              </Button>
            </Box>
          </TabPanel>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={cardToDelete !== null}
        onClose={() => !isLoading && setCardToDelete(null)}
        PaperProps={{
          sx: {
            borderRadius: 0
          }
        }}
      >
        <DialogTitle>
          Remove Payment Method
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this payment method?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => setCardToDelete(null)} 
            disabled={isLoading}
            sx={{ 
              textTransform: 'none',
              fontWeight: 400,
              borderRadius: 0
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmDeleteCard} 
            color="error" 
            variant="contained"
            disabled={isLoading}
            sx={{ 
              textTransform: 'none',
              fontWeight: 400,
              borderRadius: 0,
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none'
              }
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert/Notification */}
      <Snackbar 
        open={showAlert} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity={alertSeverity} 
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: 0
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

