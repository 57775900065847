import React from 'react';
import { Box } from '@mui/material';
import PublicHeader from '../components/PublicHeader';

const PublicLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <PublicHeader />
      {children}
    </Box>
  );
};

export default PublicLayout; 