import React from 'react';
import { Box, Paper, useTheme } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
  ChartData
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { easing } from '@mui/material/styles';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface PriceHistory {
  date: string;
  price: number;
  lowestPrice?: number;
}

interface ProductPriceChartProps {
  priceHistory: PriceHistory[];
  currency: string;
  compact?: boolean;
}

export default function ProductPriceChart({ priceHistory, currency, compact }: ProductPriceChartProps) {
  const theme = useTheme();

  if (!priceHistory?.length || priceHistory.length <= 1) {
    return (
      <Box 
        sx={{ 
          height: compact ? 120 : 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          color: 'text.secondary',
          fontSize: 14
        }}
      >
        No price history available yet
      </Box>
    );
  }

  // Add date formatter
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // Update chart data and options
  const chartData: ChartData<'line'> = {
    labels: priceHistory.map(item => formatDate(item.date)),
    datasets: [{
      data: priceHistory.map(item => item.price),
      borderColor: theme.palette.primary.main,
      backgroundColor: 'transparent',
      borderWidth: 2,
      tension: 0.4,
      pointRadius: 6,
      pointBackgroundColor: theme.palette.background.paper,
      pointBorderColor: theme.palette.primary.main,
      pointBorderWidth: 2,
      pointHoverRadius: 8,
      pointHoverBorderWidth: 3,
      pointHoverBackgroundColor: theme.palette.background.paper,
      pointHoverBorderColor: theme.palette.primary.main,
      fill: false
    }]
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: 'easeInOutQuart'
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: 8,
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (context) => `${currency}${context.parsed.y.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: theme.palette.divider,
          lineWidth: 1
        },
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            size: 11
          },
          maxRotation: 0
        }
      },
      y: {
        grid: {
          display: true,
          color: theme.palette.divider,
          lineWidth: 1
        },
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            size: 11
          },
          callback: (value) => `${value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        },
        border: {
          display: false
        }
      }
    }
  };

  // Return simplified component without Paper wrapper
  return (
    <Box sx={{ height: compact ? 120 : 400, position: 'relative' }}>
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
} 