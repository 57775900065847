import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { ITag } from "../@types";

interface TagsState {
	loading: boolean;
	error: string | null;
	tags: ITag[];
}

const initialState: TagsState = {
	loading: false,
	error: null,
	tags: [],
};

const tagsSlice = createSlice({
	name: "tags",
	initialState,
	reducers: {
		getTagsListSuccess: (state, action: PayloadAction<ITag[]>) => {
			state.loading = false;
			state.tags = action.payload.map(t => ({...t, color: t.color ?? "#fff"}));
		},
		getTagsListFailed: (state) => {
			state.loading = false;
		},
		getTagsListAction: (state) => {
			state.loading = true;
		},
		createTagAction: (state, action: PayloadAction<Omit<ITag, 'id' | 'products'>>) => {
			state.loading = true;
		},
		updateTagAction: (state, action: PayloadAction<ITag>) => {
			state.loading = true;
		},
		deleteTagAction: (state, action: PayloadAction<number>) => {
			state.loading = true;
		},
	},
});

export const { getTagsListFailed, getTagsListAction, getTagsListSuccess, createTagAction, updateTagAction, deleteTagAction } =
	tagsSlice.actions;

export default tagsSlice.reducer;

// Selectors
export const selectTags = (state: RootState) => state.tags;
export const selectTagsList = (state: RootState) => state.tags.tags;
export const selectTagsLoading = (state: RootState) => state.tags.loading;