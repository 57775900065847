import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { IProduct } from '../../product/@types';

interface ProductState {
  products: IProduct[];
}

const initialState: ProductState = {
  products: []
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {}
});

export const selectProducts = (state: RootState) => state.products;

export default productSlice.reducer; 