import { SvgIcon, SvgIconProps } from '@mui/material';

export const EmptyStateIllustration = (props: SvgIconProps) => (
  <SvgIcon {...props} sx={{ width: 200, height: 200, fontSize: 'inherit' }}>
    <path
      fill="currentColor"
      d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z"
    />
    <path
      fill="currentColor"
      d="M12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"
    />
    <path fill="currentColor" d="M12 8l-1.41 1.41L12.17 11H8v2h4.17l-1.58 1.59L12 16l4-4-4-4z" />
  </SvgIcon>
); 