import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Box,
	Typography,
	IconButton,
	InputAdornment,
	Fade,
	CircularProgress,
	styled,
	useTheme,
	alpha,
	Chip,
	Stack,
	Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import { motion } from 'framer-motion';

import * as Icons from '@mui/icons-material';

import {
	CheckInterval,
	Condition,
	IProduct,
	IProductInformationByUrl,
	NotificationType,
} from "../@types";
import { useTranslation } from "react-i18next";
import { addNotification } from "../../../services/notificationService";
import { useParams } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { useSelector } from "react-redux";
import { selectAuth } from "../../auth";
import { showErrorToast, showSuccessToast } from "../../../utils/toaster";
import { INotification } from "../../notification/@types";
import {
	addTagToProduct,
	getProductInfoByUrl,
	saveProduct,
} from "../../../services/productService";
import Flex from "../../../components/Flex";
import { formatPrice } from "../../../utils";
import TagSelectionDialog from './TagSelectionDialog';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { keyframes } from '@mui/material/styles';
import { selectTagsList } from 'modules/tag/store/tagsSlice';
import { ITag } from 'modules/tag/@types';
import { Label } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		borderRadius: 24,
		padding: theme.spacing(2),
		background: theme.palette.mode === 'dark' 
			? 'linear-gradient(45deg, #1a1b1e 30%, #2c2d31 90%)'
			: 'linear-gradient(45deg, #ffffff 30%, #f8fafc 90%)',
		boxShadow: theme.palette.mode === 'dark'
			? '0 8px 32px rgba(0, 0, 0, 0.4)'
			: '0 8px 32px rgba(0, 0, 0, 0.1)',
		maxWidth: 600,
		width: '100%'
	}
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: 16,
		backgroundColor: theme.palette.mode === 'dark' 
			? alpha(theme.palette.common.white, 0.05)
			: alpha(theme.palette.common.white, 0.9),
		transition: theme.transitions.create(['background-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: theme.palette.mode === 'dark'
				? alpha(theme.palette.common.white, 0.08)
				: alpha(theme.palette.common.white, 1)
		},
		'&.Mui-focused': {
			boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
			backgroundColor: theme.palette.mode === 'dark'
				? alpha(theme.palette.common.white, 0.08)
				: alpha(theme.palette.common.white, 1)
		}
	}
}));

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const TagIcon = styled(LocalOfferIcon)(({ theme }) => ({
	fontSize: 80,
	color: theme.palette.primary.main,
	opacity: 0.8,
	animation: `${floatAnimation} 3s ease-in-out infinite`
}));

interface AddNewProductProps {
	onClose: () => void;
	onSuccessAdd: (product: IProduct) => void;
}

export default function AddNewProduct({ onClose, onSuccessAdd }: AddNewProductProps) {
	const theme = useTheme();
	const [url, setUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [productInfo, setProductInfo] = useState<IProductInformationByUrl | null>(null);
	const [showTagDialog, setShowTagDialog] = useState(false);
	const [savedProduct, setSavedProduct] = useState<IProduct | null>(null);
	const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
	const tags = useSelector(selectTagsList);

	const handleFetchProduct = async () => {
		if (!url) {
			setError('Please enter a product URL');
			return;
		}
		setLoading(true);
		setError('');
		
		try {
			const { data } = await getProductInfoByUrl(url);
			setProductInfo(data);
		} catch (err: any) {
			setError(err.message || 'Failed to fetch product');
		} finally {
			setLoading(false);
		}
	};

	const handleSave = async () => {
		if (!productInfo) return;
		
		setLoading(true);
		try {
			const { data } = await saveProduct(url, productInfo);
			setSavedProduct(data);
			setShowTagDialog(true);
		} catch (err: any) {
			setError(err.message || 'Failed to save product');
			setLoading(false);
		}
	};

	const handleFinish = (withTags: boolean) => {
		if (!savedProduct) return;
		
		onSuccessAdd(savedProduct);
		onClose();
		showSuccessToast(`Product ${withTags ? 'added with tags' : 'added'} successfully`);
	};

	const renderPreview = () => (
		<Box sx={{ mt: 3 }}>
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
			>
				<Box sx={{
					p: 4,
					borderRadius: 4,
					bgcolor: theme.palette.mode === 'dark' 
						? alpha(theme.palette.common.white, 0.05)
						: alpha(theme.palette.common.white, 0.9),
					boxShadow: theme.shadows[1],
					border: '1px solid',
					borderColor: theme.palette.mode === 'dark'
						? alpha(theme.palette.common.white, 0.1)
						: alpha(theme.palette.common.black, 0.05)
				}}>
					<Box sx={{ display: 'flex', gap: 4, alignItems: 'flex-start' }}>
						<Box
							component="img"
							src={productInfo?.imgUrl}
							alt={productInfo?.title}
							sx={{
								width: 160,
								height: 160,
								objectFit: 'contain',
								borderRadius: 3,
								bgcolor: 'background.paper',
								p: 2,
								boxShadow: theme.shadows[1]
							}}
						/>
						<Box sx={{ flex: 1 }}>
							<Typography variant="h6" fontWeight="bold" gutterBottom>
								{productInfo?.title}
							</Typography>
							<Typography 
								variant="h5" 
								color="primary.main" 
								fontWeight="bold"
								sx={{ mb: 2 }}
							>
								{productInfo?.extractedPrice !== undefined 
									? formatPrice(productInfo.extractedPrice, productInfo.extractedCurrency)
									: '-'}
							</Typography>
							<Typography 
								variant="body1" 
								color="text.secondary"
								sx={{ 
									opacity: 0.8,
									display: '-webkit-box',
									WebkitLineClamp: 3,
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden'
								}}
							>
								{productInfo?.descriptions?.[0]}
							</Typography>
						</Box>
					</Box>
				</Box>
			</motion.div>
		</Box>
	);

	const renderTagSelection = () => (
		<Box sx={{ 
			mt: 3, 
			textAlign: 'center',
			px: 4,
			py: 6,
			borderRadius: 4,
			bgcolor: theme.palette.mode === 'dark' 
				? alpha(theme.palette.primary.main, 0.05)
				: alpha(theme.palette.primary.main, 0.02),
			border: '1px solid',
			borderColor: theme.palette.mode === 'dark'
				? alpha(theme.palette.primary.main, 0.1)
				: alpha(theme.palette.primary.main, 0.1)
		}}>
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.4 }}
			>
				<TagIcon />
				
				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ delay: 0.2, duration: 0.4 }}
				>
					<Typography 
						variant="h5" 
						gutterBottom 
						sx={{ 
							mt: 3,
							fontWeight: 'bold',
							background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
							WebkitBackgroundClip: 'text',
							WebkitTextFillColor: 'transparent'
						}}
					>
						Organize with Tags
					</Typography>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ delay: 0.3, duration: 0.4 }}
				>
					<Typography 
						variant="body1" 
						color="text.secondary"
						sx={{ mb: 4, opacity: 0.8, maxWidth: 400, mx: 'auto' }}
					>
						Add tags to categorize your products and find them easily later
					</Typography>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ delay: 0.4, duration: 0.4 }}
				>
					<Autocomplete
						multiple
						options={tags}
						value={selectedTags}
						onChange={(_, newValue) => setSelectedTags(newValue)}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => (
							<StyledTextField
								{...params}
								placeholder="Search or select tags..."
								sx={{ mb: 3 }}
							/>
						)}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								<Stack direction="row" spacing={1} alignItems="center">
									{React.createElement(Icons[option.icon as keyof typeof Icons] || Label, { 
										sx: { 
											color: option.color || theme.palette.primary.main,
											fontSize: 20
										}
									})}
									<Typography>{option.name}</Typography>
								</Stack>
							</Box>
						)}
						renderTags={(tagValues, getTagProps) =>
							tagValues.map((tag, index) => (
								<Chip
									{...getTagProps({ index })}
									key={tag.id}
									label={tag.name}
									icon={React.createElement(Icons[tag.icon as keyof typeof Icons] || Label, {
										style: { color: 'inherit' }
									})}
									sx={{
										bgcolor: alpha(tag.color || theme.palette.primary.main, 0.1),
										color: tag.color || theme.palette.primary.main,
										borderRadius: 2,
										'& .MuiChip-icon': {
											color: 'inherit'
										}
									}}
								/>
							))
						}
					/>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ delay: 0.5, duration: 0.4 }}
				>
					<Stack 
						direction="row" 
						spacing={2} 
						justifyContent="center"
						sx={{ mt: 4 }}
					>
						<Button
							variant="outlined"
							onClick={() => handleFinish(false)}
							sx={{
								borderRadius: 3,
								textTransform: 'none',
								px: 4,
								py: 1.5,
								borderColor: alpha(theme.palette.primary.main, 0.3),
								'&:hover': {
									borderColor: theme.palette.primary.main,
									bgcolor: alpha(theme.palette.primary.main, 0.05)
								}
							}}
						>
							Skip Tags
						</Button>
						<Button
							variant="contained"
							onClick={async () => {
								if (selectedTags.length && savedProduct?.id) {
									try {
										await addTagToProduct(String(savedProduct.id), selectedTags.map(tag => tag.name));
										handleFinish(true);
									} catch (err: any) {
										setError(err.message || 'Failed to add tags');
									}
								}
							}}
							sx={{
								borderRadius: 3,
								textTransform: 'none',
								px: 4,
								py: 1.5,
								boxShadow: `0 8px 16px ${alpha(theme.palette.primary.main, 0.2)}`,
								'&:hover': {
									boxShadow: `0 12px 20px ${alpha(theme.palette.primary.main, 0.3)}`
								}
							}}
						>
							Save with Tags
						</Button>
					</Stack>
				</motion.div>
			</motion.div>
		</Box>
	);

	const renderContent = () => {
		if (loading) {
			return (
				<Box sx={{ display: 'flex', justifyContent: 'center', py: 6 }}>
					<CircularProgress />
				</Box>
			);
		}

		return (
			<>
				<Typography 
					variant="body1" 
					color="text.secondary" 
					sx={{ mb: 3, opacity: 0.8 }}
				>
					{productInfo 
						? "Review the product details below before adding it to your tracking list."
						: "Enter the product URL to start tracking its price changes."}
				</Typography>

				{!productInfo && (
					<StyledTextField
						autoFocus
						fullWidth
						placeholder="Paste product URL here"
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						error={!!error}
						helperText={error}
						disabled={loading}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<LinkIcon color="primary" />
								</InputAdornment>
							)
						}}
					/>
				)}

				{productInfo && renderPreview()}
			</>
		);
	};

	return (
		<StyledDialog
			open={true}
			onClose={loading ? undefined : onClose}
			maxWidth="sm"
			fullWidth
			TransitionComponent={Fade}
		>
			<motion.div
				initial={{ opacity: 0, y: 20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
			>
				<Box sx={{ position: 'relative' }}>
					<DialogTitle sx={{ pb: 0 }}>
						<Typography variant="h5" component="div" fontWeight="bold">
							{savedProduct ? 'Add Tags' : productInfo ? 'Preview Product' : 'Add New Product'}
						</Typography>
						{!loading && (
							<IconButton
								onClick={onClose}
								sx={{
									position: 'absolute',
									right: 8,
									top: 8,
									color: 'text.secondary'
								}}
							>
								<CloseIcon />
							</IconButton>
						)}
					</DialogTitle>

					<DialogContent sx={{ pt: 3 }}>
						{savedProduct ? renderTagSelection() : renderContent()}
					</DialogContent>

					{!savedProduct && (
						<DialogActions sx={{ px: 3, pb: 3 }}>
							<Button
								onClick={onClose}
								disabled={loading}
								sx={{
									borderRadius: 3,
									textTransform: 'none',
									color: 'text.secondary',
									px: 4
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={productInfo ? handleSave : handleFetchProduct}
								variant="contained"
								disabled={loading || !url}
								sx={{
									borderRadius: 3,
									textTransform: 'none',
									px: 4,
									position: 'relative',
									minWidth: 120
								}}
							>
								{loading ? (
									<CircularProgress size={24} color="inherit" />
								) : productInfo ? (
									'Add to Tracking'
								) : (
									'Preview Product'
								)}
							</Button>
						</DialogActions>
					)}
				</Box>
			</motion.div>
		</StyledDialog>
	);
}
