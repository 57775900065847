import { type AxiosPromise } from "axios";
import axiosInstance from "./baseService";
import { INotification } from "../modules/notification/@types";

export const addNotification = (data: INotification): AxiosPromise<void> => {
	return axiosInstance.post("/notifications", data);
};

export const removeNotification = (
	id: string,
): AxiosPromise<INotification[]> => {
	return axiosInstance.delete(`/notifications/${id}`);
};

export const getAllNotifications = (): AxiosPromise<INotification[]> => {
	return axiosInstance.get("/notifications");
};
