import React from 'react';
import { Box, Container, Typography, Grid, Paper, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Check } from '@mui/icons-material';

const plans = [
  {
    title: 'Free',
    price: '$0',
    features: [
      'Basic product comparison',
      'Up to 5 tracked items',
      'Email notifications',
      'Basic analytics'
    ]
  },
  {
    title: 'Pro',
    price: '$9.99',
    features: [
      'Advanced comparison tools',
      'Unlimited tracked items',
      'Real-time notifications',
      'Advanced analytics',
      'Priority support'
    ],
    highlighted: true
  },
  {
    title: 'Enterprise',
    price: 'Custom',
    features: [
      'Custom integration',
      'Dedicated support',
      'API access',
      'Custom analytics',
      'SLA guarantee'
    ]
  }
];

export default function PricingSection() {
  return (
    <Box sx={{ py: 10, bgcolor: 'grey.50' }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          textAlign="center" 
          sx={{ mb: 6, fontWeight: 700 }}
        >
          Pricing Plans
        </Typography>
        <Grid container spacing={4}>
          {plans.map((plan, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                sx={{
                  p: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  ...(plan.highlighted && {
                    border: 2,
                    borderColor: 'primary.main'
                  })
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
                  {plan.title}
                </Typography>
                <Typography variant="h3" sx={{ mb: 4 }}>
                  {plan.price}
                </Typography>
                <List sx={{ mb: 4, flexGrow: 1 }}>
                  {plan.features.map((feature, idx) => (
                    <ListItem key={idx} disableGutters>
                      <ListItemIcon sx={{ minWidth: 40 }}>
                        <Check color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant={plan.highlighted ? "contained" : "outlined"}
                  size="large"
                  fullWidth
                >
                  Get Started
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 