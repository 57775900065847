import * as React from 'react';
import {
	Box,
	Container,
	Typography,
	Paper,
	IconButton,
	useTheme,
} from '@mui/material';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProductPriceChart from '../../../components/ProductPriceChart';
import { useSelector } from 'react-redux';
import { selectProducts } from '../store/productSlice';
import { IProduct } from '../../product/@types';

const DashboardContainer = styled(Box)(({ theme }) => ({
	minHeight: '100vh',
	padding: theme.spacing(3),
	backgroundColor: theme.palette.mode === 'dark' 
		? theme.palette.background.default 
		: theme.palette.grey[50],
}));

const StatsCard = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	borderRadius: theme.shape.borderRadius * 2,
	transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
	'&:hover': {
		transform: 'translateY(-4px)',
		boxShadow: theme.shadows[4],
	},
}));

const StatValue = styled(Typography)(({ theme }) => ({
	fontSize: '2rem',
	fontWeight: 'bold',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

interface PriceChangeProps {
	trend: 'up' | 'down';
}

const PriceChangeIndicator = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'trend'
})<PriceChangeProps>(({ theme, trend }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	color: trend === 'up' ? theme.palette.error.main : theme.palette.success.main,
	'& .MuiSvgIcon-root': {
		marginRight: theme.spacing(0.5),
	},
}));

export default function Dashboard() {
	const theme = useTheme();
	const products = useSelector(selectProducts).products;

	const stats = {
		totalProducts: products.length,
		activeAlerts: 12,
		priceDrops: 5,
		averageSavings: 150,
	};

	return (
		<DashboardContainer>
			<Container maxWidth="xl">
				<Typography variant="h4" fontWeight="bold" mb={4}>
					Dashboard
				</Typography>

				<Grid container spacing={3}>
					{/* Stats Cards */}
					<Grid item xs={12} sm={6} md={3}>
						<StatsCard elevation={0}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<Typography color="text.secondary">Total Products</Typography>
								<IconButton size="small" sx={{ backgroundColor: `${theme.palette.primary.main}15` }}>
									<ShoppingCartIcon color="primary" />
								</IconButton>
							</Box>
							<StatValue>{stats.totalProducts}</StatValue>
							<Typography variant="body2" color="text.secondary">
								Tracking {stats.totalProducts} products
							</Typography>
						</StatsCard>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<StatsCard elevation={0}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<Typography color="text.secondary">Active Alerts</Typography>
								<IconButton size="small" sx={{ backgroundColor: `${theme.palette.warning.main}15` }}>
									<NotificationsActiveIcon color="warning" />
								</IconButton>
							</Box>
							<StatValue>{stats.activeAlerts}</StatValue>
							<Typography variant="body2" color="text.secondary">
								Price alerts set
							</Typography>
						</StatsCard>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<StatsCard elevation={0}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<Typography color="text.secondary">Price Drops</Typography>
								<IconButton size="small" sx={{ backgroundColor: `${theme.palette.success.main}15` }}>
									<TrendingDownIcon color="success" />
								</IconButton>
							</Box>
							<StatValue>{stats.priceDrops}</StatValue>
							<Typography variant="body2" color="text.secondary">
								Products on sale
							</Typography>
						</StatsCard>
					</Grid>

					<Grid item xs={12} sm={6} md={3}>
						<StatsCard elevation={0}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
								<Typography color="text.secondary">Potential Savings</Typography>
								<IconButton size="small" sx={{ backgroundColor: `${theme.palette.error.main}15` }}>
									<TrendingUpIcon color="error" />
								</IconButton>
							</Box>
							<StatValue>${stats.averageSavings}</StatValue>
							<Typography variant="body2" color="text.secondary">
								Average savings found
							</Typography>
						</StatsCard>
					</Grid>

					{/* Price History Chart */}
					<Grid xs={12} md={8}>
						<Paper 
							elevation={0} 
							sx={{ 
								p: 3, 
								borderRadius: theme.shape.borderRadius * 2,
								height: '100%'
							}}
						>
							<Typography variant="h6" fontWeight="bold" mb={3}>
								Price History Overview
							</Typography>
							<ProductPriceChart 
								priceHistory={[
									{ date: '2024-01', price: 100, lowestPrice: 90 },
									{ date: '2024-02', price: 95, lowestPrice: 90 },
									{ date: '2024-03', price: 120, lowestPrice: 90 },
								]}
								currency="$"
							/>
						</Paper>
					</Grid>

					{/* Recent Activity */}
					<Grid xs={12} md={4}>
						<Paper 
							elevation={0} 
							sx={{ 
								p: 3, 
								borderRadius: theme.shape.borderRadius * 2,
								height: '100%'
							}}
						>
							<Typography variant="h6" fontWeight="bold" mb={3}>
								Recent Price Changes
							</Typography>
							{products.slice(0, 5).map((product, index) => (
								<Box
									key={index}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										mb: 2,
										p: 2,
										borderRadius: 1,
										bgcolor: theme.palette.background.default,
									}}
								>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
										<img 
											src={product.imgUrl} 
											alt={product.title}
											style={{ 
												width: 40, 
												height: 40, 
												borderRadius: theme.shape.borderRadius 
											}}
										/>
										<Box>
											<Typography variant="body2" noWrap sx={{ maxWidth: 200 }}>
												{product.title}
											</Typography>
											<PriceChangeIndicator trend={product.price > product.prevPrice ? 'up' : 'down'}>
												{product.price > product.prevPrice ? <TrendingUpIcon /> : <TrendingDownIcon />}
												{Math.abs(product.price - product.prevPrice).toFixed(2)}%
											</PriceChangeIndicator>
										</Box>
									</Box>
									<Typography variant="body2" fontWeight="bold">
										${product.price}
									</Typography>
								</Box>
							))}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</DashboardContainer>
	);
}
