import { type AxiosPromise } from "axios";
import axiosInstance from "./baseService";
import { ITag } from "../modules/tag/@types";

export const getAllTags = (): AxiosPromise<ITag[]> => {
	return axiosInstance.get(`/tags`);
};

export const createTag = (tag: ITag): AxiosPromise<ITag> => {
	return axiosInstance.post(`/tags`, tag);
};

export const updateTag = (tag: ITag): AxiosPromise<ITag> => {
	const { id, products, ...tagWithoutId } = tag;
	return axiosInstance.put(`/tags/${id}`, tagWithoutId);
};

export const deleteTag = (id: number): AxiosPromise<void> => {
	return axiosInstance.delete(`/tags/${id}`);
};
