import { type AxiosPromise } from "axios";
import { LoginCredentials } from "../modules/auth";
import axiosInstance from "./baseService";

export const login = (
	credentials: LoginCredentials,
): AxiosPromise<{ access_token: string }> => {
	return axiosInstance.post("/auth/login", credentials);
};

export const signup = (
	credentials: LoginCredentials,
): AxiosPromise<{ access_token: string }> => {
	return axiosInstance.post("/users/register", credentials);
};

export const getMe = (): AxiosPromise<{}> => {
	return axiosInstance.get("/auth/me");
};

export const sendVerificationCode = (email: string): AxiosPromise<{}> => {
	return axiosInstance.get(`/auth/verify-email/${email}`);
};

export const verifyAccount = (token: string): AxiosPromise<{}> => {
	return axiosInstance.get(`/auth/confirm-email/${token}`);
};
