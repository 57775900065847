"use client"

import { useState, useEffect } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  Snackbar,
  Alert,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Skeleton,
  useTheme,
  alpha
} from "@mui/material"
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

type Plan = {
  id: string
  name: string
  price: number
  interval: "month" | "year"
  description: string
  features: string[]
  popular?: boolean
  isCurrentPlan?: boolean
}

export default function SubscriptionPlans() {
  const theme = useTheme()
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null)
  const [billingInterval, setBillingInterval] = useState<"month" | "year">("month")
  const [isLoading, setIsLoading] = useState(false)
  const [plansLoading, setPlansLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error" | "info" | "warning">("success")
  
  const plans: Plan[] = [
    {
      id: `basic-${billingInterval}ly`,
      name: "Basic",
      price: billingInterval === "month" ? 9 : 90,
      interval: billingInterval,
      description: "Essential features for individuals",
      features: [
        "Up to 5 projects", 
        "Basic analytics", 
        "24-hour support response time", 
        "1GB storage", 
        "Community support",
        "Limited API access"
      ]
    },
    {
      id: `pro-${billingInterval}ly`,
      name: "Pro",
      price: billingInterval === "month" ? 19 : 190,
      interval: billingInterval,
      description: "Advanced features for professionals",
      features: [
        "Unlimited projects",
        "Advanced analytics",
        "4-hour support response time",
        "10GB storage",
        "Custom domains",
        "Full API access",
        "Dedicated support",
      ],
      popular: true,
      isCurrentPlan: true
    },
    {
      id: `enterprise-${billingInterval}ly`,
      name: "Enterprise",
      price: billingInterval === "month" ? 49 : 490,
      interval: billingInterval,
      description: "Complete solution for businesses",
      features: [
        "Unlimited projects",
        "Advanced analytics & reporting",
        "1-hour support response time",
        "100GB storage",
        "Custom domains",
        "SSO Authentication",
        "Dedicated account manager",
        "Premium support 24/7",
        "Custom integrations",
        "Security audits"
      ]
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      setPlansLoading(false)
      // Auto-select the current plan when data is loaded
      const currentPlan = plans.find(plan => plan.isCurrentPlan)
      if (currentPlan) {
        setSelectedPlan(currentPlan.id)
      }
    }, 1000)
  }, [])

  const handleSelectPlan = (planId: string) => {
    setSelectedPlan(planId)
  }

  const handleBillingIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newInterval = event.target.value as "month" | "year"
    setBillingInterval(newInterval)
    
    // Update selected plan ID to match new interval
    const currentPlanName = selectedPlan?.split('-')[0]
    setSelectedPlan(`${currentPlanName}-${newInterval}ly`)
  }

  const handleChangePlan = async () => {
    setIsLoading(true)

    // Simulate API call
    setTimeout(() => {
      setIsLoading(false)
      showNotification(`Your subscription has been updated to the ${plans.find((p) => p.id === selectedPlan)?.name} plan`)
    }, 1500)
  }

  // Toast replacement function
  const showNotification = (message: string, severity: "success" | "error" | "info" | "warning" = "success") => {
    setAlertMessage(message)
    setAlertSeverity(severity)
    setShowAlert(true)
  }

  // Handle closing the notification
  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const selectedPlanData = plans.find(p => p.id === selectedPlan)
  const savings = billingInterval === "year" ? 15 : 0

  // Render skeleton for a plan card
  const renderPlanCardSkeleton = () => (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        py: 3,
        px: 2.5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Skeleton variant="text" width={120} height={32} sx={{ mb: 1 }} />
      <Skeleton variant="text" width="80%" height={24} sx={{ mb: 2 }} />
      
      <Skeleton variant="text" width={100} height={40} sx={{ mb: 1 }} />
      <Skeleton variant="text" width={80} height={20} sx={{ mb: 3 }} />
      
      <Divider sx={{ my: 2 }} />
      
      <Box sx={{ mb: 2 }}>
        <Skeleton variant="text" width={100} height={24} sx={{ mb: 1 }} />
        {[1, 2, 3, 4].map((_, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
            <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1.5 }} />
            <Skeleton variant="text" width="70%" height={20} />
          </Box>
        ))}
      </Box>
      
      <Box sx={{ mt: 'auto', pt: 2 }}>
        <Skeleton variant="rectangular" width="100%" height={40} />
      </Box>
    </Box>
  )

  return (
    <Box>
      {/* Current Plan Summary */}
      <Box sx={{ mb: 5 }}>
        <Typography variant="h6" sx={{ mb: 0.5, fontWeight: 500 }}>
          Current Plan: Pro
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          ${billingInterval === "month" ? "19" : "190"} per {billingInterval} • Next billing date: April 15, 2025
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button 
            variant="outlined" 
            size="small"
            sx={{ 
              textTransform: 'none',
              fontWeight: 400,
              borderRadius: 0,
              py: 0.75
            }}
          >
            Manage Billing
          </Button>
          <Button 
            color="error" 
            variant="outlined" 
            size="small"
            sx={{ 
              textTransform: 'none',
              fontWeight: 400,
              borderRadius: 0,
              py: 0.75
            }}
          >
            Cancel Plan
          </Button>
        </Box>
      </Box>

      {/* Billing Toggle */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Billing Interval
        </Typography>
        <RadioGroup
          value={billingInterval}
          onChange={handleBillingIntervalChange}
          sx={{ flexDirection: 'row', gap: 3 }}
        >
          <FormControlLabel 
            value="month" 
            control={<Radio size="small" />} 
            label={
              <Typography variant="body2">Monthly</Typography>
            } 
          />
          <FormControlLabel 
            value="year" 
            control={<Radio size="small" />} 
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2">Annual</Typography>
                {savings > 0 && (
                  <Typography variant="caption" color="success.main" sx={{ fontWeight: 500 }}>
                    Save {savings}%
                  </Typography>
                )}
              </Box>
            } 
          />
        </RadioGroup>
      </Box>

      {/* Plan Selection */}
      <Grid container spacing={3} sx={{ mb: 5 }}>
        {plansLoading ? (
          // Skeleton loaders while plans are loading
          <>
            {[1, 2, 3].map((_, index) => (
              <Grid item xs={12} md={4} key={index}>
                {renderPlanCardSkeleton()}
              </Grid>
            ))}
          </>
        ) : (
          plans.map((plan) => (
            <Grid item xs={12} md={4} key={plan.id}>
              <Card 
                variant="outlined"
                sx={{ 
                  height: '100%',
                  borderColor: selectedPlan === plan.id ? 'primary.main' : 'divider',
                  borderWidth: 1,
                  borderRadius: 0,
                  cursor: 'pointer',
                  transition: 'border-color 0.2s ease',
                  position: 'relative',
                  '&:hover': {
                    borderColor: selectedPlan === plan.id ? 'primary.main' : 'primary.light',
                  }
                }}
                onClick={() => handleSelectPlan(plan.id)}
              >
                {plan.popular && (
                  <Chip 
                    label="Popular" 
                    size="small" 
                    color="primary"
                    sx={{
                      position: 'absolute',
                      top: 12,
                      right: 12,
                      height: 22,
                      fontSize: '0.75rem',
                      fontWeight: 400,
                      borderRadius: 0,
                      px: 0.75
                    }}
                  />
                )}
                <CardContent sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6" sx={{ mb: 0.5, fontWeight: 500 }}>
                    {plan.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {plan.description}
                  </Typography>
                  
                  <Typography 
                    variant="h5" 
                    component="div" 
                    sx={{ 
                      fontWeight: 500, 
                      mb: 2,
                      mt: 0.5
                    }}
                  >
                    ${plan.price}
                    <Typography 
                      component="span" 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ ml: 0.5 }}
                    >
                      /{plan.interval}
                    </Typography>
                  </Typography>
                  
                  <Divider sx={{ mb: 2 }} />
                  
                  <List disablePadding sx={{ mb: 2, flexGrow: 1 }}>
                    {plan.features.map((feature, i) => (
                      <ListItem key={i} disablePadding disableGutters sx={{ mb: 1 }}>
                        <ListItemIcon sx={{ minWidth: 28 }}>
                          <CheckIcon 
                            fontSize="small" 
                            color="primary"
                            sx={{ fontSize: '0.9rem' }}
                          />
                        </ListItemIcon>
                        <ListItemText 
                          primary={feature} 
                          primaryTypographyProps={{ 
                            variant: 'body2', 
                            color: 'text.secondary'
                          }} 
                        />
                      </ListItem>
                    ))}
                  </List>
                  
                  <Box sx={{ mt: 'auto' }}>
                    <Radio 
                      checked={selectedPlan === plan.id}
                      onChange={() => handleSelectPlan(plan.id)}
                      sx={{ p: 0, mr: 1 }}
                    />
                    <Typography 
                      variant="body2" 
                      component="span" 
                      sx={{ 
                        fontWeight: selectedPlan === plan.id ? 500 : 400,
                        color: selectedPlan === plan.id ? 'primary.main' : 'text.primary'
                      }}
                    >
                      {selectedPlan === plan.id ? "Selected" : "Select this plan"}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Action Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          variant="contained" 
          onClick={handleChangePlan} 
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={18} /> : <ArrowForwardIcon />}
          sx={{ 
            textTransform: 'none',
            fontWeight: 400,
            borderRadius: 0,
            px: 3,
            py: 1,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          {isLoading ? "Updating..." : `Switch to ${selectedPlanData?.name}`}
        </Button>
      </Box>

      <Snackbar 
        open={showAlert} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseAlert} 
          severity={alertSeverity} 
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: 0
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

