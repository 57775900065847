import { lazy } from "react";

const Routes = {
	dashboard: "/",
	login: "/login",
	confirmEmail: "/confirm-email/:email",
	verifyEmail: "/verify-email/:token",
	signUp: "/sign-up",
	products: "/products",
	productById: "/products/:id",
	profile: "/profile",
	tags: "/tags",
	notifications: "/notifications",
	shops: "/shops",
};

export default Routes;
