import { INotification } from "../../notification/@types";
import { ITag } from "../../tag/@types";

interface IProductPriceHistory {
	date: Date;
	price: number;
}

export enum CheckInterval {
	EVERY_5_MINUTES = "EVERY_5_MINUTES",
	EVERY_10_MINUTES = "EVERY_10_MINUTES",
	EVERY_30_MINUTES = "EVERY_30_MINUTES",
	EVERY_HOUR = "EVERY_HOUR",
	EVERY_3_HOURS = "EVERY_3_HOURS",
	EVERY_6_HOURS = "EVERY_6_HOURS",
	EVERY_9_HOURS = "EVERY_9_HOURS",
	EVERY_12_HOURS = "EVERY_12_HOURS",
	EVERY_DAY = "EVERY_DAY",
}

export enum Condition {
	PRICE_CHANGE = "PRICE_CHANGE", // Notify on any price change
	PRICE_DROP = "PRICE_DROP", // Notify only when the price decreases
	PRICE_INCREASE = "PRICE_INCREASE", // Notify only when the price increases
	BELOW_THRESHOLD = "BELOW_THRESHOLD", // Notify when the price drops below a specific value
	ABOVE_THRESHOLD = "ABOVE_THRESHOLD", // Notify when the price exceeds a specific value
}

export enum NotificationType {
	EMAIL = "EMAIL",
	SMS = "SMS",
	NOTIFICATION = "NOTIFICATION",
	TELEGRAM = "TELEGRAM",
	WHATSAPP = "WHATSAPP",
	PUSH = "PUSH",
}

export interface IProduct {
	notifications: INotification[];
	tags: ITag[];
	id: number;
	url: string;
	selector: string;
	currency: string;
	priceHistory: IProductPriceHistory[];
	descriptions: string[];
	createdAt: Date;
	updatedAt: Date;
	title: string;
	price: number;
	prevPrice: number;
	imgUrl: string;
	favicon: string;
}

export interface IProductInformationByUrl {
	extractedPrice: number;
	extractedCurrency: string;
	title: string;
	descriptions: string[];
	faviconUrl: string;
	time: string;
	imgUrl: string;
	selector: string;
	imgSelector: string;
}
