"use client"

import React, { useState, useEffect } from "react"
import {
  Tabs,
  Tab,
  Box,
  Paper,
  Typography,
  Container,
  Divider,
  Skeleton,
  Grid
} from "@mui/material"
import AccountSettings from './AccountSettings'
import SubscriptionPlans from './SubscriptionPlans'
import PaymentMethods from './PaymentMethods'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  }
}

export default function ProfileTabs() {
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  // Simulate loading when switching tabs
  useEffect(() => {
    setIsLoading(true)
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    
    return () => clearTimeout(timer)
  }, [value])

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // Render skeleton loaders for different tabs
  const renderTabSkeleton = (tabIndex: number) => {
    switch (tabIndex) {
      case 0: // Account Settings
        return (
          <Box>
            <Box sx={{ mb: 4 }}>
              <Skeleton variant="text" width={180} height={32} sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Skeleton variant="circular" width={80} height={80} sx={{ mr: 2 }} />
                <Box>
                  <Skeleton variant="text" width={100} height={32} />
                  <Skeleton variant="text" width={80} height={24} />
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2.5}>
                  {[1, 2, 3].map((_, i) => (
                    <Grid item xs={12} md={6} key={i}>
                      <Skeleton variant="rectangular" height={40} width="100%" />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Skeleton variant="rectangular" height={40} width={150} />
            </Box>
            
            <Divider sx={{ my: 4 }} />
            
            <Box>
              <Skeleton variant="text" width={120} height={32} sx={{ mb: 2 }} />
              <Box sx={{ mb: 3 }}>
                <Grid container spacing={2.5}>
                  {[1, 2, 3].map((_, i) => (
                    <Grid item xs={12} md={6} key={i}>
                      <Skeleton variant="rectangular" height={40} width="100%" />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Skeleton variant="rectangular" height={40} width={170} />
            </Box>
          </Box>
        )
      
      case 1: // Subscription Plans
        return (
          <Box>
            <Skeleton variant="text" width={180} height={32} sx={{ mb: 1 }} />
            <Skeleton variant="text" width={300} height={24} sx={{ mb: 4 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Skeleton variant="rectangular" width={300} height={40} />
            </Box>
            
            <Grid container spacing={2.5}>
              {[1, 2, 3].map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      p: 3,
                      height: '100%',
                    }}
                  >
                    <Skeleton variant="text" width={120} height={32} sx={{ mb: 1 }} />
                    <Skeleton variant="text" width="80%" height={24} sx={{ mb: 2 }} />
                    
                    <Skeleton variant="text" width={100} height={40} sx={{ mb: 1 }} />
                    <Skeleton variant="text" width={80} height={20} sx={{ mb: 3 }} />
                    
                    <Divider sx={{ my: 2 }} />
                    
                    <Box sx={{ mb: 2 }}>
                      <Skeleton variant="text" width={100} height={24} sx={{ mb: 1 }} />
                      {[1, 2, 3, 4].map((_, i) => (
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                          <Skeleton variant="circular" width={20} height={20} sx={{ mr: 1.5 }} />
                          <Skeleton variant="text" width="70%" height={20} />
                        </Box>
                      ))}
                    </Box>
                    
                    <Box sx={{ mt: 'auto', pt: 2 }}>
                      <Skeleton variant="rectangular" width="100%" height={40} />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )
      
      case 2: // Payment Methods
        return (
          <Box>
            <Skeleton variant="text" width={180} height={32} sx={{ mb: 1 }} />
            <Skeleton variant="text" width={280} height={24} sx={{ mb: 4 }} />
            
            <Box sx={{ mb: 4 }}>
              {[1, 2].map((_, index) => (
                <Box key={index}>
                  {index > 0 && <Divider />}
                  <Box sx={{ py: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Skeleton variant="text" width={150} height={24} sx={{ mr: 3 }} />
                      <Skeleton variant="text" width={120} height={24} />
                    </Box>
                    <Box>
                      <Skeleton variant="rectangular" width={100} height={30} />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            
            <Skeleton variant="rectangular" width={180} height={36} />
          </Box>
        )
        
      default:
        return null
    }
  }

  return (
    <Container maxWidth="lg" sx={{ py: 1 }}>  
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs 
          value={value} 
          onChange={handleChange}
          aria-label="profile tabs"
          sx={{
            '& .MuiTabs-indicator': {
              height: 2,
            },
            '& .MuiTab-root': {
              textTransform: 'none',
              fontWeight: 400,
              fontSize: '0.9rem',
              minHeight: 48,
              px: 2,
              mr: 3,
              minWidth: 'unset',
              opacity: 0.7,
              transition: 'opacity 0.2s ease',
            },
            '& .Mui-selected': {
              fontWeight: 500,
              opacity: 1,
            }
          }}
        >
          <Tab 
            label="Account Settings" 
            {...a11yProps(0)} 
            disableRipple
          />
          <Tab 
            label="Subscription Plans" 
            {...a11yProps(1)} 
            disableRipple
          />
          <Tab 
            label="Payment Methods" 
            {...a11yProps(2)} 
            disableRipple
          />
        </Tabs>
      </Box>
      
      <TabPanel value={value} index={0}>
        {isLoading ? renderTabSkeleton(0) : <AccountSettings />}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {isLoading ? renderTabSkeleton(1) : <SubscriptionPlans />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {isLoading ? renderTabSkeleton(2) : <PaymentMethods />}
      </TabPanel>
    </Container>
  )
}

