import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import { Search, Compare, Notifications } from '@mui/icons-material';

const steps = [
  {
    icon: <Search />,
    title: 'Search Products',
    description: 'Find the products you want to compare across multiple platforms'
  },
  {
    icon: <Compare />,
    title: 'Compare Details',
    description: 'View detailed comparisons of prices, features, and specifications'
  },
  {
    icon: <Notifications />,
    title: 'Get Notified',
    description: 'Receive alerts when prices drop or products become available'
  }
];

export default function HowItWorksSection() {
  return (
    <Box sx={{ py: 10 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h2" 
          textAlign="center" 
          sx={{ mb: 6, fontWeight: 700 }}
        >
          How It Works
        </Typography>
        <Grid container spacing={4}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Paper
                sx={{
                  p: 4,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    borderRadius: '50%',
                    bgcolor: 'primary.light',
                    color: 'white',
                    mb: 2
                  }}
                >
                  {step.icon}
                </Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  {step.title}
                </Typography>
                <Typography color="text.secondary">
                  {step.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
} 