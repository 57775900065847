import type {} from "@mui/material/themeCssVarsAugmentation";
import { ThemeOptions, Theme, createTheme } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-charts/themeAugmentation";
import { getDesignTokens } from "./themePrimitives";
import {
	getChartsCustomization,
	dataGridCustomizations,
	datePickersCustomizations,
	inputsCustomizations,
	dataDisplayCustomizations,
	feedbackCustomizations,
	navigationCustomizations,
	surfacesCustomizations,
} from "./customizations";
import { Mode } from '../types/theme';

export const getTheme = (mode: Mode): Theme => {
	return createTheme({
		palette: {
			mode,
			// ... rest of your theme configuration
		},
	});
};

export default function getCustomTheme(mode: Mode): ThemeOptions {
	return {
		...getDesignTokens(mode),
		components: {
			...dataGridCustomizations,
			...datePickersCustomizations,
			// ...inputsCustomizations,
			...dataDisplayCustomizations,
			...feedbackCustomizations,
			...navigationCustomizations,
			...surfacesCustomizations,
		},
	};
}
