import React from 'react';
import { Box, Container, Typography, Button, Stack } from '@mui/material';

export default function CTASection() {
  return (
    <Box sx={{ py: 10, bgcolor: 'primary.main', color: 'white' }}>
      <Container maxWidth="lg">
        <Stack spacing={4} alignItems="center" textAlign="center">
          <Typography variant="h3" sx={{ fontWeight: 700 }}>
            Ready to Start Comparing?
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.9, maxWidth: 600 }}>
            Join thousands of users making smarter purchasing decisions
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              bgcolor: 'white',
              color: 'primary.main',
              '&:hover': { bgcolor: 'grey.100' }
            }}
          >
            Get Started Now
          </Button>
        </Stack>
      </Container>
    </Box>
  );
} 