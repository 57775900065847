import React from 'react';
import { Box, Container, Grid, Typography, Paper, Stack } from '@mui/material';
import { CompareArrows, Speed, Security, Analytics, Devices, Cloud, Extension, LocalOffer as TagIcon } from '@mui/icons-material';
import { keyframes } from '@mui/material/styles';

// Import all SVGs
import { ReactComponent as ComparisonIcon } from '../../../assets/images/features/comparison.svg';
import { ReactComponent as RealtimeIcon } from '../../../assets/images/features/realtime.svg';
import { ReactComponent as SecurityIcon } from '../../../assets/images/features/security.svg';
import { ReactComponent as AnalyticsIcon } from '../../../assets/images/features/analytics.svg';
import { ReactComponent as DevicesIcon } from '../../../assets/images/features/devices.svg';
import { ReactComponent as CloudIcon } from '../../../assets/images/features/cloud.svg';
import { ReactComponent as ExtensionIcon } from '../../../assets/images/features/extension.svg';
import { ReactComponent as TagsIcon } from '../../../assets/images/features/tags.svg';
import { ReactComponent as PriceChartIcon } from '../../../assets/images/features/price-chart.svg';

// Define the type for our feature
interface Feature {
  icon: React.ReactNode;
  title: string;
  description: string;
  Image: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const features: Feature[] = [
  {
    icon: <CompareArrows />,
    title: 'Price History',
    description: 'Track price changes over time and get insights into the best time to buy',
    Image: PriceChartIcon
  },
  {
    icon: <CompareArrows />,
    title: 'Smart Comparison',
    description: 'Compare products across multiple sources with our advanced algorithms and AI-powered analysis',
    Image: ComparisonIcon
  },
  {
    icon: <Speed />,
    title: 'Real-time Updates',
    description: 'Get instant notifications about price changes, availability, and special deals',
    Image: RealtimeIcon
  },
  {
    icon: <Security />,
    title: 'Secure Analysis',
    description: 'Your data is protected with enterprise-grade security and encryption',
    Image: SecurityIcon
  },
  {
    icon: <Analytics />,
    title: 'Deep Analytics',
    description: 'Gain insights from comprehensive market trends and price history analysis',
    Image: AnalyticsIcon
  },
  {
    icon: <Devices />,
    title: 'Cross-Platform',
    description: 'Access your comparisons from any device with our responsive platform',
    Image: DevicesIcon
  },
  {
    icon: <Cloud />,
    title: 'Cloud Sync',
    description: 'Your data is automatically synced across all your devices',
    Image: CloudIcon
  },
  {
    icon: <Extension />,
    title: 'Browser Extension',
    description: 'Save products from any website with our browser extension. Track prices and compare instantly.',
    Image: ExtensionIcon
  },
  {
    icon: <TagIcon />,
    title: 'Smart Tagging',
    description: 'Organize products with custom tags for better organization and group comparisons',
    Image: TagsIcon
  }
];

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function FeaturesSection() {
  return (
    <Box sx={{ py: 15, bgcolor: 'grey.50' }}>
      <Container maxWidth="lg">
        <Stack spacing={3} alignItems="center" mb={8}>
          <Typography variant="h2" textAlign="center" sx={{ fontWeight: 700, fontSize: { xs: '2rem', md: '3rem' } }}>
            Powerful Features
          </Typography>
          <Typography color="text.secondary" textAlign="center" sx={{ maxWidth: 600 }}>
            Everything you need to make informed purchasing decisions and save money
          </Typography>
        </Stack>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Paper sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                opacity: 0,
                animation: `${fadeInUp} 0.6s ease-out forwards`,
                animationDelay: `${index * 0.1}s`,
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: (theme) => theme.shadows[4]
                }
              }}>
                <Box 
                  sx={{ 
                    width: '100%', 
                    height: 200, 
                    mb: 3, 
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(96, 165, 250, 0.1)',
                    overflow: 'hidden',
                    '& svg': {
                      width: '90%',
                      height: 'auto',
                      maxHeight: '160px'
                    }
                  }}
                >
                  <Box
                    component={feature.Image}
                    sx={{
                      width: '80%',
                      height: '80%',
                      objectFit: 'contain',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)'
                      }
                    }}
                  />
                </Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" sx={{ lineHeight: 1.7 }}>
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}