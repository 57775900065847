import React from 'react';
import ProfileTabs from "../components/ProfileTabs"


export default function ProfilePage() {

  return (
    <div className="container py-10">
      <div className="mx-auto max-w-5xl space-y-8">
        <div>
          <h1 className="text-3xl font-bold">Profile</h1>
          <p className="text-muted-foreground">Manage your account settings and preferences</p>
        </div>
        <ProfileTabs />
      </div>
    </div>
  )
}

