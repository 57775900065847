"use client"

import { useState, ChangeEvent } from "react"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Stack,
  Tooltip,
  Skeleton,
  useTheme,
  alpha
} from "@mui/material"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

export default function AccountSettings() {
  const theme = useTheme()
  
  // Profile data state
  const [fullName, setFullName] = useState("Jane Doe")
  const [email, setEmail] = useState("jane.doe@example.com")
  const [phone, setPhone] = useState("+1 (555) 123-4567")
  
  // Password state
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  
  // UI state
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error" | "info" | "warning">("success")
  
  // Profile image loading state
  const [imageLoading, setImageLoading] = useState(true)
  const [imageError, setImageError] = useState(false)
  const [profileImage, setProfileImage] = useState("https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=256&q=80")

  // Handle form submission for profile info
  const handleSaveProfile = (event: React.FormEvent) => {
    event.preventDefault()
    setIsLoading(true)
    
    // Simulate API call
    setTimeout(() => {
      setIsLoading(false)
      setSnackbarMessage("Profile updated successfully")
      setSnackbarSeverity("success")
      setShowSnackbar(true)
    }, 800)
  }
  
  // Handle password update
  const handleUpdatePassword = (event: React.FormEvent) => {
    event.preventDefault()
    
    if (newPassword !== confirmPassword) {
      setSnackbarMessage("Passwords do not match")
      setSnackbarSeverity("error")
      setShowSnackbar(true)
      return
    }
    
    setIsLoading(true)
    
    // Simulate API call
    setTimeout(() => {
      setIsLoading(false)
      setCurrentPassword("")
      setNewPassword("")
      setConfirmPassword("")
      setSnackbarMessage("Password updated successfully")
      setSnackbarSeverity("success")
      setShowSnackbar(true)
    }, 800)
  }
  
  // Handle image loading
  const handleImageLoad = () => {
    setImageLoading(false)
    setImageError(false)
  }
  
  // Handle image error
  const handleImageError = () => {
    setImageLoading(false)
    setImageError(true)
  }
  
  // Close snackbar
  const handleCloseSnackbar = () => {
    setShowSnackbar(false)
  }
  
  // Display user initials (for avatar fallback)
  const getInitials = (name: string) => {
    return name
      .split(" ")
      .map(part => part[0])
      .join("")
      .toUpperCase()
      .slice(0, 2)
  }

  return (
    <Box>
      {/* Profile Information */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
          Account Settings
        </Typography>
        
        <form onSubmit={handleSaveProfile}>
          <Box mb={3} display="flex" flexDirection="column" alignItems="flex-start">
            {/* Profile Picture with Skeleton */}
            <Box position="relative" mb={1.5}>
              {imageLoading && (
                <Skeleton 
                  variant="circular" 
                  width={80} 
                  height={80} 
                  animation="wave" 
                />
              )}
              
              {imageError ? (
                <Box
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 'medium'
                  }}
                >
                  {getInitials(fullName)}
                </Box>
              ) : (
                <Box 
                  component="img" 
                  src={profileImage}
                  alt="Profile"
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  sx={{ 
                    width: 80, 
                    height: 80, 
                    objectFit: 'cover',
                    borderRadius: '50%',
                    display: imageLoading ? 'none' : 'block'
                  }}
                />
              )}
            </Box>
            
            <Stack direction="row" spacing={1}>
              <Button
                size="small"
                variant="outlined"
                sx={{ 
                  fontSize: '0.75rem', 
                  textTransform: 'none',
                  borderRadius: 0,
                  fontWeight: 400,
                  py: 0.5
                }}
              >
                Change
              </Button>
              <Button
                size="small"
                color="error"
                sx={{ 
                  fontSize: '0.75rem', 
                  textTransform: 'none',
                  py: 0.5,
                  fontWeight: 400
                }}
              >
                Remove
              </Button>
            </Stack>
          </Box>
          
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Full Name"
                fullWidth
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                size="small"
                InputProps={{
                  sx: {
                    borderRadius: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                fullWidth
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="small"
                InputProps={{
                  sx: {
                    borderRadius: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone Number"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                size="small"
                InputProps={{
                  sx: {
                    borderRadius: 0
                  }
                }}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{
                textTransform: "none",
                fontWeight: 400,
                borderRadius: 0,
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none"
                }
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
      
      <Divider sx={{ my: 4 }} />
      
      {/* Security Settings */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
          Security
        </Typography>
        
        <form onSubmit={handleUpdatePassword}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Current Password"
                fullWidth
                required
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        edge="end"
                        size="small"
                      >
                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: 0
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  label="New Password"
                  fullWidth
                  required
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Stack direction="row" spacing={0.5}>
                          <Tooltip title="Password must be at least 8 characters long, include a capital letter, a number, and a special character">
                            <IconButton
                              aria-label="password requirements"
                              edge="end"
                              size="small"
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge="end"
                            size="small"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Stack>
                      </InputAdornment>
                    ),
                    sx: {
                      borderRadius: 0
                    }
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Confirm New Password"
                fullWidth
                required
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                        size="small"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: 0
                  }
                }}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              sx={{
                textTransform: "none",
                fontWeight: 400,
                borderRadius: 0,
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none"
                }
              }}
            >
              Update Password
            </Button>
          </Box>
        </form>
      </Box>
      
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity} 
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: 0
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

