import * as React from "react";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import { 
	Box, 
	Drawer, 
	List, 
	ListItem, 
	ListItemIcon, 
	ListItemText, 
	Typography,
	useTheme,
	alpha,
	CssBaseline,
	Stack
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StorefrontIcon from '@mui/icons-material/Storefront';

import Routes from "../configs/routes";
import { selectAuth, logout } from "modules/auth/store/authSlice";
import { getTagsListAction, selectTagsList } from "modules/tag/store/tagsSlice";

const SideMenu = styled(Drawer)(({ theme }) => ({
	width: 280,
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		width: 280,
		boxSizing: 'border-box',
		background: theme.palette.mode === 'dark' 
			? `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.9)}, ${alpha(theme.palette.background.default, 0.2)})`
			: `linear-gradient(to bottom, ${alpha(theme.palette.primary.light, 0.05)}, ${theme.palette.background.paper})`,
		borderRight: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
		transition: theme.transitions.create(['box-shadow', 'background'], {
			duration: theme.transitions.duration.standard,
		}),
		'&:hover': {
			boxShadow: `0 0 40px ${alpha(theme.palette.primary.main, 0.08)}`,
		}
	},
}));

const ProfileSection = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3, 2),
	marginBottom: theme.spacing(1),
	position: 'relative',
	'&::after': {
		content: '""',
		position: 'absolute',
		bottom: 0,
		left: theme.spacing(3),
		right: theme.spacing(3),
		height: 1,
		background: `linear-gradient(90deg, 
			transparent 0%, 
			${alpha(theme.palette.divider, 0.12)} 50%,
			transparent 100%
		)`,
	}
}));

const ProfileCard = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(2),
	padding: theme.spacing(1.5, 2),
	borderRadius: theme.shape.borderRadius * 2,
	transition: theme.transitions.create(['background', 'transform'], {
		duration: theme.transitions.duration.shorter,
	}),
	cursor: 'pointer',
	'&:hover': {
		background: alpha(theme.palette.primary.main, 0.04),
		transform: 'translateX(4px)',
		'& .arrow-icon': {
			transform: 'translateX(4px)',
			opacity: 1,
		}
	}
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.success.main,
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': { transform: 'scale(.8)', opacity: 1 },
		'100%': { transform: 'scale(2.4)', opacity: 0 },
	},
}));

const MenuItem = styled(ListItemButton)(({ theme }) => ({
	margin: theme.spacing(0.5, 2),
	padding: theme.spacing(1.2, 2),
	borderRadius: theme.shape.borderRadius * 1.5,
	transition: theme.transitions.create(['background', 'transform', 'color'], {
		duration: theme.transitions.duration.shorter,
	}),
	'&.Mui-selected': {
		backgroundColor: alpha(theme.palette.primary.main, 0.08),
		color: theme.palette.primary.main,
		transform: 'scale(1.01)',
		'& .MuiListItemIcon-root': {
			color: theme.palette.primary.main,
		}
	},
	'&:hover': {
		backgroundColor: alpha(theme.palette.primary.main, 0.04),
		transform: 'translateX(4px)',
	}
}));

const menuItems = [
	{ icon: <DashboardIcon />, text: 'Dashboard', path: '/dashboard' },
	{ icon: <ShoppingBagIcon />, text: 'Products', path: '/products' },
	{ 
		icon: <LocalOfferIcon />, 
		text: 'Tags', 
		path: '/tags',
		badge: 12
	},
	{
		text: "Shops",
		icon: <StorefrontIcon />,
		path: Routes.shops,
		badge: "100+"
	},
	{ icon: <SettingsIcon />, text: 'Settings', path: '/settings' },

];

function AuthLayout() {
	const { isAuthenticated, init } = useSelector(selectAuth);
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const tags = useSelector(selectTagsList);
	const dispatch = useDispatch();

	const shouldRedirect = !isAuthenticated && init;

	useEffect(() => {
		if (!tags.length && !shouldRedirect) {
		  dispatch(getTagsListAction());
		}
	  }, [dispatch, tags.length, shouldRedirect]);
	
	const handleLogout = () => {
		dispatch(logout());
		navigate(Routes.login);
	};

	if (shouldRedirect) {
		return <Navigate to={Routes.login} />;
	}

	return (
		<>
			<CssBaseline enableColorScheme />
			<Box sx={{ display: "flex" }}>
				<SideMenu variant="permanent">
					<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ p: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ type: 'spring', stiffness: 260, damping: 20 }}
							>
								<img src="/logo.svg" alt="Logo" style={{ width: 40, height: 40 }} />
							</motion.div>
							<Typography variant="h5" fontWeight="700" sx={{ 
								background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
								WebkitBackgroundClip: 'text',
								WebkitTextFillColor: 'transparent'
							}}>
								Price Tracker
							</Typography>
						</Box>

						<ProfileSection>
							<ProfileCard onClick={() => navigate('/profile')}>
								<StyledBadge
									overlap="circular"
									anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
									variant="dot"
								>
									<Avatar
										sx={{ 
											width: 48, 
											height: 48,
											border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
											background: theme.palette.primary.main,
											transition: theme.transitions.create(['border', 'box-shadow']),
											'&:hover': {
												borderColor: theme.palette.primary.main,
												boxShadow: `0 0 16px ${alpha(theme.palette.primary.main, 0.2)}`
											}
										}}
									>
										<PersonIcon />
									</Avatar>
								</StyledBadge>
								<Box sx={{ flex: 1, minWidth: 0 }}>
									<Typography variant="subtitle1" fontWeight="600" noWrap>
										John Doe
									</Typography>
									<Typography variant="caption" color="text.secondary" noWrap>
										john.doe@example.com
									</Typography>
								</Box>
								<KeyboardArrowRightIcon 
									className="arrow-icon"
									sx={{ 
										opacity: 0,
										color: 'primary.main',
										transition: theme.transitions.create(['transform', 'opacity'])
									}} 
								/>
							</ProfileCard>
						</ProfileSection>

						<List sx={{ flex: 1 }}>
							{menuItems.map((item, index) => (
								<motion.div
									key={item.path}
									initial={{ x: -20, opacity: 0 }}
									animate={{ x: 0, opacity: 1 }}
									transition={{ delay: index * 0.1 }}
								>
									<MenuItem
										selected={location.pathname.startsWith(item.path)}
										onClick={() => navigate(item.path)}
									>
										<ListItemIcon sx={{ minWidth: 40 }}>
											{item.icon}
										</ListItemIcon>
										<ListItemText 
											primary={item.text}
											primaryTypographyProps={{
												fontSize: '0.95rem',
												fontWeight: location.pathname.startsWith(item.path) ? 600 : 500
											}}
										/>
										{item.badge && (
											<Box
												sx={{
													minWidth: 20,
													height: 20,
													borderRadius: 10,
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													bgcolor: alpha(theme.palette.primary.main, 0.1),
													color: theme.palette.primary.main,
													fontSize: '0.75rem',
													fontWeight: 600,
													ml: 1
												}}
											>
												{item.badge}
											</Box>
										)}
									</MenuItem>
								</motion.div>
							))}
						</List>

						<Divider sx={{ borderColor: alpha(theme.palette.divider, 0.1) }} />

						<Box sx={{ p: 2 }}>
							<MenuItem
								onClick={handleLogout}
								sx={{ 
									color: theme.palette.error.main,
									'&:hover': {
										bgcolor: alpha(theme.palette.error.main, 0.05),
									}
								}}
							>
								<ListItemIcon sx={{ minWidth: 40, color: 'inherit' }}>
									<LogoutIcon />
								</ListItemIcon>
								<ListItemText 
									primary="Logout"
									primaryTypographyProps={{
										fontSize: '0.95rem',
										fontWeight: 500
									}}
								/>
							</MenuItem>
						</Box>
					</Box>
				</SideMenu>
				<Box
					component="main"
					sx={(theme) => ({
						flexGrow: 1,
						backgroundColor: alpha(theme.palette.background.default, 1),
						overflow: "auto",
						minHeight: '100vh',
						padding: theme.spacing(3),
					})}
				>
					<Stack
						spacing={2}
						sx={{
							alignItems: "center",
							mx: 3,
							pb: 10,
							mt: { xs: 8, md: 0 },
						}}
					>
						<Box sx={{ width: '100%' }}>
							<Outlet />
						</Box>
					</Stack>
				</Box>
			</Box>
		</>
	);
}

export default AuthLayout;
