import axios from "axios";
import { getToken } from "./localStorageService";
import { API_URL } from "../configs/constants";

const axiosInstance = axios.create({
	baseURL: `${API_URL}`,
});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = getToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export default axiosInstance;
