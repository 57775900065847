import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import {IDomain} from "../@types";

interface DomainsState {
	loading: boolean;
	error: string | null;
	domains: IDomain[];
}

const initialState: DomainsState = {
	loading: false,
	error: null,
	domains: [],
};

const knownDomainsSlice = createSlice({
	name: "domains",
	initialState,
	reducers: {
		getDomainsListSuccess: (state, action: PayloadAction<IDomain[]>) => {
			state.loading = false;
			state.domains = action.payload;
		},
		getDomainsListFailed: (state) => {
			state.loading = false;
		},
		getDomainsListAction: (state) => {
			state.loading = true;
		},
	},
});

export const { getDomainsListFailed, getDomainsListAction, getDomainsListSuccess } =
	knownDomainsSlice.actions;

export default knownDomainsSlice.reducer;

// Selectors
export const selectDomains = (state: RootState) => state.domains;

