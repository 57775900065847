import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, Stack, Autocomplete, TextField, Box, Typography,
  Chip, alpha
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import { Label } from "@mui/icons-material";
import { useDispatch, useSelector } from 'react-redux';
import { addTagToProduct } from 'services/productService';
import { selectTags, getTagsListAction } from 'modules/tag';
import { ITag } from 'modules/tag/@types';

interface TagSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  productId: string;
  onSuccess: () => void;
  existingTags: ITag[];
}

export default function TagSelectionDialog({ open, onClose, productId, onSuccess, existingTags }: TagSelectionDialogProps) {
  const dispatch = useDispatch();
  const {tags} = useSelector(selectTags);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open && !tags.length) {
      dispatch(getTagsListAction());
    }
  }, [open, dispatch]);

  const handleSubmit = async (tagNames: string[]) => {
    setLoading(true);
    try {
      await addTagToProduct(productId, tagNames);
      onSuccess();
      onClose();
    } catch (error) {
      console.error('Failed to add tags:', error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Tags</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Autocomplete
            multiple
            value={selectedTags}
            onChange={(_, newValue) => setSelectedTags(newValue)}
            options={tags.filter(tag => !existingTags.some(t => t.name === tag.name))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select tags"
                placeholder="Search tags..."
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Stack direction="row" spacing={1} alignItems="center">
                  {React.createElement(Icons[option.icon as keyof typeof Icons] || Label, { 
                    sx: { 
                      color: option.color,
                      fontSize: 16
                    }
                  })}
                  <Typography>{option.name}</Typography>
                </Stack>
              </Box>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.id}
                  label={option.name}
                  icon={React.createElement(Icons[option.icon as keyof typeof Icons] || Label)}
                  sx={{
                    bgcolor: alpha(option.color, 0.1),
                    color: option.color ?? "#fff",
                    '& .MuiChip-icon': {
                      color: 'inherit'
                    }
                  }}
                />
              ))
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => {
            const tagNames = selectedTags.map(tag => tag.name);
            handleSubmit(tagNames);
          }}
          variant="contained"
          disabled={!selectedTags.length || loading}
        >
          Add Tags
        </Button>
      </DialogActions>
    </Dialog>
  );
} 