import { type AxiosPromise } from "axios";
import axiosInstance from "./baseService";
import { IProduct, IProductInformationByUrl } from "../modules/product/@types";
import axios from 'axios';

export const getProductList = (tags: string[]): AxiosPromise<IProduct[]> => {
	return axiosInstance.post("/products/list", {
		tags,
	});
};

export const getProductById = (id: number): AxiosPromise<IProduct> => {
	return axiosInstance.get(`/products/${id}`);
};

export const removeProductById = (id: number): AxiosPromise<IProduct[]> => {
	return axiosInstance.delete(`/products/${id}`);
};

export const addTagToProduct = (
	id: string,
	tags: string[],
): AxiosPromise<IProduct[]> => {
	return axiosInstance.post(`/products/${id}/tags`, { tags });
};

export const getProductInfoByUrl = (
	url: string,
): AxiosPromise<IProductInformationByUrl> => {
	return axiosInstance.get(`/products/by-url?url=${url}`);
};

export const saveProduct = (
	url: string,
	data: IProductInformationByUrl,
): AxiosPromise<IProduct> => {
	return axiosInstance.post(
		`/products`,
		{
			url,
			selector: data.selector,
			imgSelector: data.imgSelector,
		},
		{ timeout: 600000 },
	);
};
