import * as React from "react";
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SitemarkIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
	</SvgIcon>
);

export const GoogleIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path d="M21.35 11.1h-9.17v2.73h6.51c-.33 3.81-3.5 5.44-6.5 5.44C8.36 19.27 5 16.25 5 12c0-4.1 3.2-7.27 7.2-7.27 3.09 0 4.9 1.97 4.9 1.97L19 4.72S16.56 2 12.1 2C6.42 2 2.03 6.8 2.03 12c0 5.05 4.13 10 10.22 10 5.35 0 9.25-3.67 9.25-9.09 0-1.15-.15-1.81-.15-1.81z" />
	</SvgIcon>
);

export const FacebookIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path d="M20 12.05a8 8 0 10-9.25 7.9v-5.59H8.9v-2.3h1.85V10a3.22 3.22 0 013.37-3.55c.68 0 1.34.12 1.34.12v1.75h-.75c-.74 0-.97.46-.97.93v1.11h1.65l-.26 2.3h-1.4v5.59A8 8 0 0020 12.05z" />
	</SvgIcon>
);
