import React, { useState } from 'react';
import { Box, Paper, Typography, Button, Stack, IconButton, Tooltip, Chip, TextField, Autocomplete } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { 
  BookmarkBorder as SaveIcon, 
  BookmarkAdded as SavedIcon,
  Close as CloseIcon,
  Star as StarIcon,
  Notifications as NotifyIcon,
  Share as ShareIcon,
  LocalOffer as TagIcon,
  Add as AddIcon
} from '@mui/icons-material';
import ProductImage from '../assets/images/features/product-image.svg';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BrowserBar = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark' ? '#2f2f2f' : '#f0f0f0',
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const BrowserButton = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[400],
}));

const BrowserAddress = styled(Box)(({ theme }) => ({
  flex: 1,
  background: theme.palette.mode === 'dark' ? '#1f1f1f' : '#ffffff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1.5),
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));

const ExtensionContainer = styled(Paper)(({ theme }) => ({
  width: '320px',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  position: 'relative',
  animation: `${slideIn} 0.3s ease-out`,
  boxShadow: theme.shadows[8],
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    animation: `${pulse} 0.3s ease-in-out`,
  },
}));

export default function ExtensionPreview() {
  const [isSaved, setIsSaved] = useState(false);
  const [isNotifying, setIsNotifying] = useState(false);
  const [rating, setRating] = useState(0);
  const [tags, setTags] = useState<string[]>([]);
  const [inputTag, setInputTag] = useState('');
  const suggestedTags = ['Electronics', 'Gadgets', 'Deals', 'Wishlist', 'Compare Later'];

  const handleSave = () => {
    setIsSaved(!isSaved);
  };

  const handleAddTag = (tag: string) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputTag('');
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <BrowserBar>
        <BrowserButton sx={{ bgcolor: '#ff5f57' }} />
        <BrowserButton sx={{ bgcolor: '#ffbd2e' }} />
        <BrowserButton sx={{ bgcolor: '#28c940' }} />
        <BrowserAddress>amazon.com/product...</BrowserAddress>
      </BrowserBar>
      <ExtensionContainer>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}>
          <Typography variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 600 }}>
            Save Product
          </Typography>
          <IconButton size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <Box
            component="img"
            src={ProductImage}
            alt="Product Preview"
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 2,
              mb: 2
            }}
          />
          <Box 
            sx={{ 
              position: 'absolute',
              top: 8,
              right: 8,
              display: 'flex',
              gap: 1,
              background: 'rgba(0,0,0,0.5)',
              borderRadius: 2,
              padding: 0.5,
            }}
          >
            {[1, 2, 3, 4, 5].map((star) => (
              <Tooltip key={star} title={`Rate ${star} stars`}>
                <StarIcon 
                  sx={{ 
                    color: star <= rating ? 'warning.main' : 'white',
                    cursor: 'pointer',
                    fontSize: 20,
                  }}
                  onClick={() => setRating(star)}
                />
              </Tooltip>
            ))}
          </Box>
        </Box>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Product Name
              </Typography>
              <Typography color="primary.main" variant="h6" sx={{ fontWeight: 600 }}>
                $99.99
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              <Tooltip title="Share product">
                <ActionButton size="small">
                  <ShareIcon fontSize="small" />
                </ActionButton>
              </Tooltip>
              <Tooltip title={isNotifying ? "Stop notifications" : "Get price alerts"}>
                <ActionButton 
                  size="small" 
                  color={isNotifying ? "primary" : "default"}
                  onClick={() => setIsNotifying(!isNotifying)}
                >
                  <NotifyIcon fontSize="small" />
                </ActionButton>
              </Tooltip>
            </Stack>
          </Box>
          <Typography variant="body2" color="text.secondary">
            Add this product to your comparison list and track its price changes.
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              Add tags to organize your products
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mb: 1 }}>
              {tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  size="small"
                  onDelete={() => setTags(tags.filter(t => t !== tag))}
                  sx={{ 
                    animation: `${fadeIn} 0.3s ease-out`,
                  }}
                />
              ))}
            </Box>
            <Autocomplete
              freeSolo
              size="small"
              options={suggestedTags.filter(tag => !tags.includes(tag))}
              inputValue={inputTag}
              onInputChange={(_, value) => setInputTag(value)}
              onChange={(_, value) => value && handleAddTag(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add tags..."
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <TagIcon fontSize="small" sx={{ mr: 1, opacity: 0.5 }} />,
                  }}
                />
              )}
            />
          </Box>
          <Button
            variant="contained"
            startIcon={isSaved ? <SavedIcon /> : <SaveIcon />}
            fullWidth
            onClick={handleSave}
            sx={{ 
              mt: 2,
              bgcolor: isSaved ? 'success.main' : 'primary.main',
              '&:hover': {
                bgcolor: isSaved ? 'success.dark' : 'primary.dark',
              }
            }}
          >
            {isSaved ? 'Saved to Comparist' : 'Save to Comparist'}
          </Button>
        </Stack>
      </ExtensionContainer>
    </Box>
  );
} 