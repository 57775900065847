import { call, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import * as domainService from "../../../services/domainService";

import {
	getDomainsListAction,
	getDomainsListFailed,
	getDomainsListSuccess,
} from "./knownDomainsSlice";

function* getDomainsSaga(): SagaIterator {
	try {
		const { data } = yield call(domainService.getAllDomains);
		yield put(getDomainsListSuccess(data));
	} catch (error: any) {
		yield put(getDomainsListFailed(error.message));
	}
}

function* knownDomainSaga() {
	yield takeLatest(getDomainsListAction.type, getDomainsSaga);
}

export default knownDomainSaga;
