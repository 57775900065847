import * as React from "react";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Divider,
	TextField,
	Typography,
	Link,
	Container,
	Paper,
	IconButton,
	InputAdornment,
	Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	GoogleIcon,
	FacebookIcon,
	SitemarkIcon,
} from "../components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest, selectAuth } from "../store/authSlice";
import { Navigate } from "react-router-dom";
import Routes from "../../../configs/routes";
import ForgotPassword from "../components/ForgotPassword";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	borderRadius: theme.shape.borderRadius * 2,
	maxWidth: 450,
	width: '100%',
	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
	background: theme.palette.background.paper,
	backdropFilter: 'blur(20px)',
	border: '1px solid',
	borderColor: theme.palette.mode === 'dark' 
		? 'rgba(255, 255, 255, 0.05)'
		: 'rgba(255, 255, 255, 0.8)',
}));

const AuthContainer = styled(Box)(({ theme }) => ({
	minHeight: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(3),
	background: theme.palette.mode === 'dark'
		? 'radial-gradient(circle at 50% 50%, rgba(37, 38, 43, 1) 0%, rgba(24, 24, 28, 1) 100%)'
		: 'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(249, 250, 251, 1) 100%)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		borderRadius: theme.shape.borderRadius,
		transition: theme.transitions.create(['box-shadow', 'border-color']),
		'&:hover': {
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-focused': {
			boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
		},
	},
}));

const SocialButton = styled(Button)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(1.5),
	textTransform: 'none',
	fontWeight: 500,
	border: '1px solid',
	borderColor: theme.palette.mode === 'dark' 
		? 'rgba(255, 255, 255, 0.12)'
		: 'rgba(0, 0, 0, 0.12)',
	'&:hover': {
		backgroundColor: theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, 0.05)'
			: 'rgba(0, 0, 0, 0.05)',
	},
}));

const LogoContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: theme.spacing(3),
	'& .MuiSvgIcon-root': {
		width: 40,
		height: 40,
		color: theme.palette.primary.main
	}
}));

export default function SignIn() {
	const [showPassword, setShowPassword] = React.useState(false);
	const [emailError, setEmailError] = React.useState("");
	const [passwordError, setPasswordError] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const { isAuthenticated, init, error } = useSelector(selectAuth);
	const dispatch = useDispatch();

	if (isAuthenticated && init) {
		return <Navigate to={Routes.dashboard} />;
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		
		// Reset errors
		setEmailError("");
		setPasswordError("");

		// Validate
		let isValid = true;
		const email = data.get("email") as string;
		const password = data.get("password") as string;

		if (!email || !/\S+@\S+\.\S+/.test(email)) {
			setEmailError("Please enter a valid email address");
			isValid = false;
		}

		if (!password || password.length < 6) {
			setPasswordError("Password must be at least 6 characters");
			isValid = false;
		}

		if (isValid) {
			dispatch(loginRequest({ email, password }));
		}
	};

	return (
		<AuthContainer>
			<Container maxWidth="sm">
				<StyledPaper elevation={0}>
					<LogoContainer>
						<SitemarkIcon />
					</LogoContainer>
					
					<Typography variant="h4" align="center" gutterBottom fontWeight="bold">
						Welcome back
					</Typography>
					<Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 4 }}>
						Please enter your details to sign in
					</Typography>

					{error && (
						<Alert severity="error" sx={{ mb: 3 }}>
							{error}
						</Alert>
					)}

					<Box component="form" onSubmit={handleSubmit} noValidate>
						<StyledTextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							error={!!emailError}
							helperText={emailError}
						/>
						<StyledTextField
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type={showPassword ? 'text' : 'password'}
							id="password"
							autoComplete="current-password"
							error={!!passwordError}
							helperText={passwordError}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setShowPassword(!showPassword)}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
							<FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/>
							<Link
								component="button"
								variant="body2"
								onClick={() => setOpen(true)}
								sx={{ textDecoration: 'none' }}
							>
								Forgot password?
							</Link>
						</Box>

						<Button
							type="submit"
							fullWidth
							variant="contained"
							size="large"
							sx={{ 
								mt: 2, 
								mb: 3,
								py: 1.5,
								textTransform: 'none',
								fontWeight: 600
							}}
						>
							Sign In
						</Button>

						<Divider sx={{ my: 3 }}>
							<Typography variant="body2" color="text.secondary">
								or continue with
							</Typography>
						</Divider>

						<Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
							<SocialButton
								fullWidth
								startIcon={<GoogleIcon />}
								onClick={() => alert("Sign in with Google")}
							>
								Google
							</SocialButton>
							<SocialButton
								fullWidth
								startIcon={<FacebookIcon />}
								onClick={() => alert("Sign in with Facebook")}
							>
								Facebook
							</SocialButton>
						</Box>

						<Typography align="center" variant="body2">
							Don't have an account?{' '}
							<Link href={Routes.signUp} sx={{ textDecoration: 'none', fontWeight: 500 }}>
								Sign up
							</Link>
						</Typography>
					</Box>
				</StyledPaper>
			</Container>

			<ForgotPassword open={open} handleClose={() => setOpen(false)} />
		</AuthContainer>
	);
}
