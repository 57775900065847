import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { authSaga, authReducer } from "../modules/auth";
import { productReducer, productSaga } from "../modules/product";
import { notificationSaga, notificationReducer } from "../modules/notification";
import { knownDomainSaga, knownDomainsReducer} from "../modules/domains";
import { tagsReducer, tagsSaga } from "../modules/tag";
import navigationReducer from "./navigationSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: {
		auth: authReducer,
		products: productReducer,
		notifications: notificationReducer,
		tags: tagsReducer,
		navigation: navigationReducer,
		domains: knownDomainsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(function* rootSaga() {
	yield all([authSaga(), productSaga(), notificationSaga(), tagsSaga(), knownDomainSaga()]);
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
