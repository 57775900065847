import { styled } from '@mui/material/styles';

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

// Base64 placeholder for a simple image placeholder
const PLACEHOLDER_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiIGZpbGw9IiNFRUVFRUUiLz48cGF0aCBkPSJNODAgOTBINzBWMTEwSDgwVjEyMEg5MFYxMTBIMTAwVjEyMEgxMTBWMTEwSDEyMFY5MEgxMTBWODBIMTAwVjkwSDkwVjgwSDgwVjkwWiIgZmlsbD0iI0JCQkJCQiIvPjwvc3ZnPg==';

const StyledImage = styled('img')({
  width: '100%',
  height: 200,
  objectFit: 'contain',
  backgroundColor: '#f5f5f5',
  transition: 'transform 0.3s ease-in-out',
});

const ImageWithFallback = ({ src, alt, className }: ImageProps) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = PLACEHOLDER_IMAGE;
  };

  return (
    <StyledImage
      src={src || PLACEHOLDER_IMAGE}
      alt={alt}
      onError={handleError}
      className={className}
    />
  );
};

export default ImageWithFallback; 