import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyAccount } from "../../../services/authService";
import { showSuccessToast } from "../../../utils/toaster";
import Routes from "../../../configs/routes";

const EmailConfirmation: React.FC = () => {
	const { token } = useParams<Record<string, string | undefined>>(); // Fix here
	const [message, setMessage] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		const confirmEmail = async () => {
			try {
				if (!token) {
					setMessage("Invalid token.");
					return;
				}
				await verifyAccount(token);
				showSuccessToast("Email verified successfully!");
				navigate(Routes.login);
			} catch (error) {
				setMessage("There was an error verifying your email.");
			} finally {
				setLoading(false);
			}
		};

		confirmEmail();
	}, [navigate, token]);

	return (
		<div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
			<div className="bg-white shadow-md rounded p-6 max-w-md w-full">
				{loading ? (
					<p className="text-gray-600 text-center">Verifying your email...</p>
				) : (
					<p className={`text-center text-red-600`}>{message}</p>
				)}
			</div>
		</div>
	);
};

export default EmailConfirmation;
