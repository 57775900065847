import React from 'react';
import { Box, Paper, Typography, LinearProgress } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const PreviewContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '400px',
  background: 'rgba(255,255,255,0.1)',
  backdropFilter: 'blur(8px)',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  position: 'relative',
  animation: `${float} 6s ease-in-out infinite`,
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '40px',
    background: 'rgba(255,255,255,0.1)',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
  }
}));

const MockChart = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '150px',
  background: 'linear-gradient(90deg, rgba(255,255,255,0.03) 25%, rgba(255,255,255,0.08) 37%, rgba(255,255,255,0.03) 63%)',
  backgroundSize: '1000px 100%',
  animation: `${shimmer} 2s infinite linear`,
  borderRadius: theme.shape.borderRadius,
  margin: '16px 0',
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(255,255,255,0.1)',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'white',
    transition: 'transform 1s ease-in-out',
  }
}));

export default function DashboardPreview() {
  const [progress, setProgress] = React.useState([30, 60, 45]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => prev.map(() => Math.random() * 100));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <PreviewContainer elevation={0}>
      <Box sx={{ p: 3, pt: 6 }}>
        <Typography variant="h6" color="white" sx={{ 
          opacity: 0.9, 
          mb: 3,
          position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: -8,
            left: 0,
            width: 40,
            height: 2,
            backgroundColor: 'white',
            opacity: 0.3,
          }
        }}>
          Price Comparison
        </Typography>
        <MockChart />
        <Box sx={{ mb: 3 }}>
          {progress.map((value, i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Typography variant="body2" color="white" sx={{ opacity: 0.7, mb: 1 }}>
                Product {i + 1}
              </Typography>
              <ProgressBar 
                variant="determinate" 
                value={value}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </PreviewContainer>
  );
} 