import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/auth/pages/LoginPage";
import SignUpPage from "./modules/auth/pages/SignUpPage";
import Dashboard from "./modules/dashboard/pages/Dashboard";
import ProductsList from "./modules/product/pages/ProductsPage";
import ProductView from "./modules/product/pages/ProductView";
import ConfirmEmailPage from "./modules/auth/pages/ConfirmEmailPage";
import VerifyEmailPage from "./modules/auth/pages/VerifyEmailPage";

import { TagsPage } from "./modules/tag";
import { ShopsPage } from "./modules/domains";
import MainLayout from "./layouts/MainLayout";
import AuthLayout from "./layouts/AuthLayout";
import Paths from "./configs/routes";
import Home from "./pages/Home/Home";
import ProfilePage from "modules/profile/pages/ProfilePage";

const App: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route path="/home" element={<Home />} />
				<Route path={Paths.confirmEmail} element={<ConfirmEmailPage />} />
				<Route path={Paths.verifyEmail} element={<VerifyEmailPage />} />
				<Route element={<MainLayout />}>
					<Route path={Paths.login} element={<LoginPage />} />
					<Route path={Paths.signUp} element={<SignUpPage />} />
					<Route element={<AuthLayout />}>
						<Route index path={Paths.dashboard} element={<Dashboard />} />
						<Route index path={Paths.products} element={<ProductsList />} />
						<Route index path={Paths.shops} element={<ShopsPage />} />
						<Route index path={Paths.tags} element={<TagsPage />} />
						<Route index path={Paths.productById} element={<ProductView />} />
						<Route index path={Paths.profile} element={<ProfilePage />} />
					</Route>
				</Route>
			</Routes>
		</Router>
	);
};

export default App;
