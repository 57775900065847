import { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Dialog,
  useTheme,
  Tooltip,
  IconButton,
  InputAdornment,
  alpha
} from '@mui/material';
import { ChromePicker } from 'react-color';
import { ITag } from 'modules/tag/@types';
import SearchIcon from '@mui/icons-material/Search';

import {
  ShoppingCart,
  Laptop,
  Phone,
  Home,
  Book,
  LocalOffer,
  Favorite,
  Star,
  Label,
  Category,
  Style,
  LocalMall,
  Devices,
  Watch,
  Headphones,
  Camera,
  Smartphone,
  Tablet,
  Kitchen,
  Restaurant,
  FitnessCenter,
  SportsEsports,
  Pets,
  ChildCare,
  LocalShipping,
  Storefront,
  Inventory,
  LocalCafe,
  School,
  Work,
  BeachAccess,
  Flight,
  Hotel,
  DirectionsCar,
  LocalParking,
  LocalAtm,
  AccountBalance,
  CreditCard,
  Savings,
  ShoppingBag,
  LocalGroceryStore,
  LocalPharmacy,
  LocalHospital,
  LocalFlorist,
  Spa,
  Celebration,
  CardGiftcard,
  SportsSoccer,
  SportsBasketball,
  SportsBaseball,
  Casino,
  Movie,
  MusicNote,
  Theaters,
  Games,
  Brush,
  ColorLens,
  Photo,
  Collections,
  Palette,
  Architecture,
  Build,
  Construction,
  Handyman
} from '@mui/icons-material';

interface TagDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (tag: ITag) => void;
  tag: ITag | null;
  showColorPicker: boolean;
  onColorPickerChange: (show: boolean) => void;
}

// Create a map of common icons
const commonIcons = {
  ShoppingCart,
  ShoppingBag,
  LocalGroceryStore,
  Storefront,
  LocalMall,
  Laptop,
  Phone,
  Smartphone,
  Tablet,
  Devices,
  Watch,
  Headphones,
  Camera,
  Home,
  Kitchen,
  Furniture: Build,
  Architecture,
  Construction,
  Handyman,
  Restaurant,
  LocalCafe,
  LocalFlorist,
  FitnessCenter,
  LocalHospital,
  LocalPharmacy,
  Spa,
  SportsEsports,
  Movie,
  MusicNote,
  Theaters,
  Games,
  Casino,
  SportsSoccer,
  SportsBasketball,
  SportsBaseball,
  Flight,
  Hotel,
  DirectionsCar,
  LocalParking,
  LocalShipping,
  BeachAccess,
  LocalAtm,
  AccountBalance,
  CreditCard,
  Savings,
  Book,
  School,
  LocalOffer,
  Favorite,
  Star,
  Label,
  Category,
  Pets,
  ChildCare,
  Work,
  Celebration,
  CardGiftcard,
  Inventory,
  Brush,
  ColorLens,
  Photo,
  Collections,
  Palette,
  Style
};

function TagDialog({ open, onClose, onSave, tag, showColorPicker, onColorPickerChange }: TagDialogProps) {
  const theme = useTheme();
  const [iconSearch, setIconSearch] = useState('');
  const [formData, setFormData] = useState<ITag>({
    id: tag?.id || 0,
    name: tag?.name || '',
    description: tag?.description || '',
    color: tag?.color || generateRandomColor(),
    icon: tag?.icon || 'LocalOffer',
    products: tag?.products || []
  });

  const filteredIcons = useMemo(() => {
    const searchTerm = iconSearch.toLowerCase();
    return Object.entries(commonIcons).filter(([name]) => 
      name.toLowerCase().includes(searchTerm)
    );
  }, [iconSearch]);

  useEffect(() => {
    if (tag) {
      setFormData(tag);
    }
  }, [tag]);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 3,
          width: '100%',
          maxWidth: 500,
        }
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          {tag ? 'Edit Tag' : 'Create New Tag'}
        </Typography>
        
        <TextField
          fullWidth
          label="Tag Name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Description"
          multiline
          rows={3}
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          sx={{ mb: 2 }}
        />

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Tag Color
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                bgcolor: formData.color,
                cursor: 'pointer',
                border: '2px solid',
                borderColor: 'divider',
              }}
              onClick={() => onColorPickerChange(true)}
            />
            <Typography variant="body2" color="text.secondary">
              Click to change color
            </Typography>
          </Box>
          {showColorPicker && (
            <Box sx={{ mt: 2, position: 'relative' }}>
              <ChromePicker
                color={formData.color}
                onChange={(color) => setFormData({ ...formData, color: color.hex })}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" gutterBottom>
            Tag Icon
          </Typography>
          <TextField
            fullWidth
            placeholder="Search icons..."
            value={iconSearch}
            onChange={(e) => setIconSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: 'repeat(auto-fill, minmax(40px, 1fr))',
            gap: 1,
            maxHeight: 200,
            overflow: 'auto',
            p: 1,
            border: 1,
            borderColor: 'divider',
            borderRadius: 1
          }}>
            {filteredIcons.map(([name, Icon]) => (
              <Tooltip title={name} key={name}>
                <IconButton
                  size="small"
                  onClick={() => setFormData({ ...formData, icon: name })}
                  sx={{
                    color: formData.icon === name ? 'primary.main' : 'text.secondary',
                    bgcolor: formData.icon === name ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                    '&:hover': {
                      bgcolor: alpha(theme.palette.primary.main, 0.05)
                    }
                  }}
                >
                  <Icon />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onSave(formData as ITag)}
            disabled={!formData.name}
          >
            Save Tag
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default TagDialog; 

function generateRandomColor(): string {
  return '#' + Math.floor(Math.random()*16777215).toString(16);
}
