import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../store/store";
import { getToken, removeToken } from "../../../services/localStorageService";
import { createAsyncThunk } from '@reduxjs/toolkit';

interface User {
	id: number;
	name: string;
	email: string;
}

interface AuthState {
	isAuthenticated: boolean;
	user: User | null;
	loading: boolean;
	error: string | null;
	init: boolean;
	isVerified: boolean;
}

const initialState: AuthState = {
	isAuthenticated: false,
	user: null,
	loading: false,
	error: null,
	init: false,
	isVerified: false,
};

export type LoginCredentials = {
	email: string;
	password: string;
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		signupRequest: (state, action: PayloadAction<LoginCredentials>) => {
			state.loading = true;
			state.error = null;
		},
		signupSuccess: (state, action: PayloadAction<LoginCredentials>) => {
			state.loading = false;
			state.error = null;
			state.user = { email: action.payload.email, id: 0, name: "" };
		},
		loginRequest: (state, action: PayloadAction<LoginCredentials>) => {
			state.loading = true;
			state.error = null;
		},
		loginSuccess: (state, action: PayloadAction<User>) => {
			state.loading = false;
			state.isAuthenticated = true;
			state.init = true;
			state.user = action.payload;
			console.log("getToken()", getToken(), "AUTHENTICATE");
			window.postMessage({ type: "AUTHENTICATE", token: getToken() }, "*");
		},
		loginFailure: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
			state.init = true;
		},

		signupFailure: (state, action: PayloadAction<string>) => {
			state.loading = false;
			state.error = action.payload;
		},

		logout: (state) => {
			state.isAuthenticated = false;
			state.user = null;
			removeToken();
		},
		getMe: (state) => {
			state.loading = true;
			state.error = null;
		},
		initSuccess: (state) => {
			state.loading = false;
			state.error = null;
			state.init = true;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(verifyEmail.fulfilled, (state) => {
			state.isVerified = true;
		});
	},
});

export const {
	loginRequest,
	loginSuccess,
	loginFailure,
	logout,
	getMe,
	signupRequest,
	signupSuccess,
	signupFailure,
	initSuccess,
} = authSlice.actions;

export default authSlice.reducer;

// Selectors
export const selectAuth = (state: RootState) => state.auth;

export const verifyEmail = createAsyncThunk(
	'auth/verifyEmail',
	async (token: string) => {
		// Add your API call here
		const response = await fetch(`/api/verify-email/${token}`);
		return response.json();
	}
);
