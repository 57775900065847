const ACCESS_TOKEN = "ACCESS_TOKEN";

export function setToken(token: string) {
	localStorage.setItem(ACCESS_TOKEN, token);
}

export function getToken() {
	return localStorage.getItem(ACCESS_TOKEN);
}

export function removeToken() {
	return localStorage.removeItem(ACCESS_TOKEN);
}
