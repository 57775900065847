import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendVerificationCode, verifyAccount } from "../../../services/authService";
import { showSuccessToast } from "../../../utils/toaster";
import Routes from "../../../configs/routes";
import {
	Box,
	Button,
	Typography,
	Container,
	Paper,
	Link,
	Alert,
	CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SitemarkIcon } from "../components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../store/authSlice";
import { Link as RouterLink } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	borderRadius: theme.shape.borderRadius * 2,
	maxWidth: 450,
	width: '100%',
	boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 50px',
	background: theme.palette.background.paper,
	backdropFilter: 'blur(20px)',
	border: '1px solid',
	borderColor: theme.palette.mode === 'dark' 
		? 'rgba(255, 255, 255, 0.05)'
		: 'rgba(255, 255, 255, 0.8)',
}));

const AuthContainer = styled(Box)(({ theme }) => ({
	minHeight: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(3),
	background: theme.palette.mode === 'dark'
		? 'radial-gradient(circle at 50% 50%, rgba(37, 38, 43, 1) 0%, rgba(24, 24, 28, 1) 100%)'
		: 'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(249, 250, 251, 1) 100%)',
}));

const LogoContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: theme.spacing(3),
	'& .MuiSvgIcon-root': {
		width: 40,
		height: 40,
		color: theme.palette.primary.main
	}
}));

const IconContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: theme.spacing(3),
	marginTop: theme.spacing(2),
	'& .MuiSvgIcon-root': {
		width: 60,
		height: 60,
		color: theme.palette.primary.main,
		padding: theme.spacing(1.5),
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.light + '20',
	}
}));

const EmailConfirmation: React.FC = () => {
	const { email } = useParams<Record<string, string | undefined>>();
	const [message, setMessage] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();
	const auth = useSelector(selectAuth);
	
	// States for the resend button
	const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
	const [cooldownTime, setCooldownTime] = useState<number>(120); // 2 minutes in seconds
	const [remainingTime, setRemainingTime] = useState<number>(0);

	// Handle the cooldown timer
	useEffect(() => {
		let timerId: NodeJS.Timeout;
		
		if (isResendDisabled && remainingTime > 0) {
			timerId = setTimeout(() => {
				setRemainingTime(prev => prev - 1);
			}, 1000);
		} else if (remainingTime === 0 && isResendDisabled) {
			setIsResendDisabled(false);
		}
		
		return () => {
			if (timerId) clearTimeout(timerId);
		};
	}, [isResendDisabled, remainingTime]);

	// Format the remaining time to MM:SS
	const formatTime = useCallback((seconds: number): string => {
		const mins = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
	}, []);

	// Handle resend email
	const handleResendEmail = useCallback(() => {
		if (email) {
			sendVerificationCode(email);
			showSuccessToast("Verification email resent successfully!");
			setIsResendDisabled(true);
			setRemainingTime(cooldownTime);
		}
	}, [email, cooldownTime]);

	return (
		<AuthContainer>
			<Container maxWidth="sm">
				<StyledPaper>
					<LogoContainer>
						<SitemarkIcon />
					</LogoContainer>
					
					<Typography variant="h4" align="center" gutterBottom fontWeight="bold">
						Verify Your Email
					</Typography>
					
					<>
						<Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 2 }}>
							We've sent a verification link to:
						</Typography>
						
						<Typography variant="body1" align="center" fontWeight="bold" sx={{ mb: 3 }}>
							{email || 'your email address'}
						</Typography>
						
						<IconContainer>
							<EmailIcon />
						</IconContainer>
						
						<Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 2 }}>
							Please check your inbox and click the verification link to complete your registration.
						</Typography>
						
						<Typography variant="body2" align="center" color="text.secondary" sx={{ mb: 4 }}>
							If you don't see the email, check your spam folder or
						</Typography>
						
						<Button
							fullWidth
							variant="outlined"
							size="large"
							sx={{ 
								mt: 2,
								py: 1.5,
								textTransform: 'none',
								fontWeight: 600
							}}
							onClick={handleResendEmail}
							disabled={isResendDisabled}
						>
							{isResendDisabled 
								? `Resend Available in ${formatTime(remainingTime)}` 
								: 'Resend Verification Email'}
						</Button>
						
						<Box sx={{ mt: 3, textAlign: 'center' }}>
							<Link 
								component={RouterLink} 
								to={Routes.login}
								underline="hover"
								sx={{ fontWeight: 500 }}
							>
								Back to Login
							</Link>
						</Box>
					</>
				
				</StyledPaper>
			</Container>
		</AuthContainer>
	);
};

export default EmailConfirmation;
