import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  IconButton,
  Chip,
  Link,
  alpha,
  useTheme,
  InputAdornment,
  Button,
  Avatar,
  Divider,
  CircularProgress,
  Skeleton,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StoreIcon from '@mui/icons-material/Store';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneIcon from '@mui/icons-material/Tune';
import StarIcon from '@mui/icons-material/Star';
import PublicIcon from '@mui/icons-material/Public';
import { motion } from 'framer-motion';
import { IDomain } from '../@types';
import { 
  getDomainsListAction,
  selectDomains 
} from '../store/knownDomainsSlice';
import Autocomplete from '@mui/material/Autocomplete';

// Replace the current countryData with this comprehensive list
const countryData = {
  'af': { name: 'Afghanistan', flag: '🇦🇫' },
  'al': { name: 'Albania', flag: '🇦🇱' },
  'dz': { name: 'Algeria', flag: '🇩🇿' },
  'ad': { name: 'Andorra', flag: '🇦🇩' },
  'ao': { name: 'Angola', flag: '🇦🇴' },
  'ag': { name: 'Antigua and Barbuda', flag: '🇦🇬' },
  'ar': { name: 'Argentina', flag: '🇦🇷' },
  'am': { name: 'Armenia', flag: '🇦🇲' },
  'au': { name: 'Australia', flag: '🇦🇺' },
  'at': { name: 'Austria', flag: '🇦🇹' },
  'az': { name: 'Azerbaijan', flag: '🇦🇿' },
  'bs': { name: 'Bahamas', flag: '🇧🇸' },
  'bh': { name: 'Bahrain', flag: '🇧🇭' },
  'bd': { name: 'Bangladesh', flag: '🇧🇩' },
  'bb': { name: 'Barbados', flag: '🇧🇧' },
  'by': { name: 'Belarus', flag: '🇧🇾' },
  'be': { name: 'Belgium', flag: '🇧🇪' },
  'bz': { name: 'Belize', flag: '🇧🇿' },
  'bj': { name: 'Benin', flag: '🇧🇯' },
  'bt': { name: 'Bhutan', flag: '🇧🇹' },
  'bo': { name: 'Bolivia', flag: '🇧🇴' },
  'ba': { name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
  'bw': { name: 'Botswana', flag: '🇧🇼' },
  'br': { name: 'Brazil', flag: '🇧🇷' },
  'bn': { name: 'Brunei', flag: '🇧🇳' },
  'bg': { name: 'Bulgaria', flag: '🇧🇬' },
  'bf': { name: 'Burkina Faso', flag: '🇧🇫' },
  'bi': { name: 'Burundi', flag: '🇧🇮' },
  'kh': { name: 'Cambodia', flag: '🇰🇭' },
  'cm': { name: 'Cameroon', flag: '🇨🇲' },
  'ca': { name: 'Canada', flag: '🇨🇦' },
  'cv': { name: 'Cape Verde', flag: '🇨🇻' },
  'cf': { name: 'Central African Republic', flag: '🇨🇫' },
  'td': { name: 'Chad', flag: '🇹🇩' },
  'cl': { name: 'Chile', flag: '🇨🇱' },
  'cn': { name: 'China', flag: '🇨🇳' },
  'co': { name: 'Colombia', flag: '🇨🇴' },
  'km': { name: 'Comoros', flag: '🇰🇲' },
  'cg': { name: 'Congo', flag: '🇨🇬' },
  'cd': { name: 'DR Congo', flag: '🇨🇩' },
  'cr': { name: 'Costa Rica', flag: '🇨🇷' },
  'hr': { name: 'Croatia', flag: '🇭🇷' },
  'cu': { name: 'Cuba', flag: '🇨🇺' },
  'cy': { name: 'Cyprus', flag: '🇨🇾' },
  'cz': { name: 'Czech Republic', flag: '🇨🇿' },
  'dk': { name: 'Denmark', flag: '🇩🇰' },
  'dj': { name: 'Djibouti', flag: '🇩🇯' },
  'dm': { name: 'Dominica', flag: '🇩🇲' },
  'do': { name: 'Dominican Republic', flag: '🇩🇴' },
  'ec': { name: 'Ecuador', flag: '🇪🇨' },
  'eg': { name: 'Egypt', flag: '🇪🇬' },
  'sv': { name: 'El Salvador', flag: '🇸🇻' },
  'gq': { name: 'Equatorial Guinea', flag: '🇬🇶' },
  'er': { name: 'Eritrea', flag: '🇪🇷' },
  'ee': { name: 'Estonia', flag: '🇪🇪' },
  'et': { name: 'Ethiopia', flag: '🇪🇹' },
  'fj': { name: 'Fiji', flag: '🇫🇯' },
  'fi': { name: 'Finland', flag: '🇫🇮' },
  'fr': { name: 'France', flag: '🇫🇷' },
  'ga': { name: 'Gabon', flag: '🇬🇦' },
  'gm': { name: 'Gambia', flag: '🇬🇲' },
  'ge': { name: 'Georgia', flag: '🇬🇪' },
  'de': { name: 'Germany', flag: '🇩🇪' },
  'gh': { name: 'Ghana', flag: '🇬🇭' },
  'gr': { name: 'Greece', flag: '🇬🇷' },
  'gd': { name: 'Grenada', flag: '🇬🇩' },
  'gt': { name: 'Guatemala', flag: '🇬🇹' },
  'gn': { name: 'Guinea', flag: '🇬🇳' },
  'gw': { name: 'Guinea-Bissau', flag: '🇬🇼' },
  'gy': { name: 'Guyana', flag: '🇬🇾' },
  'ht': { name: 'Haiti', flag: '🇭🇹' },
  'hn': { name: 'Honduras', flag: '🇭🇳' },
  'hu': { name: 'Hungary', flag: '🇭🇺' },
  'is': { name: 'Iceland', flag: '🇮🇸' },
  'in': { name: 'India', flag: '🇮🇳' },
  'id': { name: 'Indonesia', flag: '🇮🇩' },
  'ir': { name: 'Iran', flag: '🇮🇷' },
  'iq': { name: 'Iraq', flag: '🇮🇶' },
  'ie': { name: 'Ireland', flag: '🇮🇪' },
  'il': { name: 'Israel', flag: '🇮🇱' },
  'it': { name: 'Italy', flag: '🇮🇹' },
  'ci': { name: 'Ivory Coast', flag: '🇨🇮' },
  'jm': { name: 'Jamaica', flag: '🇯🇲' },
  'jp': { name: 'Japan', flag: '🇯🇵' },
  'jo': { name: 'Jordan', flag: '🇯🇴' },
  'kz': { name: 'Kazakhstan', flag: '🇰🇿' },
  'ke': { name: 'Kenya', flag: '🇰🇪' },
  'ki': { name: 'Kiribati', flag: '🇰🇮' },
  'kp': { name: 'North Korea', flag: '🇰🇵' },
  'kr': { name: 'South Korea', flag: '🇰🇷' },
  'kw': { name: 'Kuwait', flag: '🇰🇼' },
  'kg': { name: 'Kyrgyzstan', flag: '🇰🇬' },
  'la': { name: 'Laos', flag: '🇱🇦' },
  'lv': { name: 'Latvia', flag: '🇱🇻' },
  'lb': { name: 'Lebanon', flag: '🇱🇧' },
  'ls': { name: 'Lesotho', flag: '🇱🇸' },
  'lr': { name: 'Liberia', flag: '🇱🇷' },
  'ly': { name: 'Libya', flag: '🇱🇾' },
  'li': { name: 'Liechtenstein', flag: '🇱🇮' },
  'lt': { name: 'Lithuania', flag: '🇱🇹' },
  'lu': { name: 'Luxembourg', flag: '🇱🇺' },
  'mg': { name: 'Madagascar', flag: '🇲🇬' },
  'mw': { name: 'Malawi', flag: '🇲🇼' },
  'my': { name: 'Malaysia', flag: '🇲🇾' },
  'mv': { name: 'Maldives', flag: '🇲🇻' },
  'ml': { name: 'Mali', flag: '🇲🇱' },
  'mt': { name: 'Malta', flag: '🇲🇹' },
  'mh': { name: 'Marshall Islands', flag: '🇲🇭' },
  'mr': { name: 'Mauritania', flag: '🇲🇷' },
  'mu': { name: 'Mauritius', flag: '🇲🇺' },
  'mx': { name: 'Mexico', flag: '🇲🇽' },
  'fm': { name: 'Micronesia', flag: '🇫🇲' },
  'md': { name: 'Moldova', flag: '🇲🇩' },
  'mc': { name: 'Monaco', flag: '🇲🇨' },
  'mn': { name: 'Mongolia', flag: '🇲🇳' },
  'me': { name: 'Montenegro', flag: '🇲🇪' },
  'ma': { name: 'Morocco', flag: '🇲🇦' },
  'mz': { name: 'Mozambique', flag: '🇲🇿' },
  'mm': { name: 'Myanmar', flag: '🇲🇲' },
  'na': { name: 'Namibia', flag: '🇳🇦' },
  'nr': { name: 'Nauru', flag: '🇳🇷' },
  'np': { name: 'Nepal', flag: '🇳🇵' },
  'nl': { name: 'Netherlands', flag: '🇳🇱' },
  'nz': { name: 'New Zealand', flag: '🇳🇿' },
  'ni': { name: 'Nicaragua', flag: '🇳🇮' },
  'ne': { name: 'Niger', flag: '🇳🇪' },
  'ng': { name: 'Nigeria', flag: '🇳🇬' },
  'mk': { name: 'North Macedonia', flag: '🇲🇰' },
  'no': { name: 'Norway', flag: '🇳🇴' },
  'om': { name: 'Oman', flag: '🇴🇲' },
  'pk': { name: 'Pakistan', flag: '🇵🇰' },
  'pw': { name: 'Palau', flag: '🇵🇼' },
  'pa': { name: 'Panama', flag: '🇵🇦' },
  'pg': { name: 'Papua New Guinea', flag: '🇵🇬' },
  'py': { name: 'Paraguay', flag: '🇵🇾' },
  'pe': { name: 'Peru', flag: '🇵🇪' },
  'ph': { name: 'Philippines', flag: '🇵🇭' },
  'pl': { name: 'Poland', flag: '🇵🇱' },
  'pt': { name: 'Portugal', flag: '🇵🇹' },
  'qa': { name: 'Qatar', flag: '🇶🇦' },
  'ro': { name: 'Romania', flag: '🇷🇴' },
  'ru': { name: 'Russia', flag: '🇷🇺' },
  'rw': { name: 'Rwanda', flag: '🇷🇼' },
  'kn': { name: 'Saint Kitts and Nevis', flag: '🇰🇳' },
  'lc': { name: 'Saint Lucia', flag: '🇱🇨' },
  'vc': { name: 'Saint Vincent', flag: '🇻🇨' },
  'ws': { name: 'Samoa', flag: '🇼🇸' },
  'sm': { name: 'San Marino', flag: '🇸🇲' },
  'st': { name: 'Sao Tome and Principe', flag: '🇸🇹' },
  'sa': { name: 'Saudi Arabia', flag: '🇸🇦' },
  'sn': { name: 'Senegal', flag: '🇸🇳' },
  'rs': { name: 'Serbia', flag: '🇷🇸' },
  'sc': { name: 'Seychelles', flag: '🇸🇨' },
  'sl': { name: 'Sierra Leone', flag: '🇸🇱' },
  'sg': { name: 'Singapore', flag: '🇸🇬' },
  'sk': { name: 'Slovakia', flag: '🇸🇰' },
  'si': { name: 'Slovenia', flag: '🇸🇮' },
  'sb': { name: 'Solomon Islands', flag: '🇸🇧' },
  'so': { name: 'Somalia', flag: '🇸🇴' },
  'za': { name: 'South Africa', flag: '🇿🇦' },
  'ss': { name: 'South Sudan', flag: '🇸🇸' },
  'es': { name: 'Spain', flag: '🇪🇸' },
  'lk': { name: 'Sri Lanka', flag: '🇱🇰' },
  'sd': { name: 'Sudan', flag: '🇸🇩' },
  'sr': { name: 'Suriname', flag: '🇸🇷' },
  'se': { name: 'Sweden', flag: '🇸🇪' },
  'ch': { name: 'Switzerland', flag: '🇨🇭' },
  'sy': { name: 'Syria', flag: '🇸🇾' },
  'tw': { name: 'Taiwan', flag: '🇹🇼' },
  'tj': { name: 'Tajikistan', flag: '🇹🇯' },
  'tz': { name: 'Tanzania', flag: '🇹🇿' },
  'th': { name: 'Thailand', flag: '🇹🇭' },
  'tl': { name: 'Timor-Leste', flag: '🇹🇱' },
  'tg': { name: 'Togo', flag: '🇹🇬' },
  'to': { name: 'Tonga', flag: '🇹🇴' },
  'tt': { name: 'Trinidad and Tobago', flag: '🇹🇹' },
  'tn': { name: 'Tunisia', flag: '🇹🇳' },
  'tr': { name: 'Turkey', flag: '🇹🇷' },
  'tm': { name: 'Turkmenistan', flag: '🇹🇲' },
  'tv': { name: 'Tuvalu', flag: '🇹🇻' },
  'ug': { name: 'Uganda', flag: '🇺🇬' },
  'ua': { name: 'Ukraine', flag: '🇺🇦' },
  'ae': { name: 'United Arab Emirates', flag: '🇦🇪' },
  'gb': { name: 'United Kingdom', flag: '🇬🇧' },
  'us': { name: 'United States', flag: '🇺🇸' },
  'uy': { name: 'Uruguay', flag: '🇺🇾' },
  'uz': { name: 'Uzbekistan', flag: '🇺🇿' },
  'vu': { name: 'Vanuatu', flag: '🇻🇺' },
  'va': { name: 'Vatican City', flag: '🇻🇦' },
  've': { name: 'Venezuela', flag: '🇻🇪' },
  'vn': { name: 'Vietnam', flag: '🇻🇳' },
  'ye': { name: 'Yemen', flag: '🇾🇪' },
  'zm': { name: 'Zambia', flag: '🇿🇲' },
  'zw': { name: 'Zimbabwe', flag: '🇿🇼' },
  'unknown': { name: 'Global', flag: '🌎' }
};

// Simplified categories
const categories = [
  { id: 'all', name: 'All' },
  { id: 'electronics', name: 'Electronics' },
  { id: 'fashion', name: 'Fashion' },
  { id: 'home', name: 'Home' },
  { id: 'beauty', name: 'Beauty' }
];

// Elegant styled components
const PageHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  borderRadius: 12,
  background: theme.palette.background.paper,
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  position: 'relative',
  boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
  border: `1px solid ${alpha(theme.palette.divider, 0.08)}`,
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .shop-image': {
      transform: 'scale(1.05)'
    }
  }
}));

const CardImageWrapper = styled(Box)(({ theme }) => ({
  height: 180,
  position: 'relative',
  backgroundColor: alpha(theme.palette.primary.light, 0.04),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40px',
    background: `linear-gradient(to top, ${theme.palette.background.paper}, transparent)`,
  }
}));

const ShopImage = styled('img')({
  maxWidth: '60%',
  maxHeight: '60%',
  objectFit: 'contain',
  transition: 'transform 0.4s ease',
  className: 'shop-image'
});

const CountryFlag = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 12,
  right: 12,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 20,
  padding: '4px 10px',
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(5px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  fontSize: '0.75rem',
  fontWeight: 500,
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  '& .flag': {
    fontSize: '1rem',
    marginRight: theme.spacing(0.75)
  }
}));

const FavoriteButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 12,
  left: 12,
  zIndex: 2,
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  backdropFilter: 'blur(5px)',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  padding: 6,
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 1),
  }
}));

const SearchBox = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    '&.Mui-focused': {
      boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.25)'
    }
  }
}));

const CategoryButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: 8,
  padding: '6px 16px',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  textTransform: 'none',
  fontWeight: 500,
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
    }
  }
}));

const FilterSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const ShopsPage: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { domains, loading, error } = useSelector(selectDomains);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [favorites, setFavorites] = useState<string[]>([]);
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('all');
  
  // Fetch domains on mount
  useEffect(() => {
    dispatch(getDomainsListAction());
  }, [dispatch]);

  // Helper functions
  const getDomainLogo = (domain: string) => {
    return `https://logo.clearbit.com/${domain}`;
  };
  
  const getDomainCategory = (domain: string) => {
    // Simple mapping logic
    if (domain.includes('electronics') || domain.includes('tech')) return 'electronics';
    if (domain.includes('cloth') || domain.includes('apparel')) return 'fashion';
    if (domain.includes('home') || domain.includes('furniture')) return 'home';
    if (domain.includes('beauty') || domain.includes('cosmetic')) return 'beauty';
    return 'all';
  };
  
  const getDisplayName = (domain: string) => {
    // Clean up domain name for display
    return domain
      .replace(/\.(com|net|org|co|io|shop|store)$/i, '')
      .replace(/www\./i, '')
      .split('.')
      .join(' ')
      .split('-')
      .join(' ')
      .replace(/\b\w/g, l => l.toUpperCase()); // Capitalize each word
  };
  
  const getCountryData = (regionCode: string) => {
    const code = regionCode?.toLowerCase() || 'unknown';
    return countryData[code as keyof typeof countryData] || countryData.unknown;
  };
  
  // Toggle favorite status
  const toggleFavorite = (id: string) => {
    if (favorites.includes(id)) {
      setFavorites(favorites.filter(fav => fav !== id));
    } else {
      setFavorites([...favorites, id]);
    }
  };
  
  // Toggle favorite filter
  const toggleFavoritesFilter = () => {
    setShowOnlyFavorites(!showOnlyFavorites);
  };
  
  // Reset all filters
  const resetFilters = () => {
    setSearchTerm('');
    setSelectedCategory('all');
    setShowOnlyFavorites(false);
    setSelectedCountry('all');
  };
  
  // Filter domains
  const filteredDomains = useMemo(() => {
    return domains?.filter(domain => {
      // Text search filter
      const matchesSearch = !searchTerm || 
        domain.domain.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (domain.description && domain.description.toLowerCase().includes(searchTerm.toLowerCase()));
      
      // Category filter
      const matchesCategory = selectedCategory === 'all' || 
        getDomainCategory(domain.domain) === selectedCategory;
      
      // Favorites filter
      const matchesFavorites = !showOnlyFavorites || 
        favorites.includes(domain.id.toString());
      
      // Country filter - NEW
      const matchesCountry = selectedCountry === 'all' || 
        (domain.region?.toLowerCase() === selectedCountry);
      
      return matchesSearch && matchesCategory && matchesFavorites && matchesCountry;
    }) || [];
  }, [domains, searchTerm, selectedCategory, showOnlyFavorites, favorites, selectedCountry]);
  
  // Animation variants
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 12
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      {/* Header */}
      <PageHeader>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom 
          sx={{ fontWeight: 700 }}
        >
          Shop Directory
          </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary"
        >
          Browse our collection of verified shops from around the world
        </Typography>
      </PageHeader>
      
      {/* Filters */}
      <FilterSection>
        {/* Search */}
        <SearchBox
          fullWidth
          placeholder="Search for shops..."
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setSearchTerm('')}>
                  {/* X icon */}
                  <Box component="span" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>✕</Box>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        
        {/* Category & Favorites Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
          <ToggleButtonGroup
            value={selectedCategory}
            exclusive
            onChange={(_, value) => value && setSelectedCategory(value)}
            aria-label="category filter"
                        size="small" 
            sx={{ flexWrap: 'wrap' }}
          >
            {categories.map(category => (
              <CategoryButton key={category.id} value={category.id}>
                {category.name}
              </CategoryButton>
            ))}
          </ToggleButtonGroup>
          
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', alignItems: 'center', mt: 2 }}>
            <Autocomplete
              id="country-select"
              options={Object.entries(countryData).map(([code, data]) => ({ 
                code, 
                ...data 
              }))}
              getOptionLabel={(option) => option.name}
              value={selectedCountry === 'all' 
                ? { code: 'all', name: 'All Countries', flag: '🌎' } 
                : Object.entries(countryData)
                    .map(([code, data]) => ({ code, ...data }))
                    .find(country => country.code === selectedCountry) || 
                  { code: 'unknown', name: 'Global', flag: '🌎' }
              }
              onChange={(_, newValue) => setSelectedCountry(newValue?.code || 'all')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="outlined"
                        size="small" 
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <PublicIcon fontSize="small" />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  sx={{ minWidth: 220 }}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                  <Box component="span" sx={{ fontSize: '1.2rem', mr: 1 }}>
                    {option.flag}
                  </Box>
                  {option.name}
                </li>
              )}
              disableClearable
              sx={{ 
                minWidth: 220,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2
                }
              }}
            />
            
            <Button
              variant={showOnlyFavorites ? "contained" : "outlined"}
              size="small"
              startIcon={<FavoriteIcon />}
              onClick={toggleFavoritesFilter}
              sx={{ 
                borderRadius: 2,
                boxShadow: 'none',
                textTransform: 'none',
                px: 2
              }}
            >
              Favorites
            </Button>
                </Box>
                  </Box>
        
        {/* Active filters summary */}
        {(searchTerm || selectedCategory !== 'all' || showOnlyFavorites) && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
            <TuneIcon fontSize="small" color="action" />
            <Typography variant="body2" color="text.secondary">
              {filteredDomains.length} result{filteredDomains.length !== 1 ? 's' : ''}
                  </Typography>
            <Button 
                      size="small"
              onClick={resetFilters}
              sx={{ 
                ml: 'auto', 
                textTransform: 'none',
                color: theme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline'
                }
              }}
            >
              Reset filters
            </Button>
          </Box>
        )}
      </FilterSection>
      
      {/* Divider */}
      <Divider sx={{ mb: 4 }} />
      
      {/* Loading state */}
      {loading && (
        <Grid container spacing={3}>
          {[1, 2, 3, 4, 5, 6].map(i => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <StyledCard>
                <Skeleton variant="rectangular" height={180} />
                <CardContent>
                  <Skeleton variant="text" width="70%" height={32} />
                  <Skeleton variant="text" width="40%" />
                  <Skeleton variant="text" height={60} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Skeleton variant="circular" width={32} height={32} />
                    <Skeleton variant="rectangular" width={100} height={32} sx={{ borderRadius: 16 }} />
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
      
      {/* Error state */}
      {!loading && error && (
        <Box sx={{ 
          textAlign: 'center', 
          py: 8, 
          px: 3, 
          borderRadius: 2, 
          backgroundColor: alpha(theme.palette.error.light, 0.1) 
        }}>
          <StoreIcon sx={{ fontSize: 40, color: theme.palette.error.main, mb: 2, opacity: 0.7 }} />
          <Typography variant="h6" color="error.main" gutterBottom>
            Unable to load shops
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 3 }}>
            {error}
          </Typography>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={() => dispatch(getDomainsListAction())}
          >
            Try Again
          </Button>
        </Box>
      )}
      
      {/* Empty state */}
      {!loading && !error && filteredDomains.length === 0 && (
          <Box sx={{ textAlign: 'center', py: 8 }}>
          <Avatar
            sx={{
              width: 64,
              height: 64,
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              color: theme.palette.primary.main,
              mx: 'auto',
              mb: 2
            }}
          >
            <StoreIcon fontSize="large" />
          </Avatar>
          <Typography variant="h6" gutterBottom>
              No shops found
            </Typography>
          <Typography color="text.secondary" sx={{ mb: 3, maxWidth: 400, mx: 'auto' }}>
            {searchTerm || selectedCategory !== 'all' || showOnlyFavorites ? 
              "We couldn't find any shops matching your current filters." : 
              "There are no shops in our directory yet."}
            </Typography>
          {(searchTerm || selectedCategory !== 'all' || showOnlyFavorites) && (
            <Button 
              variant="outlined" 
              onClick={resetFilters}
              sx={{ borderRadius: 2 }}
            >
              Clear Filters
            </Button>
          )}
          </Box>
        )}
      
      {/* Shops grid */}
      {!loading && !error && filteredDomains.length > 0 && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Grid container spacing={3}>
            {filteredDomains.map((domain) => {
              const region = domain.region?.toLowerCase() || 'unknown';
              const country = getCountryData(region);
              const displayName = getDisplayName(domain.domain);
              const isFavorite = favorites.includes(domain.id.toString());
              
              return (
                <Grid item xs={12} sm={6} md={4} key={domain.id}>
                  <motion.div variants={itemVariants}>
                    <StyledCard>
                      {/* Country flag */}
                      <CountryFlag>
                        <span className="flag">{country.flag}</span>
                        {country.name}
                      </CountryFlag>
                      
                      {/* Favorite button */}
                      <FavoriteButton
                        size="small"
                        onClick={() => toggleFavorite(domain.id.toString())}
                        color={isFavorite ? "error" : "default"}
                      >
                        {isFavorite ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
                      </FavoriteButton>
                      
                      {/* Shop logo */}
                      <CardImageWrapper>
                        <ShopImage 
                          src={getDomainLogo(domain.domain)}
                          alt={displayName}
                          onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                            // Fallback for broken images
                            e.currentTarget.src = `https://ui-avatars.com/api/?name=${displayName}&background=random&color=fff&size=128`;
                          }}
                        />
                      </CardImageWrapper>
                      
                      {/* Shop info */}
                      <CardContent>
                        <Typography 
                          variant="h6" 
                          gutterBottom 
                          sx={{ 
                            fontWeight: 600,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {displayName}
                        </Typography>
                        
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{
                            mb: 2,
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            height: 40
                          }}
                        >
                          {domain.description || `Shop online at ${displayName} with worldwide shipping available.`}
                        </Typography>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 'auto' }}>
                          <Chip 
                            size="small"
                            label={domain.status === 'CREATED_BY_USER' ? 'Verified' : 'Listed'}
                            color={domain.status === 'CREATED_BY_USER' ? 'success' : 'default'}
                            icon={domain.status === 'CREATED_BY_USER' ? <StarIcon /> : undefined}
                            variant="outlined"
                          />
                          
                          <Button
                            variant="outlined"
                            size="small"
                            endIcon={<OpenInNewIcon />}
                            href={`https://${domain.domain}`}
                            target="_blank"
                            sx={{ 
                              borderRadius: 2,
                              textTransform: 'none'
                            }}
                          >
                            Visit
                          </Button>
                        </Box>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>
              );
            })}
          </Grid>
        </motion.div>
      )}
    </Container>
  );
};

export default ShopsPage; 