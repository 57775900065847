import * as React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  IconButton,
  InputBase,
  Chip,
  Menu,
  MenuItem,
  Button,
  Fade,
  useTheme,
  alpha,
  Pagination,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import { useSelector, useDispatch } from 'react-redux';
import { selectProducts, getProductListAction, getProductsAction, removeProductByIdAction } from '../store/productSlice';
import { IProduct } from '../@types';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { AppDispatch } from '../../../store';
import ImageWithFallback from '../../../components/common/ImageWithFallback';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { EmptyStateIllustration } from '../../../components/illustrations/EmptyStateIllustration';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import ProductsList from './ProductsList';
import AddNewProduct from '../components/AddNewProduct';

const ProductsContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(180deg, #1a1b1e 0%, #121316 100%)'
    : 'linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)',
  padding: theme.spacing(3, 0),
}));

const SearchBar = styled(Paper)(({ theme }) => ({
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: 600,
  borderRadius: theme.shape.borderRadius * 3,
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.8)',
  transition: 'all 0.3s ease',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.1)}`,
  }
}));

const ProductCard = styled(motion(Paper))(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  background: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.04)'
    : '#ffffff',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 20px rgba(0, 0, 0, 0.4)'
      : '0 4px 20px rgba(0, 0, 0, 0.1)',
    '& .product-image': {
      transform: 'scale(1.05)',
    },
    '& .product-actions': {
      opacity: 1,
      transform: 'translateY(0)',
    }
  }
}));

const PriceTag = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(0.75, 1.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.8)'
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(4px)',
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  zIndex: 2,
  '& .price': {
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  },
  '& .discount': {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    fontWeight: 500,
  }
}));

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4, 0),
  marginBottom: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.02)'
    : 'rgba(0, 0, 0, 0.02)',
  borderRadius: theme.shape.borderRadius * 2,
}));

const StatsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.05)'
    : 'rgba(255, 255, 255, 0.8)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  }
}));

const ProductActions = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(1.5),
  background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.3))',
  display: 'flex',
  gap: theme.spacing(0.5),
  opacity: 0,
  transform: 'translateY(10px)',
  transition: 'all 0.2s ease-in-out',
  zIndex: 2,
  '& .MuiIconButton-root': {
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,0.15)',
    backdropFilter: 'blur(4px)',
    padding: 8,
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      transform: 'translateY(-2px)',
    }
  }
}));

const SiteInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.2)'
    : 'rgba(0, 0, 0, 0.03)',
  marginBottom: theme.spacing(2),
  '& img': {
    width: 24,
    height: 24,
    borderRadius: '4px'
  },
  '& .website-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  }
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  borderRadius: '50px',
  padding: '8px 12px',
  height: 'auto',
  '& .MuiChip-label': {
    fontSize: '0.875rem',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  '& .MuiChip-icon': {
    fontSize: '1rem',
    margin: 0,
  },
  transition: 'all 0.2s ease',
  '&.MuiChip-filled': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)' 
      : 'rgba(0, 0, 0, 0.05)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      transform: 'translateY(-2px)',
    },
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }
}));

const ITEMS_PER_PAGE = 12;

export default function ProductsPage() {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector(selectProducts).products;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [filterValue, setFilterValue] = React.useState('all');
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const navigate = useNavigate();
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  useEffect(() => {
    if (!products.length) {
      dispatch(getProductsAction());
    }
  }, [dispatch, products.length]);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = (productId: number) => {
    if (window.confirm('Are you sure you want to remove this product?')) {
      dispatch(removeProductByIdAction(productId));
    }
  };

  const handleProductClick = (productId: number) => {
    navigate(`/products/${productId}`);
  };

  const filteredProducts = products.filter(product => {
    const matchesSearch = product.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTags = selectedTags.length === 0 || 
      product.tags?.some(tag => selectedTags.includes(tag.name));
    
    switch (filterValue) {
      case 'price_drops':
        return matchesSearch && matchesTags && product.price < product.prevPrice;
      case 'recent':
        // Assuming products have a createdAt field
        return matchesSearch && matchesTags;
      case 'expensive':
        return matchesSearch && matchesTags;
      case 'cheap':
        return matchesSearch && matchesTags;
      case 'alerts':
        return matchesSearch && matchesTags && product.notifications?.length > 0;
      default:
        return matchesSearch && matchesTags;
    }
  }).sort((a, b) => {
    switch (filterValue) {
      case 'expensive':
        return b.price - a.price;
      case 'cheap':
        return a.price - b.price;
      case 'recent':
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      default:
        return 0;
    }
  });

  const paginatedProducts = React.useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredProducts.slice(startIndex, endIndex);
  }, [filteredProducts, page]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredProducts.length / ITEMS_PER_PAGE));
  }, [filteredProducts]);

  useEffect(() => {
    setPage(1);
  }, [searchTerm, filterValue, selectedTags]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  const filterOptions = [
    { label: 'All Products', value: 'all' },
    { label: 'Price Drops', value: 'price_drops', icon: <TrendingDownIcon /> },
    { label: 'Recently Added', value: 'recent', icon: <AccessTimeIcon /> },
    { label: 'Most Expensive', value: 'expensive', icon: <PriceChangeIcon /> },
    { label: 'Least Expensive', value: 'cheap', icon: <PriceChangeIcon /> },
    { label: 'With Alerts', value: 'alerts', icon: <NotificationsActiveIcon /> },
  ];

  return (
    <ProductsContainer>
      <HeroSection>
        <Container maxWidth="xl">
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12} md={6}>
              <Box>
                <Typography variant="h4" fontWeight="bold" gutterBottom>
                  Your Product Collection
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {products.length} products being tracked
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setIsCreateModalOpen(true)}
                  sx={{
                    borderRadius: '50px',
                    px: 3,
                    py: 1,
                    textTransform: 'none',
                    fontWeight: 600
                  }}
                >
                  Add Product
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </HeroSection>

      <Container maxWidth="xl">
        <Box sx={{ mb: 4, display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          <SearchBar elevation={0}>
            <IconButton sx={{ p: '10px' }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>

          <IconButton
            onClick={handleFilterClick}
            sx={{
              bgcolor: anchorEl ? theme.palette.primary.main : 'transparent',
              color: anchorEl ? 'white' : 'inherit',
              '&:hover': { bgcolor: theme.palette.primary.main, color: 'white' },
            }}
          >
            <FilterListIcon />
          </IconButton>

          <IconButton
            sx={{
              bgcolor: 'transparent',
              '&:hover': { bgcolor: theme.palette.primary.main, color: 'white' },
            }}
          >
            <SortIcon />
          </IconButton>
        </Box>

        <Box sx={{ 
          mb: 3, 
          display: 'flex', 
          gap: 1,
          flexWrap: 'wrap'
        }}>
          {filterOptions.map((option) => (
            <FilterChip
              key={option.value}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {option.icon}
                  <span>{option.label}</span>
                  {option.value !== 'all' && (
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        opacity: 0.7,
                        ml: 0.5,
                        px: 0.75,
                        py: 0.25,
                        borderRadius: '50px',
                        bgcolor: 'action.selected'
                      }}
                    >
                      {option.value === 'price_drops' 
                        ? filteredProducts.filter(p => p.price < p.prevPrice).length
                        : option.value === 'alerts'
                          ? filteredProducts.filter(p => p.notifications?.length > 0).length
                          : filteredProducts.length}
                    </Typography>
                  )}
                </Box>
              }
              onClick={() => setFilterValue(option.value)}
              variant={filterValue === option.value ? "filled" : "outlined"}
              className={filterValue === option.value ? "Mui-selected" : ""}
            />
          ))}
        </Box>

        {selectedTags.length > 0 && (
          <Box sx={{ mb: 3, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {selectedTags.map(tag => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => setSelectedTags(tags => tags.filter(t => t !== tag))}
                color="primary"
                variant="outlined"
                sx={{ borderRadius: '50px' }}
              />
            ))}
          </Box>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          TransitionComponent={Fade}
          PaperProps={{
            sx: {
              mt: 1.5,
              minWidth: 200,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              bgcolor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.03)' 
                : 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
            }
          }}
        >
          {filterOptions.map((option) => (
            <MenuItem 
              key={option.value}
              onClick={() => {
                setFilterValue(option.value);
                handleClose();
              }}
              selected={filterValue === option.value}
              sx={{ 
                borderRadius: 1,
                my: 0.5,
                mx: 1,
                gap: 1.5
              }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Menu>

        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
        >
          <Grid container spacing={3}>
            {products && products.length > 0 ? (
              paginatedProducts.map((product, index) => (
                <Grid xs={12} sm={6} md={4} lg={3} key={product.id}>
                  <motion.div variants={item}>
                    <ProductCard 
                      elevation={0}
                      onClick={() => handleProductClick(product.id)}
                      sx={{ 
                        cursor: 'pointer',
                        '&:active': {
                          transform: 'scale(0.99)'
                        }
                      }}
                    >
                      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                        <ImageWithFallback
                          src={product.imgUrl}
                          alt={product.title}
                          className="product-image"
                        />
                        <PriceTag>
                          <span className="price">{product.currency}{product.price}</span>
                          {product.price < product.prevPrice && (
                            <span className="discount">
                              -{Math.round(((product.prevPrice - product.price) / product.prevPrice) * 100)}%
                            </span>
                          )}
                        </PriceTag>
                        <ProductActions 
                          className="product-actions"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <IconButton 
                            size="small"
                            title="Set Price Alert"
                          >
                            <NotificationsIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            size="small"
                            title="Add to Favorites"
                          >
                            <FavoriteIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            size="small"
                            title="View Price History"
                          >
                            <TrendingUpIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            size="small"
                            title="Share"
                          >
                            <ShareIcon fontSize="small" />
                          </IconButton>
                          <IconButton 
                            size="small" 
                            title="Remove Product"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemove(product.id);
                            }}
                            sx={{
                              '&:hover': {
                                bgcolor: theme.palette.error.main,
                                color: 'white',
                              }
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </ProductActions>
                      </Box>
                      <Box sx={{ p: 2, flexGrow: 1 }}>
                        <SiteInfo>
                          <img 
                            src={product.favicon} 
                            alt=""
                            onError={(e) => {
                              e.currentTarget.src = '/default-favicon.png';
                            }}
                          />
                          <Box className="website-info">
                            <Typography variant="body2" fontWeight={500} noWrap>
                              {new URL(product.url).hostname}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              Updated {new Date(product.updatedAt).toLocaleDateString()} • {
                                new Date(product.updatedAt).toLocaleTimeString([], {
                                  hour: '2-digit',
                                  minute: '2-digit'
                                })
                              }
                            </Typography>
                          </Box>
                        </SiteInfo>
                        <Typography 
                          variant="subtitle1" 
                          fontWeight={600} 
                          gutterBottom 
                          sx={{ 
                            lineHeight: 1.3,
                            mb: 1,
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            height: 42
                          }}
                        >
                          {product.title}
                        </Typography>
                        <Box sx={{ mb: 2, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                          {product.tags?.slice(0, 3).map(tag => (
                            <Chip
                              key={tag.id}
                              label={tag.name}
                              size="small"
                              variant="outlined"
                              sx={{ 
                                borderRadius: '4px',
                                height: 24,
                                '& .MuiChip-label': {
                                  px: 1,
                                  fontSize: '0.75rem'
                                }
                              }}
                            />
                          ))}
                        </Box>
                        <Box sx={{ mt: 'auto' }}>
                          <PriceTag>
                            <span className="price">{product.currency}{product.price}</span>
                            {product.price < product.prevPrice && (
                              <span className="discount">
                                -{Math.round(((product.prevPrice - product.price) / product.prevPrice) * 100)}%
                              </span>
                            )}
                          </PriceTag>
                        </Box>
                      </Box>
                    </ProductCard>
                  </motion.div>
                </Grid>
              ))
            ) : (
              <Grid xs={12}>
                <Box 
                  sx={{ 
                    textAlign: 'center', 
                    py: 8,
                    px: 2,
                    maxWidth: 600,
                    mx: 'auto'
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <EmptyStateIllustration
                      style={{ 
                        width: '100%',
                        maxWidth: 300,
                        marginBottom: 32,
                        color: theme.palette.primary.main
                      }}
                    />
                  </motion.div>
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    No Products Yet
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 4 }}>
                    Start by adding your first product to track its price and get notified about the best deals.
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="large"
                    sx={{
                      borderRadius: '50px',
                      px: 4,
                      py: 1.5,
                      textTransform: 'none',
                      fontWeight: 600
                    }}
                  >
                    Add Your First Product
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </motion.div>

        <Box sx={{ 
          mt: 4, 
          display: 'flex', 
          justifyContent: 'center',
          '& .MuiPagination-ul': {
            gap: 1
          }
        }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
            variant="outlined"
            shape="rounded"
            size="large"
            sx={{
              '& .MuiPaginationItem-root': {
                borderRadius: 2,
                bgcolor: theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.03)' 
                  : 'rgba(255, 255, 255, 0.9)',
                borderColor: theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)',
                '&.Mui-selected': {
                  bgcolor: theme.palette.primary.main,
                  color: 'white',
                  '&:hover': {
                    bgcolor: theme.palette.primary.dark,
                  }
                }
              }
            }}
          />
        </Box>

        {isCreateModalOpen && (
          <AddNewProduct
            onClose={() => setIsCreateModalOpen(false)}
            onSuccessAdd={() => {
              setIsCreateModalOpen(false);
              dispatch(getProductsAction());
            }}
          />
        )}
      </Container>
    </ProductsContainer>
  );
} 